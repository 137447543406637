import {
    Box,
    Container,
    Divider,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { ArrowBack, CancelOutlined } from '@material-ui/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Form/Button';
import { SeinoLogo } from '../../../components/Graphics/SeinoLogo';
import { ConfigureWrapper, InfoBox } from './ConfigureRules';

export interface ErrorProps {
    title: string;
    stepTitle: string;
    errorTitle: string;
    errorDescription: string;
    backUrl: string;
    backTitle?: string;
}

export const ErrorPage = (props: ErrorProps) => {
    const navigate = useNavigate();

    return (
        <ConfigureWrapper>
            <Container>
                <SeinoLogo />
                <InfoBox my={2}>
                    <Typography my={4} variant="h4">
                        {props.title}
                    </Typography>

                    <Box mt={4}>
                        <Divider />
                    </Box>

                    <Typography mt={1} mb={1} variant="h6">
                        {props.stepTitle}
                    </Typography>

                    <Box>
                        <Divider />
                    </Box>

                    <ListItem>
                        <ListItemAvatar>
                            <CancelOutlined color="error" />
                        </ListItemAvatar>
                        <ListItemText
                            primary={props.errorTitle}
                            secondary={props.errorDescription}
                        />
                    </ListItem>

                    <Button
                        startIcon={<ArrowBack />}
                        onClick={() => navigate(props.backUrl)}
                        variant="text"
                        color="secondary"
                        title={props.backTitle ?? 'Go back'}
                    />
                </InfoBox>
            </Container>
        </ConfigureWrapper>
    );
};
