import { TableCell, TableRow, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { ColorNames } from '../../utils/styles';

export const KPIDashboardWrapper = styled.div`
    display: grid;
    grid-gap: 5px;
    padding: 16px;
    z-index: 9999;
`;

export const AddKPIWrapper = styled.div`
    display: grid;
    grid-template-columns: 70% 30%;
    margin: 20px 0;
`;

export const KPIWrapper = styled.div`
    display: grid;
    grid-template-columns: 90% 10%;
    justify-content: space-between;
`;

export const KPITextField = styled(TextField)`
    margin: 7px 0;
`;

export const KPITableRow = styled(TableRow)`
    :hover {
        background-color: ${ColorNames.BACKGROUND_GREY_DARK};
    }
`;

export const KPIDetailCell = styled(TableCell)`
    && {
        padding: 0 16px;
    }
`;
