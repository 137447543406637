import {
    clamp,
    endOfDay,
    endOfMonth,
    Interval,
    setDate,
    startOfDay,
} from 'date-fns';

export const normalizeInterval = (x: Interval) => ({
    start: startOfDay(x.start < x.end ? x.start : x.end),
    end: endOfDay(x.end > x.start ? x.end : x.start),
});

export const getMonthInterval = (d: Date): Interval =>
    normalizeInterval({
        start: setDate(d, 1),
        end: endOfMonth(d),
    });

export const ensureRangeIsNotInFuture = (range: Interval) => ({
    start: clamp(range.start, { start: 0, end: new Date() }),
    end: clamp(range.end, { start: range.start, end: new Date() }),
});
