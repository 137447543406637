import { Navigate, useLocation } from 'react-router-dom';

export interface Condition {
    condition: () => boolean;
    navigateTo?: string;
    priority?: number;
}
export interface ProtectedRouteProps {
    conditions: Condition[];
    children: JSX.Element;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    conditions,
    children,
}: ProtectedRouteProps) => {
    const location = useLocation();

    const foundCondition = conditions
        .sort((a, b) => (b?.priority || 1) - (a?.priority || 1))
        .find(condition => !condition.condition());

    return foundCondition !== undefined ? (
        <Navigate
            to={foundCondition.navigateTo || '/'}
            state={{ from: location }}
            replace={true}
        />
    ) : (
        children
    );
};

export interface SeinoRouteProps {
    children: JSX.Element;
    conditions?: Condition[];
    onboardingRequired?: boolean;
}
export const SeinoRoute: React.FC<SeinoRouteProps> = ({
    onboardingRequired = true,
    conditions = [],
    children,
}: SeinoRouteProps) => {
    conditions.push({
        condition: () => !onboardingRequired,
        navigateTo: '/onboarding',
        priority: 99,
    });

    return <ProtectedRoute conditions={conditions}>{children}</ProtectedRoute>;
};

export interface AccountAdminRouteProps extends SeinoRouteProps {
    isAccountAdmin: boolean;
}

export const AccountAdminRoute: React.FC<AccountAdminRouteProps> = ({
    isAccountAdmin,
    conditions = [],
    children,
}: AccountAdminRouteProps) => {
    return (
        <ProtectedRoute
            conditions={conditions.concat([
                { condition: () => isAccountAdmin, navigateTo: '/' },
            ])}
        >
            {children}
        </ProtectedRoute>
    );
};
