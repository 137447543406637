import { StoredView } from '../../../components/ExplorerGrid/grid/utils/gridView';

export const mapResponseToAllViews = (data: any): StoredView[] => {
    const returnValue: StoredView[] = [];

    data.forEach(({ viewId, config }: { viewId: string; config: string }) => {
        returnValue.push({
            ...JSON.parse(config),
            viewId,
        });
    });

    // Sort views by title and return
    return returnValue.sort(({ title }, { title: titleB }) =>
        title.localeCompare(titleB)
    );
};
