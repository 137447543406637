import { isBefore, isValid, subDays } from 'date-fns';
import { GetState, SetState, StateCreator, StoreApi } from 'zustand';
import {
    DateRange,
    fetchGoalMetrics,
    fetchGridData,
    FetchGridDataProps,
    FetchWorkspaceProps,
} from '../../api-client';
import { CallToActionState } from '../../components/CallToAction/CallToAction';
import { GoalMetric } from '../../components/ExplorerGrid/grid/utils/goalMetric';
import { createFetchState, FetchState } from '../helpers';

export interface DataGridStore {
    dateRange: DateRange;
    gridData: FetchState<FetchGridDataProps, any[]>;
    goalMetrics: FetchState<FetchWorkspaceProps, GoalMetric[]>;
    onboardingState: CallToActionState;
    setDateRange: (startDate?: Date | null, endDate?: Date | null) => void;
    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
}

export const datesValid = ({ startDate, endDate }: DateRange): boolean => {
    const bothValid = isValid(startDate) && isValid(endDate);
    if (!bothValid) return false;

    if (isBefore(endDate, startDate)) return false;

    return true;
};

export const dataGridStore: StateCreator<DataGridStore> = (
    set: SetState<DataGridStore>,
    get: GetState<DataGridStore>, // @ts-ignore
    api: StoreApi<DataGridStore>
) => ({
    gridData: createFetchState<FetchGridDataProps, any[]>(
        'gridData',
        fetchGridData,
        { flushResultOnFetch: false }
    )(set, get),
    goalMetrics: createFetchState<FetchWorkspaceProps, GoalMetric[]>(
        'goalMetrics',
        fetchGoalMetrics
    )(set, get),
    onboardingState: CallToActionState.OK,
    dateRange: { startDate: subDays(new Date(), 7), endDate: new Date() },
    setDateRange: (startDate, endDate) => {
        const newStartDate = startDate || get().dateRange.startDate;
        const newEndDate = endDate || get().dateRange.endDate;

        set({
            dateRange: { startDate: newStartDate, endDate: newEndDate },
        });
    },
    darkMode: JSON.parse(window.localStorage.getItem('darkMode') || 'false'),
    setDarkMode: darkMode => {
        set({ darkMode });
    },
});
