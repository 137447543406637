export const generateAbsoluteUrl = (path: string): string =>
    `${window.location.origin}${path}`;

export const generateResellerCenterWorkspaceUrl = (
    workspaceId: string,
    relativeUrl: string = '/'
) => {
    const url = relativeUrl.replace(/^\//, '');
    return `/workspaces/${workspaceId}/${url}`;
};
