import { Box, Button } from '@mui/material';
import {
    eachDayOfInterval,
    format,
    isBefore,
    isFuture,
    isSameDay,
    isWithinInterval,
} from 'date-fns';
import { Dispatch, useMemo } from 'react';
import styled from 'styled-components';

import { CalendarDay } from './CalendarDay';
import { CalendarWeekdays } from './CalendarWeekdays';
import { CalendarWeekNumbers } from './CalendarWeekNumbers';
import { ensureRangeIsNotInFuture, getMonthInterval } from './dateHelpers';
import { DateRangePickerAction, IntervalSide } from './reducer';

interface CalendarMonthProps {
    dispatch: Dispatch<DateRangePickerAction>;
    month: Date;
    dateRange: Interval;
    weekStartsOn: Day;
    locale: Locale;
    currentSide: IntervalSide;
}

export const CalendarMonth = ({
    month,
    dateRange,
    weekStartsOn,
    locale,
    dispatch,
    currentSide,
}: CalendarMonthProps) => {
    const monthInterval = getMonthInterval(month);
    const dates = useMemo(() => eachDayOfInterval(monthInterval), [
        monthInterval,
    ]);

    const selectMonth = () =>
        dispatch({
            type: 'selectRange',
            dateRange: ensureRangeIsNotInFuture(monthInterval),
        });

    return (
        <Container>
            <CalendarWeekNumbers
                month={monthInterval}
                weekStartsOn={weekStartsOn}
                dispatch={dispatch}
            />
            <Box>
                <Button
                    fullWidth={true}
                    onClick={selectMonth}
                    disabled={isFuture(month)}
                >
                    {format(month, 'MMMM yyyy', { locale })}
                </Button>

                <CalendarGrid>
                    <CalendarWeekdays
                        weekStartsOn={weekStartsOn}
                        locale={locale}
                    />
                    {dates.map(d => (
                        <CalendarDay
                            key={d.getDate()}
                            dispatch={dispatch}
                            date={d}
                            firstOfRange={isSameDay(dateRange.start, d)}
                            lastOfRange={isSameDay(dateRange.end, d)}
                            inRange={isWithinInterval(d, dateRange)}
                            weekStartsOn={weekStartsOn}
                            disabled={
                                (currentSide === 'end' &&
                                    isBefore(d, dateRange.start)) ||
                                isFuture(d)
                            }
                        />
                    ))}
                </CalendarGrid>
            </Box>
        </Container>
    );
};

const Container = styled(Box)`
    display: flex;
    flex-direction: row;
`;

const CalendarGrid = styled(Box)`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    row-gap: 4px;
`;
