import React from 'react';
import SeinoSelect, { components, Props } from 'react-select';
import { ColorNames } from '../../utils/styles';
import { Label } from './Input.styles';
import { getStyles } from './utils/select-style';

export type SelectProps = {
    testId?: string;
    size?: 'small' | 'medium' | 'large';
    border?: boolean;
    dark?: boolean;
    minWidth?: string | number;
} & Props;

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <svg
                width="10"
                height="5"
                viewBox="0 0 10 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 0L5 5L10 0H0Z"
                    fill={
                        props.selectProps.dark
                            ? ColorNames.WHITE
                            : ColorNames.BLACK
                    }
                />
            </svg>
        </components.DropdownIndicator>
    );
};

export const Select = (props: SelectProps) => {
    const {
        size = 'medium',
        label,
        border = true,
        onChange,
        options,
        dark,
        defaultValue,
    } = props;

    const CustomLabel = () => (label ? <Label>{label}</Label> : <></>);

    return (
        <div style={{ width: '100%' }} data-testid={props.testId}>
            <CustomLabel />
            <SeinoSelect
                {...props}
                defaultValue={defaultValue}
                onChange={onChange}
                options={options}
                components={{ DropdownIndicator, ...props.components }}
                styles={getStyles(size, dark, border)}
            />
        </div>
    );
};
