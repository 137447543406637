import { WarningOutlined } from '@material-ui/icons';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
    Box,
    Container,
    Paper,
    Skeleton,
    Stack,
    Tab,
    Typography,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useEffect } from 'react';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingStatus } from '../../constants';
import { useSeinoStore } from '../../store/seino-store';
import { ColorNames } from '../../utils/styles';
import { ConfigureESP } from './TabESP/ESP';
import { ConfigureGA } from './TabGA/ConfigureGA';
import { Mapping } from './TabMapping/Mapping';
import { TabPermission } from './TabPermissions/TabPermission';
import { Plans } from './TabPlan/Plans';

const StyledContainer = styled(Container)`
    margin: 2rem 0;
    width: 100%;
`;

const WorkspaceInformationHeader = styled(Stack)`
    margin: 1rem 0 2rem 0;
    display: flex;
    flex-direction: column;
`;

export const WorkspaceManagement = () => {
    const { planTabSettings } = useFlags();
    const navigate = useNavigate();
    const { workspaceId, selectedTab = 'permissions' } = useParams<{
        workspaceId: string;
        selectedTab: string;
    }>();

    const onTabChanged = (_: React.SyntheticEvent, newValue: string) => {
        navigate(
            generatePath('/workspace-management/:workspaceId/:selectedTab', {
                workspaceId: workspaceId,
                selectedTab: newValue,
            })
        );
    };

    const {
        workspaceManagement: { workspace },
        user: { setCurrentWorkspaceId },
    } = useSeinoStore();

    useEffect(() => {
        if (!workspaceId) return;

        setCurrentWorkspaceId(workspaceId);
        workspace.fetch({ workspaceId });
    }, [workspaceId, setCurrentWorkspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

    const linkToAccountPage = useCallback(
        (selectedTab?: string): string | null => {
            if (!workspace.result) return null;

            return generatePath('/account-management/:accountId/:selectedTab', {
                accountId: workspace.result?.associatedAccount.id,
                selectedTab: selectedTab || 'workspaces',
            });
        },
        [workspace.result]
    );

    if (!workspaceId) {
        return (
            <StyledContainer>
                <Typography variant="h4">No workspace selected</Typography>
            </StyledContainer>
        );
    }

    return (
        <Container maxWidth={'xl'}>
            <WorkspaceInformationHeader>
                {workspace.result ? (
                    <Box mb={0}>
                        <Typography variant={'h4'} component={'h2'}>
                            {workspace.result?.name}
                        </Typography>
                    </Box>
                ) : (
                    <Skeleton
                        style={{
                            display: 'inline-block',
                            lineHeight: '3rem',
                        }}
                        variant="text"
                        width={210}
                        height="3rem"
                    />
                )}
                <Typography variant={'subtitle1'}>
                    {workspace.result && linkToAccountPage() ? (
                        <Link to={linkToAccountPage() ?? ''}>
                            {workspace.result?.associatedAccount.name}
                        </Link>
                    ) : (
                        <Skeleton
                            style={{ display: 'inline-block' }}
                            variant="text"
                            width={210}
                        />
                    )}
                </Typography>
            </WorkspaceInformationHeader>

            <TabContext value={selectedTab}>
                <Box square={true} variant={'elevation'} component={Paper}>
                    <TabList
                        onChange={onTabChanged}
                        aria-label="Workspace Settings"
                    >
                        {planTabSettings && <Tab label="Plan" value="plan" />}
                        <Tab label="Permissions" value="permissions" />
                        <Tab
                            label="ESP Configuration"
                            value="esp"
                            icon={
                                workspace.status === LoadingStatus.SUCCESS &&
                                !workspace.result
                                    ?.hasEmailProviderConnection ? (
                                    <WarningOutlined
                                        style={{
                                            color: ColorNames.DANGER_COLOR,
                                        }}
                                    />
                                ) : undefined
                            }
                        ></Tab>
                        <Tab
                            label="Configure Google Analytics"
                            value="ga"
                            icon={
                                workspace.status === LoadingStatus.SUCCESS &&
                                !workspace.result
                                    ?.hasAnalyticsProviderConnection ? (
                                    <WarningOutlined
                                        style={{
                                            color: ColorNames.DANGER_COLOR,
                                        }}
                                    />
                                ) : undefined
                            }
                        />
                        <Tab
                            label="Mapping"
                            value="mapping"
                            icon={
                                workspace.status === LoadingStatus.SUCCESS &&
                                !workspace.result
                                    ?.hasConfiguredAggregationRules ? (
                                    <WarningOutlined
                                        style={{
                                            color: ColorNames.DANGER_COLOR,
                                        }}
                                    />
                                ) : undefined
                            }
                        />
                    </TabList>
                </Box>

                <Box square={true} variant={'elevation'} component={Paper}>
                    <TabPanel value="plan">
                        <Plans />
                    </TabPanel>
                    <TabPanel value="permissions">
                        <TabPermission
                            workspaceId={workspaceId}
                            linkToAccountMembersPage={
                                linkToAccountPage('members') ?? undefined
                            }
                        />
                    </TabPanel>
                    <TabPanel value="esp">
                        <ConfigureESP />
                    </TabPanel>
                    <TabPanel value="ga">
                        <ConfigureGA />
                    </TabPanel>
                    <TabPanel value="mapping">
                        <Mapping workspaceId={workspaceId} />
                    </TabPanel>
                </Box>
            </TabContext>
        </Container>
    );
};
