import {
    Box,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../components/Form/Button';
import { LoadingStatus } from '../../../../constants';
import { useSeinoStore } from '../../../../store/seino-store';

export const ConfigureWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem auto;
`;

export const ConfigureActiveCampaign = () => {
    const [apiUrl, setApiUrl] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [editingEnabled, setEditingEnabled] = useState(false);

    const {
        workspaceManagement: {
            dataSources,
            configureActiveCampaign,
            workspace,
        },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    useEffect(() => {
        dataSources.fetch({ workspaceId: currentWorkspaceId });
    }, [currentWorkspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async () => {
        if (!currentWorkspaceId) return;

        const configure = async () => {
            await configureActiveCampaign.fetch({
                workspaceId: currentWorkspaceId,
                url: apiUrl,
                key: apiKey,
            });
            await dataSources.fetch({ workspaceId: currentWorkspaceId });
            workspace.fetch({ workspaceId: currentWorkspaceId });
        };
        configure();
    };

    return (
        <ConfigureWrapper>
            <Container>
                <Typography style={{ maxWidth: 600 }}>
                    SEINō uses the ActiveCampaign REST API to retrieve data
                    about your campaigns. In order to access the API, we need
                    the URL and a API key associated with your ActiveCampaign
                    account. Please refer to{' '}
                    <Link
                        href="https://help.activecampaign.com/hc/en-us/articles/207317590-Getting-started-with-the-API#how-to-obtain-your-activecampaign-api-url-and-key"
                        target="_blank"
                    >
                        the ActiveCampaign documentation
                    </Link>
                    where to find this information.
                </Typography>

                {configureActiveCampaign.error && (
                    <Typography mt={2} color="error">
                        Connecting to ActiveCampaign failed. Please check the
                        URL and key you provided.
                    </Typography>
                )}

                <Box mt={3}>
                    <TextField
                        label="URL"
                        disabled={
                            configureActiveCampaign.status ===
                            LoadingStatus.LOADING
                        }
                        fullWidth={true}
                        error={!!configureActiveCampaign.error}
                        value={apiUrl}
                        onFocus={e => e.target.select()}
                        onChange={e => setApiUrl(e.target.value)}
                        {...(dataSources.result?.hasActiveCampaignConnection &&
                        !editingEnabled
                            ? {
                                  disabled: true,
                                  value: dataSources.result?.activeCampaignUrl,
                                  InputProps: {
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <IconButton
                                                  onClick={() =>
                                                      setEditingEnabled(true)
                                                  }
                                                  edge="end"
                                                  sx={{ margin: 0 }}
                                              >
                                                  <Edit />
                                              </IconButton>
                                          </InputAdornment>
                                      ),
                                  },
                              }
                            : {})}
                    />
                </Box>
                <Box mt={3}>
                    <TextField
                        label="Key"
                        disabled={
                            configureActiveCampaign.status ===
                            LoadingStatus.LOADING
                        }
                        fullWidth={true}
                        value={apiKey}
                        onFocus={e => e.target.select()}
                        error={!!configureActiveCampaign.error}
                        onChange={e => setApiKey(e.target.value)}
                        {...(dataSources.result?.hasActiveCampaignConnection &&
                        !editingEnabled
                            ? {
                                  disabled: true,
                                  value: '•'.repeat(64),
                                  InputProps: {
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <IconButton
                                                  onClick={() =>
                                                      setEditingEnabled(true)
                                                  }
                                                  edge="end"
                                                  sx={{ margin: 0 }}
                                              >
                                                  <Edit />
                                              </IconButton>
                                          </InputAdornment>
                                      ),
                                  },
                              }
                            : {})}
                    />
                </Box>

                <Grid
                    my={4}
                    container
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Grid item />

                    <Grid item>
                        <Button
                            disabled={
                                configureActiveCampaign.status ===
                                    LoadingStatus.LOADING ||
                                !apiKey ||
                                !apiUrl
                            }
                            onClick={() => {
                                handleSubmit();
                            }}
                            fullWidth={false}
                            variant="contained"
                            title="Connect"
                            startIcon={
                                configureActiveCampaign.status ===
                                    LoadingStatus.LOADING && (
                                    <CircularProgress size={12} />
                                )
                            }
                        />
                    </Grid>
                </Grid>
            </Container>
        </ConfigureWrapper>
    );
};
