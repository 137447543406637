import { Skeleton, Typography } from '@material-ui/core';

interface AccountTitleProps {
    title: string | undefined;
    loading: boolean;
}

export const AccountTitle = ({ title, loading }: AccountTitleProps) => {
    return (
        <Typography variant={'h4'} component={'h2'}>
            {title && !loading ? (
                title
            ) : (
                <Skeleton variant="text" width={210} />
            )}
        </Typography>
    );
};
