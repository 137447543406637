import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { AddCircle, Close, DeleteOutline, Save } from '@mui/icons-material';
import { TextField, Typography } from '@mui/material';
import { DetailGridInfo } from 'ag-grid-community';
import React, {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    KPIData,
    StoredView,
} from '../../components/ExplorerGrid/grid/utils/gridView';
import {
    GRID_VIEW_VERSION,
    KPIS_METRICS,
    LoadingStatus,
} from '../../constants';
import { GridContext as ExplorerGridContext } from '../../pages/Explorer/GridContext';
import { useSeinoStore } from '../../store/seino-store';
import { Button } from '../Form/Button';
import { Select } from '../Form/Select';
import {
    KPIDashboardWrapper,
    KPIDetailCell,
    KPITableRow,
} from './KPIDashboard.styles';

export interface KPI {
    field?: string;
    value?: string;
}

export interface KPIConfig {
    [key: string]: string;
}

export interface KPIDashboardProps {
    onClose: () => void;
}

export const KPIDashboard = ({ onClose }: KPIDashboardProps) => {
    const {
        views: {
            currentView,
            selectedViewKey,
            saveView,
            views,
            setSelectedViewKey,
        },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    const inputField = useRef<HTMLInputElement | null>(null);

    const { grid: explorerGrid } = useContext(ExplorerGridContext);
    const [grid, setGrid] = useState<DetailGridInfo | null>(null);

    useEffect(() => {
        setGrid(explorerGrid);
    }, [explorerGrid]);

    const [selectedMetric, setSelectedMetric] = useState('');
    const [currentValue, setCurrentValue] = useState(0);
    const [localKpis, setLocalKPIs] = useState<KPIData>(
        currentView?.kpiData || {}
    );

    const applyKPIs = useCallback(async () => {
        const configObj: StoredView = {
            ...currentView,
            viewId: selectedViewKey,
            version: GRID_VIEW_VERSION,
            kpiData: localKpis,
            modified: Date.now(),
            view: grid?.columnApi?.getColumnState()!,
            pivotMode: grid?.columnApi?.isPivotMode() || false,
            filterModel: grid?.api?.getFilterModel() || {},
        };

        await saveView.fetch({
            view: configObj,
            workspaceId: currentWorkspaceId,
        });
        await views.fetch({ workspaceId: currentWorkspaceId });
        setSelectedViewKey(configObj.viewId);
        onClose();
    }, [
        saveView,
        views,
        grid,
        setSelectedViewKey,
        localKpis,
        currentWorkspaceId,
        currentView,
        selectedViewKey,
        onClose,
    ]);

    const addField = useCallback(() => {
        const newKPIs = {
            ...localKpis,
        };

        newKPIs[selectedMetric] = currentValue;
        setLocalKPIs(newKPIs);
        setSelectedMetric('');
        setCurrentValue(0);
    }, [selectedMetric, localKpis, currentValue]);

    const removeField = useCallback(
        (metric: string) => {
            const newKPIS = {
                ...localKpis,
            };

            delete newKPIS[metric];
            setLocalKPIs(newKPIS);
        },
        [setLocalKPIs, localKpis]
    );

    const onInputChanged = (evt: ChangeEvent<HTMLInputElement>) => {
        const updatedValue = parseFloat(evt.target.value.toString());

        if (updatedValue) {
            setCurrentValue(updatedValue);
            return;
        }
    };

    const handleMetricSelect = (evt: any) => {
        setSelectedMetric(evt.value);
    };

    return (
        <KPIDashboardWrapper>
            <Box m={1} mx={0}>
                <Table>
                    <TableHead style={{ borderBottom: 'none' }}>
                        <TableRow>
                            <TableCell style={{ borderBottom: 'none' }}>
                                <Typography variant="h5">
                                    Set view targets
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <TableCell style={{ borderBottom: 'none' }}>
                                <Typography fontWeight="bold">
                                    Metric
                                </Typography>
                            </TableCell>
                            <TableCell style={{ borderBottom: 'none' }}>
                                <Typography fontWeight="bold">Value</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            key={`add-kpi-row-${Object.keys(localKpis).length}`}
                        >
                            <TableCell>
                                <Select
                                    labelId="kpi-select-label"
                                    id="kpi-select"
                                    border={false}
                                    testId="select-metric"
                                    size="small"
                                    styles={{
                                        menuPortal: base => ({
                                            ...base,
                                            zIndex: 99999,
                                        }),
                                    }}
                                    displayEmpty
                                    onChange={handleMetricSelect}
                                    options={Object.keys(KPIS_METRICS)
                                        .filter(
                                            availableKPI =>
                                                !Object.keys(
                                                    localKpis
                                                ).includes(availableKPI)
                                        )
                                        .map(metric => ({
                                            value: metric,
                                            label: KPIS_METRICS[metric],
                                        }))}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    ref={inputField}
                                    inputProps={{
                                        'data-testid': 'kpi-value',
                                    }}
                                    variant="standard"
                                    onChange={onInputChanged}
                                    id={selectedMetric}
                                />
                            </TableCell>
                            <TableCell
                                style={{
                                    minWidth: '140px',
                                    verticalAlign: 'bottom',
                                }}
                            >
                                <Button
                                    disabled={selectedMetric === ''}
                                    data-testid="add-kpi-button"
                                    aria-label="add"
                                    onClick={addField}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<AddCircle />}
                                    size="small"
                                    title="Add Target"
                                />
                            </TableCell>
                        </TableRow>
                        {Object.keys(localKpis).map((key, index) => (
                            <KPITableRow key={key}>
                                <KPIDetailCell>
                                    <Box ml={2}>
                                        <Typography variant="body2">
                                            {KPIS_METRICS[key]}
                                        </Typography>
                                    </Box>
                                </KPIDetailCell>

                                <KPIDetailCell>
                                    <Typography variant="body2">
                                        {localKpis[key]}
                                    </Typography>
                                </KPIDetailCell>
                                <KPIDetailCell>
                                    <IconButton
                                        data-testid={`remove-kpi-${index}`}
                                        color="primary"
                                        aria-label="add"
                                        onClick={() => removeField(key)}
                                    >
                                        <DeleteOutline />
                                    </IconButton>
                                </KPIDetailCell>
                            </KPITableRow>
                        ))}
                        <TableRow>
                            <TableCell style={{ borderBottom: 'none' }}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <LoadingButton
                                        disableElevation={true}
                                        loading={
                                            saveView.status ===
                                            LoadingStatus.LOADING
                                        }
                                        size="small"
                                        startIcon={<Close />}
                                        color="primary"
                                        variant="outlined"
                                        title="Apply"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </LoadingButton>
                                </Box>
                            </TableCell>
                            <TableCell style={{ borderBottom: 'none' }} />
                            <TableCell style={{ borderBottom: 'none' }}>
                                <Box>
                                    <LoadingButton
                                        disableElevation={true}
                                        loading={
                                            saveView.status ===
                                            LoadingStatus.LOADING
                                        }
                                        size="small"
                                        endIcon={<Save />}
                                        color="info"
                                        variant="contained"
                                        title="Apply"
                                        onClick={applyKPIs}
                                    >
                                        Save targets
                                    </LoadingButton>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        </KPIDashboardWrapper>
    );
};
