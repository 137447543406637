import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const GraphicLogo = ({
    onClick = undefined,
}: {
    onClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
}) => (
    <Wrapper>
        <svg
            onClick={e => onClick?.(e)}
            width={27}
            height={32}
            viewBox="0 0 27 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: onClick !== undefined ? 'pointer' : 'default' }}
        >
            <g clipPath="url(#clip0_6980:41577)">
                <path
                    d="M2.56455 10.5114C2.0986 12.2871 1.86563 14.1346 1.86563 16.0537C1.86563 20.2971 2.87161 23.754 4.88357 26.4244C5.61519 27.3023 6.49313 28.0888 7.5174 28.7839C7.9625 29.098 8.52939 29.3841 9.2181 29.6421L9.21842 31.2555C8.34993 30.9955 7.4537 30.6106 6.52971 30.1008C5.3957 29.4789 4.40801 28.6375 3.56665 27.5767C2.39606 26.1866 1.49982 24.5222 0.877945 22.5834C0.292647 20.9372 0 18.779 0 16.1085C0 14.0636 0.219842 12.1979 0.65953 10.5114L2.56455 10.5114ZM25.5068 10.5116C25.9514 12.2661 26.1737 14.1317 26.1737 16.1085C26.1737 18.4497 25.8811 20.608 25.2958 22.5834C24.6373 24.5222 23.7411 26.1866 22.6071 27.5767C21.5828 28.7107 20.5768 29.5521 19.5891 30.1008C18.6172 30.6171 17.7209 30.9948 16.9002 31.2337L16.9006 29.6214C17.5647 29.3689 18.1133 29.0897 18.5466 28.7839C19.5708 28.1254 20.4671 27.3389 21.2353 26.4244C22.223 25.1441 22.9912 23.6076 23.5399 21.8152C24.0154 20.1325 24.2532 18.2119 24.2532 16.0537C24.2532 13.8954 24.0154 12.0298 23.5399 10.4568L23.5561 10.5116L25.5068 10.5116ZM17.0102 0.744507V2.06142H9.32816V0.744507H17.0102Z"
                    fill="#ffffff"
                />
            </g>
            <defs>
                <clipPath id="clip0_6980:41577">
                    <rect
                        width={27}
                        height={31}
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    </Wrapper>
);
