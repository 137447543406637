import { useCubeQuery } from '@cubejs-client/react';
import { Error } from '@mui/icons-material';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { differenceInCalendarDays, subDays, subYears } from 'date-fns';
import styled from 'styled-components';
import { DateRange } from '../../api-client';
import { MetricChart } from './MetricChart';
import { MetricChartHighlighted } from './MetricChartHighlighted';
import { dateFormat } from './utils';

export interface MetricChartDataWrapperProps {
    dateRange: DateRange;
    metricName: string;
    highlighted?: boolean;
    onClick?: (metricName: string) => void;
    detailView?: boolean;
}

const AltContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    min-height: 288px;
`;

export const MetricChartDataWrapper = ({
    dateRange: { startDate, endDate },
    metricName,
    highlighted,
    onClick,
    detailView = false,
}: MetricChartDataWrapperProps) => {
    const lengthOfRangeInDays = differenceInCalendarDays(endDate, startDate);
    const { resultSet, isLoading, error } = useCubeQuery({
        measures: [`Campaigns.${metricName}`],
        timeDimensions: [
            {
                dimension: `Campaigns.broadcastMailingSendDate`,
                granularity: 'day',
                compareDateRange: [
                    [dateFormat(startDate), dateFormat(endDate)],
                    [
                        dateFormat(subDays(startDate, lengthOfRangeInDays + 1)),
                        dateFormat(subDays(startDate, 1)),
                    ],
                    [
                        dateFormat(subYears(startDate, 1)),
                        dateFormat(subYears(endDate, 1)),
                    ],
                ],
            },
        ],
    });

    if (isLoading) {
        return (
            <AltContainer>
                <CircularProgress />
            </AltContainer>
        );
    }

    if (error) {
        return (
            <AltContainer>
                <Tooltip title={error.message}>
                    <Error color="error" />
                </Tooltip>
            </AltContainer>
        );
    }

    if (!resultSet) {
        return null;
    }

    const [range, lastPeriod, lastYear] = resultSet.decompose();

    const chartData = range.rawData().map((data, index) => {
        const date = new Date(data[`Campaigns.broadcastMailingSendDate.day`]);
        const value = data[`Campaigns.${metricName}`];

        const lastPeriodValue = lastPeriod.rawData()[index][
            `Campaigns.${metricName}`
        ];

        const lastPeriodDate = new Date(
            lastPeriod.rawData()[index][
                `Campaigns.broadcastMailingSendDate.day`
            ]
        );

        const lastYearValue = lastYear.rawData()[index][
            `Campaigns.${metricName}`
        ];

        const lastYearDate = new Date(
            lastYear.rawData()[index][`Campaigns.broadcastMailingSendDate.day`]
        );

        return {
            date,
            value,
            lastPeriodValue,
            lastPeriodDate,
            lastYearValue,
            lastYearDate,
        };
    });

    return detailView ? (
        <MetricChartHighlighted metricName={metricName} data={chartData} />
    ) : (
        <MetricChart
            metricName={metricName}
            data={chartData}
            onClick={onClick}
            highlighted={highlighted}
        />
    );
};
