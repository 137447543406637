import { Box, TableCell } from '@material-ui/core';
import styled from 'styled-components';
import { ColorNames } from '../../../utils/styles';

export const Wrapper = styled.div`
    min-height: 300px;
`;

export const RuleWrapper = styled.div`
    :nth-child(odd) {
        background-color: ${ColorNames.BACKGROUND_GREY};
    }
`;

export const RuleRow = styled(Box)`
    display: grid;
    grid-gap: 8px;
    align-items: baseline;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    &:hover {
        background-color: ${ColorNames.BACKGROUND_GREY_DARK};
    }
`;

export const RuleItem = styled(Box)`
    display: flex;
    align-items: center;
    padding: 0 8px;
`;

export const HeaderTableCell = styled(TableCell)`
    &.MuiTableCell-root {
        font-family: 'Poppins';
        color: ${ColorNames.TEXT_COLOR};
        text-transform: uppercase;
        font-size: 12px;
    }
`;
