import { addDays, format, isAfter, isBefore, subDays } from 'date-fns';
import { DateRange } from '../../api-client';

export const dateFormat = (date: Date) => format(date, 'yyyy-MM-dd');

export const getTitleForMetric = (metric: string) => {
    switch (metric) {
        default:
            return metric;
        case 'metric_campaigns_sent_unique':
            return 'Campaigns';
        case 'metric_website_session_time_sum':
            return 'Session time';
        case 'metric_website_session_sum':
            return 'Website sessions';
        case 'metric_website_session_time_avg':
            return 'Session time average';
        case 'metric_website_revenue_per_session':
            return 'Revenue per session';
        case 'metric_website_revenue_per_email_sent':
            return 'Revenue per email sent';
        case 'metric_website_bounce_sum':
            return 'Bounces';
        case 'metric_website_bounce_rate':
            return 'Bounce rate';
        case 'metric_website_revenue_sum':
            return 'Revenue sum';
        case 'metric_website_conversion_rate':
            return 'Conversion Rate';
        case 'metric_email_sent_all_sum':
            return 'Sent all';
        case 'metric_email_sent_unique_sum':
            return 'Sent unique';
        case 'metric_email_open_unique_sum':
            return 'Open';
        case 'metric_email_open_unique_rate':
            return 'Open (%)';
        case 'metric_email_clicks_unique_sum':
            return 'Clicks unique (sum)';
        case 'metric_email_clicks_unique_rate':
            return 'Clicks unique (%)';
        case 'metric_email_clicks_all_sum':
            return 'Clicks all';
        case 'metric_email_clicks_all_rate':
            return 'Clicks all (%)';
        case 'metric_email_clicks_to_open_all_rate':
            return 'Clicks to open all (%)';
        case 'metric_email_clicks_to_open_unique_rate':
            return 'Clicks to open unique (%)';
        case 'metric_email_clicks_per_user_avg':
            return 'Clicks per user';
        case 'metric_email_softbounce_sum':
            return 'Soft bounces';
        case 'metric_email_softbounce_rate':
            return 'Soft bounces (%)';
        case 'metric_email_hardbounce_sum':
            return 'Hard bounces';
        case 'metric_email_hardbounce_rate':
            return 'Hard bounces (%)';
        case 'metric_email_complaints_sum':
            return 'Complaints';
        case 'metric_email_complaints_rate':
            return 'Complaints (%)';
        case 'metric_email_unsubscribe_sum':
            return 'Unsubscribes';
        case 'metric_email_unsubscribe_rate':
            return 'Unsubscribes (%)';
        case 'metric_email_deliverability_rate':
            return 'Deliverability (%)';
        case 'metric_email_campaigns_unique':
            return 'Campaigns unique';
        case 'metric_website_transactions_sum':
            return 'Transactions sum';
        case 'metric_website_average_order_value':
            return 'Average order value';
    }
};

export const getPercentageDifference = (kpi: number, value: number): string => {
    if (kpi === 0) {
        return '0';
    }
    return (((value - kpi) / kpi) * 100).toFixed(2);
};

export const filterDate = (target: string, dateRange: DateRange): boolean => {
    const targetDate = new Date(target);

    const returnValue =
        isAfter(targetDate, subDays(dateRange.startDate, 1)) &&
        isBefore(targetDate, addDays(dateRange.endDate, 1));

    return returnValue;
};

const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
    }).format(value);
};

const formatNumber = (value: number) =>
    new Intl.NumberFormat('nl-NL', { maximumFractionDigits: 2 }).format(value);

export const formatMetric = (values: number[], metricName: string): string => {
    const sum = values.reduce((acc, val) => acc + val, 0);

    switch (metricName) {
        // Sum currency
        case 'metric_website_average_order_value':
            return `${formatCurrency(sum / values.length)}`;
        case 'metric_website_revenue_sum':
            return `${formatCurrency(sum)}`;
        // average rate
        case 'metric_email_open_unique_rate':
        case 'metric_email_unsubscribe_rate':
        case 'metric_email_deliverability_rate':
        case 'metric_website_conversion_rate':
            return `${formatNumber((sum / values.length) * 100)}%`;
        default:
        case 'metric_email_sent_all_sum':
        case 'metric_email_sent_unique_sum':
        case 'metric_email_open_unique_sum':
            return `${formatNumber(sum)}`;
    }
};
