import { addDays, format, isBefore } from 'date-fns';
import { ChartData } from './MetricChart';

export const genData = (startDate: Date, endDate: Date) => {
    const result = [];
    let currentDate = startDate;
    while (isBefore(currentDate, endDate)) {
        result.push(genDatum(format(currentDate, 'yyyy-MM-dd')));

        currentDate = addDays(currentDate, 1);
    }
    return result;
};

export interface PreparedData {
    id: 'default' | 'last_period';
    color: '#60C2D5' | '#9e9e9e';
    lineStyle: any;
    data: { x: string | number; y: number }[];
}

export const prepareData = (
    data: ChartData[],
    includeYear: boolean = false
) => {
    const current = {
        id: 'default',
        color: '#60C2D5',
        lineStyle: {
            strokeWidth: 2,
        },
        data: data.map(({ date, value }) => ({
            x: date,
            y: value,
        })),
    };

    const last_period = {
        id: 'last_period',
        color: '#9e9e9e',
        lineStyle: {
            strokeDasharray: '6, 2',
            strokeWidth: 1,
        },
        data: data.map(({ date, lastPeriodValue: comparisonValue }) => ({
            x: date,
            y: comparisonValue,
        })),
    };

    const last_year = {
        id: 'last_year',
        color: '#121212',
        lineStyle: {
            strokeDasharray: '8, 4',
            strokeWidth: 0.5,
        },
        data: data.map(({ date, lastYearValue: comparisonValue }) => ({
            x: date,
            y: comparisonValue,
        })),
    };

    const returnValue = [current, last_period];

    if (includeYear) {
        returnValue.push(last_year);
    }

    return returnValue;
};

const genDatum = (date: string) => {
    return {
        date,
        metric_email_sent_all_sum: Math.ceil(Math.random() * 100),
        metric_email_sent_unique_sum: Math.ceil(Math.random() * 100),
        metric_email_open_unique_sum: Math.ceil(Math.random() * 100),
        metric_email_open_unique_rate: Math.ceil(Math.random() * 100),
        metric_email_clicks_all_sum: Math.ceil(Math.random() * 100),
        metric_email_clicks_all_rate: Math.ceil(Math.random() * 100),
        metric_email_clicks_unique_sum: Math.ceil(Math.random() * 100),
        metric_email_clicks_unique_rate: Math.ceil(Math.random() * 100),
        metric_email_clicks_to_open_all_rate: Math.ceil(Math.random() * 100),
        metric_email_clicks_to_open_unique_rate: Math.ceil(Math.random() * 100),
        metric_email_clicks_per_user_avg: Math.ceil(Math.random() * 100),
        metric_email_softbounce_sum: Math.ceil(Math.random() * 100),
        metric_email_softbounce_rate: Math.ceil(Math.random() * 100),
        metric_email_hardbounce_sum: Math.ceil(Math.random() * 100),
        metric_email_hardbounce_rate: Math.ceil(Math.random() * 100),
        metric_email_complaints_sum: Math.ceil(Math.random() * 100),
        metric_email_complaints_rate: Math.ceil(Math.random() * 100),
        metric_email_unsubscribe_sum: Math.ceil(Math.random() * 100),
        metric_email_unsubscribe_rate: Math.ceil(Math.random() * 100),
        metric_email_deliverability_rate: Math.ceil(Math.random() * 100),
        metric_website_session_sum: Math.ceil(Math.random() * 100),
        metric_website_bounce_sum: Math.ceil(Math.random() * 100),
        metric_website_bounce_rate: Math.ceil(Math.random() * 100),
        metric_website_session_time_sum: Math.ceil(Math.random() * 100),
        metric_website_session_time_avg: Math.ceil(Math.random() * 100),
        metric_website_transactions_sum: Math.ceil(Math.random() * 100),
        metric_website_conversion_rate: Math.ceil(Math.random() * 100),
        metric_website_revenue_sum: Math.ceil(Math.random() * 100),
        metric_website_revenue_per_session: Math.ceil(Math.random() * 100),
        metric_website_revenue_per_email_sent: Math.ceil(Math.random() * 100),
        metric_website_average_order_value: Math.ceil(Math.random() * 100),
    };
};
