import axios from 'axios';
import { getAPIUrl } from '../../utils/api';

export interface FetchAccountProps {
    accountId: string;
}

export const fetchAccount = ({ accountId }: FetchAccountProps) => {
    return axios.get(`${getAPIUrl()}/accounts/${accountId}`);
};

export const fetchInvitations = ({ accountId }: FetchAccountProps) => {
    return axios.get(`${getAPIUrl()}/accounts/${accountId}/invitations`);
};

export const fetchMembers = ({ accountId }: FetchAccountProps) => {
    return axios.get(`${getAPIUrl()}/accounts/${accountId}/members`);
};

export interface DeleteMemberProps extends FetchAccountProps {
    memberId: string;
}

export const deleteMember = ({ accountId, memberId }: DeleteMemberProps) => {
    return axios.delete(
        `${getAPIUrl()}/accounts/${accountId}/members/${memberId}`
    );
};

export interface DeleteInvitationProps extends FetchAccountProps {
    token: string;
}

export const deleteInvitation = ({
    accountId,
    token,
}: DeleteInvitationProps) => {
    return axios.delete(
        `${getAPIUrl()}/accounts/${accountId}/invitations/${token}`
    );
};

export interface AddWorkspaceProps extends FetchAccountProps {
    name: string;
}

export const addWorkspace = ({ accountId, name }: AddWorkspaceProps) => {
    return axios.post(`${getAPIUrl()}/accounts/${accountId}/workspaces`, {
        name,
    });
};

export interface InviteMemberProps extends FetchAccountProps {
    name: string;
    email: string;
}
export const inviteMember = ({ accountId, name, email }: InviteMemberProps) => {
    return axios.post(`${getAPIUrl()}/accounts/${accountId}/members`, {
        name,
        email,
    });
};

export interface ChangeMemberRoleProps extends FetchAccountProps {
    memberId: string;
    newRole: string;
}

export const changeMemberRole = ({
    accountId,
    memberId,
    newRole,
}: ChangeMemberRoleProps) => {
    return axios.put(
        `${getAPIUrl()}/accounts/${accountId}/members/${memberId}/role`,
        {
            newRole,
        }
    );
};

export const fetchAccounts = () => {
    return axios.get(`${getAPIUrl()}/accounts`);
};

export interface OnboardingProps {
    organization: string;
    phoneNumber: string;
    country: string;
    address: string;
    city: string;
    postal: string;
    state: string;
}

export const onboard = async (data: OnboardingProps) => {
    await axios
        .post(`${getAPIUrl()}/accounts`, { ...data })
        .then(res => {
            // HTTP 201 Created
            if (res.status === 201) {
                window.location.href = '/account-management';
            }
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            console.log('finally');
        });
};
