import { Box, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { ColorNames } from '../../utils/styles';

export interface TooltipProps {
    date: string;
    compareDate: string;
    value: string;
    delta: number;
}

const StyledValue = styled(Typography)`
    font-weight: bold;
`;

const StyledDates = styled(Typography)`
    color: ${ColorNames.TEXT_GRAY};
    font-size: 10px;
`;

const Delta = styled(Typography)<{ delta: number }>`
    font-weight: bold;
    font-size: 10px;
    color: ${props =>
        props.delta > 0 ? ColorNames.SUCCESS_COLOR : ColorNames.DANGER_COLOR};
`;

export const Tooltip = ({ date, compareDate, value, delta }: TooltipProps) => {
    return (
        <div
            style={{
                background: 'white',
                padding: '12px 12px 0 12px',
                border: '1px solid #ccc',
            }}
        >
            <StyledDates>{`${date} vs ${compareDate}`}</StyledDates>
            <Box display="flex" alignItems="baseline">
                <StyledValue mb={2}>{value}</StyledValue>
                <Box mt={2}>
                    <Delta ml={1} delta={delta}>
                        {delta < 0 ? '↓ ' : '↑ '}
                        {`${delta}%`}
                    </Delta>
                </Box>
            </Box>
        </div>
    );
};
