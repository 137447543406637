import { GetState, SetState, StateCreator } from 'zustand';
import {
    addWorkspace,
    AddWorkspaceProps,
    changeMemberRole,
    ChangeMemberRoleProps,
    deleteInvitation,
    DeleteInvitationProps,
    deleteMember,
    DeleteMemberProps,
    fetchAccount,
    FetchAccountProps,
    fetchAccounts,
    fetchInvitations,
    fetchMembers,
    inviteMember,
    InviteMemberProps,
    onboard,
    OnboardingProps,
} from '../../api-client';
import { LoadingStatus } from '../../constants';
import { createFetchState, FetchState } from '../helpers';

export type MemberRole = 'admin' | 'member'
export interface Member {
    id: string;
    name: string;
    email: string;
    role: MemberRole;
    isCurrentUser: boolean;
}

interface FetchAccountsResult {
    accounts: Account[];
}

type FetchAccountResult = Account;

export interface Account {
    id: string;
    name: string;
    displayName: string;
    workspaces: Workspace[];
}

export interface DataSources {
    hasEmailProviderConnection: boolean;
    hasAnalyticsProviderConnection: boolean;
    hasConfiguredAggregationRules: boolean;
}

export interface Workspace {
    id: string;
    name: string;
    members: Member[];
    dataSources: DataSources;
}

export interface ChangeMemberRoleOptions {
    newRole: string;
}

export interface Invitation {
    token: string;
    email: string;
    expiresAt: string;
    name: string;
    role: string;
}

export interface FetchInvitationsResult {
    invitations: Invitation[];
}

export interface FetchMembersResult {
    members: Member[];
}

export interface AccountManagementStore {
    inviteMember: FetchState<InviteMemberProps, null>;
    deleteMember: FetchState<DeleteMemberProps, null>;
    deleteInvitation: FetchState<DeleteInvitationProps, null>;
    changeMemberRole: FetchState<ChangeMemberRoleProps, null>;
    members: FetchState<FetchAccountProps, FetchMembersResult>;
    addWorkspace: FetchState<AddWorkspaceProps, null>;
    onboard: FetchState<OnboardingProps, null>;
    accounts: FetchState<null, FetchAccountsResult>;
    account: FetchState<FetchAccountProps, FetchAccountResult>;
    invitations: FetchState<FetchAccountProps, FetchInvitationsResult>;
}

const initialState = {
    members: [],
    accounts: [],
    account: null,
    getMembersStatus: LoadingStatus.IDLE,
};

export const accountManagementStore: StateCreator<AccountManagementStore> = (
    set: SetState<AccountManagementStore>,
    get: GetState<AccountManagementStore>
) => ({
    ...initialState,
    addWorkspace: createFetchState<AddWorkspaceProps, null>(
        'addWorkspace',
        addWorkspace
    )(set, get),
    accounts: createFetchState<null, FetchAccountsResult>(
        'accounts',
        fetchAccounts
    )(set, get),
    account: createFetchState<FetchAccountProps, FetchAccountResult>(
        'account',
        fetchAccount
    )(set, get),
    members: createFetchState<FetchAccountProps, FetchMembersResult>(
        'members',
        fetchMembers
    )(set, get),
    onboard: createFetchState<OnboardingProps, null>('onboard', onboard)(
        set,
        get
    ),
    invitations: createFetchState<FetchAccountProps, FetchInvitationsResult>(
        'invitations',
        fetchInvitations
    )(set, get),
    inviteMember: createFetchState<InviteMemberProps, null>(
        'inviteMember',
        inviteMember
    )(set, get),
    deleteMember: createFetchState<DeleteMemberProps, null>(
        'deleteMember',
        deleteMember
    )(set, get),
    deleteInvitation: createFetchState<DeleteInvitationProps, null>(
        'deleteInvitation',
        deleteInvitation
    )(set, get),
    changeMemberRole: createFetchState<ChangeMemberRoleProps, null>(
        'changeMemberRole',
        changeMemberRole
    )(set, get),
});
