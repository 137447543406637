import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from '@material-ui/core';
import { Check, Clear, Mail } from '@mui/icons-material';
import React from 'react';

export const freeFeatures: PlanFeature[] = [
    { type: 'pro', feature: 'Data Explorer' },
    { type: 'pro', feature: '14 Day Data Retention' },
    { type: 'pro', feature: '1 ESP Account' },
    { type: 'pro', feature: '1 GA View' },
    { type: 'con', feature: 'Access Controls' },
];

export const proFeatures: PlanFeature[] = [
    { type: 'pro', feature: 'Data Explorer' },
    { type: 'pro', feature: '2 Year Data Retention' },
    { type: 'pro', feature: '1 ESP Account' },
    { type: 'pro', feature: '5 GA Views' },
    { type: 'pro', feature: 'Access Controls' },
];

export const enterpriseFeatures: PlanFeature[] = [
    { type: 'pro', feature: 'Data Explorer' },
    { type: 'pro', feature: 'Unlimited Data Retention' },
    { type: 'pro', feature: 'Multiple ESP Account' },
    { type: 'pro', feature: 'Unlimited GA View' },
    { type: 'pro', feature: 'Access Controls' },
];

export enum Plan {
    FREE = 'free',
    PRO = 'pro',
    ENTERPRISE = 'enterprise',
}

interface PlanFeature {
    type: 'pro' | 'con';
    feature: string;
}

export interface PlanCardProps {
    plan: Plan;
}

const getTitle = (plan: Plan): string => {
    switch (plan) {
        case Plan.PRO:
            return 'Pro';
        case Plan.ENTERPRISE:
            return 'Enterprise';
        case Plan.FREE:
        default:
            return 'Free';
    }
};

const getDescription = (plan: Plan): string => {
    switch (plan) {
        case Plan.PRO:
            return 'Best plan for email marketing teams who require advanced analytics. ';
        case Plan.ENTERPRISE:
            return 'For the most demanding of enterprises. All the power to match your growth.';
        case Plan.FREE:
        default:
            return 'Best plan for basic analytics with limited historical data.';
    }
};

const getFeatures = (plan: Plan): PlanFeature[] => {
    switch (plan) {
        case Plan.FREE:
            return freeFeatures;
        case Plan.PRO:
            return proFeatures;
        case Plan.ENTERPRISE:
            return enterpriseFeatures;
        default:
            return [];
    }
};

const getAction = (plan: Plan): React.ReactNode => {
    switch (plan) {
        case Plan.ENTERPRISE:
            return (
                <Button startIcon={<Mail />} color="info">
                    Contact Us
                </Button>
            );
        case Plan.PRO:
            return (
                <Button variant="contained" color="error">
                    Downgrade
                </Button>
            );
        default:
        case Plan.FREE:
            return (
                <Button variant="contained" color="success">
                    Upgrade
                </Button>
            );
    }
};

export const PlanCard = ({ plan }: PlanCardProps) => {
    return (
        <Card
            sx={{
                textAlign: 'center',
                maxWidth: '320px',
                padding: '2rem 0',
            }}
        >
            <CardContent>
                <Box my={3}>
                    <Box my={2}>
                        <Typography variant="h3">{getTitle(plan)}</Typography>
                    </Box>
                    <Typography color="text.secondary">
                        {getDescription(plan)}
                    </Typography>
                </Box>
                <Grid
                    container
                    my={2}
                    spacing={1}
                    style={{
                        margin: 'auto',
                        textAlign: 'left',
                        width: '80%',
                    }}
                >
                    {getFeatures(plan).map(({ type, feature }) => (
                        <React.Fragment key={feature}>
                            <Grid item xs={2}>
                                {type === 'pro' ? (
                                    <Check color="success" />
                                ) : (
                                    <Clear color="error" />
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Typography>{feature}</Typography>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </CardContent>
            <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
                {getAction(plan)}
            </CardActions>
        </Card>
    );
};
