import {
    Box,
    Container,
    Grid,
    InputLabel,
    ListSubheader,
    MenuItem,
    Paper,
    Stack,
    Typography,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { FormInput, FormSelect, FormWrapper } from '../../components/Form';
import { InfoPanel } from '../../components/InfoPanel/InfoPanel';
import { LoadingStatus } from '../../constants';
import { useSeinoStore } from '../../store/seino-store';
import { countries } from '../../utils/countries';
import { ColorNames } from '../../utils/styles';

export const StyledContainer = styled(Container)`
    display: flex;
    margin-top: 2rem;
`;

const phoneRegExp = /^\+?[0-9]{10,15}$/;

const validationSchema = yup.object({
    organization: yup.string().required(),
    phoneNumber: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required(),
    country: yup.string().required(),
    address: yup.string().required(),
    city: yup.string().required(),
    apartment: yup.string(),
    postal: yup.string().required(),
    state: yup.string().required(),
});

export type OnboardingData = yup.InferType<typeof validationSchema>;

export const Onboarding = () => {
    const { showOnboardingPage } = useFlags();

    const {
        accountManagement: { onboard },
    } = useSeinoStore();

    const defaultValues = useMemo(
        () => ({
            organization: '',
            phoneNumber: '',
            country: '',
            address: '',
            city: '',
            apartment: '',
            postal: '',
            state: '',
        }),
        []
    );

    if (!showOnboardingPage) {
        return (
            <InfoPanel
                showLogo={false}
                title="👋 Welcome to SEINō!"
                dividerTitle="Get started by completing one of the steps below."
                content={
                    <Typography variant="body1">
                        <ol>
                            <li>
                                Ask your account administrator for an
                                invitation.
                            </li>
                            <li>
                                <a href="mailto:hello@seino.ai?subject=Onboarding">
                                    Reach out
                                </a>{' '}
                                to us to onboard a new account.
                            </li>
                        </ol>
                    </Typography>
                }
            />
        );
    }

    return (
        <StyledContainer maxWidth={'xl'}>
            <Stack spacing={2}>
                <Typography variant={'h2'}>Setup your account</Typography>
                <Box
                    p={2}
                    square={true}
                    variant={'elevation'}
                    component={Paper}
                >
                    <FormWrapper<OnboardingData>
                        onSubmit={(data: OnboardingData) => {
                            const submitData: any = {};
                            (Object.keys(
                                data
                            ) as (keyof OnboardingData)[]).forEach(key => {
                                if (data[key]) {
                                    submitData[key] = data[key];
                                }
                            });

                            onboard.fetch({
                                ...(submitData as OnboardingData),
                            });
                        }}
                        defaultValues={defaultValues}
                        validationSchema={validationSchema}
                    >
                        <Typography variant="body1">
                            Welcome to SEINō! Before we can setup your account,
                            we need a few more details.
                        </Typography>

                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <Typography variant="h4" my={2}>
                                    Organization details
                                </Typography>
                                <FormInput<OnboardingData>
                                    placeholder="Organization"
                                    fullWidth
                                    name="organization"
                                    label="Organization"
                                    requiredMessage="You must give a org"
                                />
                                <FormInput<OnboardingData>
                                    required
                                    name="phoneNumber"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    color="info"
                                    label="Phone number"
                                    placeholder="+31612345678"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4" my={2}>
                                    Billing address
                                </Typography>

                                <FormInput<OnboardingData>
                                    required
                                    name="address"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="address"
                                    color="info"
                                    label="Address"
                                />

                                <FormInput<OnboardingData>
                                    required
                                    name="apartment"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="apparment"
                                    color="info"
                                    label="Apartment, suite, etc"
                                />

                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <FormInput<OnboardingData>
                                            required
                                            name="city"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="city"
                                            color="info"
                                            label="City"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormInput<OnboardingData>
                                            required
                                            name="postal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            id="zip"
                                            color="info"
                                            label="ZIP or postal code"
                                        />
                                    </Grid>
                                </Grid>

                                <FormInput<OnboardingData>
                                    required
                                    name="state"
                                    color="info"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id="state"
                                    label="State or province"
                                />
                                <Box mt={4} mb={1}>
                                    <InputLabel required>Country</InputLabel>
                                </Box>
                                <FormSelect<OnboardingData>
                                    name="country"
                                    label="Country"
                                >
                                    <ListSubheader>Common</ListSubheader>
                                    {countries
                                        .filter(country =>
                                            country?.groups?.includes('common')
                                        )
                                        .map(country => (
                                            <MenuItem
                                                key={`country-${country.value}`}
                                                value={country.value}
                                            >
                                                {country.label}
                                            </MenuItem>
                                        ))}
                                    <ListSubheader>Other</ListSubheader>
                                    {countries
                                        .filter(
                                            country =>
                                                !country?.groups?.includes(
                                                    'common'
                                                )
                                        )
                                        .map(country => (
                                            <MenuItem
                                                key={`country-${country.value}`}
                                                value={country.value}
                                            >
                                                {country.label}
                                            </MenuItem>
                                        ))}
                                </FormSelect>
                            </Grid>
                            <Grid xs={12} mt={8} textAlign="right">
                                <LoadingButton
                                    disableElevation={true}
                                    loading={
                                        onboard.status === LoadingStatus.LOADING
                                    }
                                    style={{
                                        backgroundColor: ColorNames.INFO_COLOR,
                                    }}
                                    type="submit"
                                    variant="outlined"
                                    color="secondary"
                                >
                                    Setup Account
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </FormWrapper>
                </Box>
            </Stack>
        </StyledContainer>
    );
};
