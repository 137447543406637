import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import { useState } from 'react';

export interface AddWorkspaceDialogProps {
    open: boolean;
    handleClose: () => void;
    onConfirm: (name: string) => void;
}

export const AddWorkspaceDialog = ({
    open,
    onConfirm,
    handleClose,
}: AddWorkspaceDialogProps) => {
    const [workspaceName, setWorkspaceName] = useState('');

    return (
        <Dialog open={open} onClose={handleClose}>
            <Box style={{ minWidth: '600px' }}>
                <DialogTitle>Create workspace</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        onChange={e => setWorkspaceName(e.target.value)}
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={() => onConfirm(workspaceName)}
                        variant="contained"
                        color="info"
                    >
                        Create
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};
