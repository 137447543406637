import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getTitleForMetric } from './utils';

const Wrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
`;

export const REACHED = [
    'metric_campaigns_sent_unique',
    'metric_email_sent_all_sum',
    'metric_email_sent_unique_sum',
];

export const DELIVERY = [
    'metric_email_deliverability_rate',
    'metric_email_softbounce_sum',
    'metric_email_softbounce_rate',
    'metric_email_hardbounce_sum',
    'metric_email_hardbounce_rate',
];

export const OPEN = [
    'metric_email_open_unique_sum',
    'metric_email_open_unique_rate',
];

export const ENGAGED = [
    'metric_email_clicks_all_sum',
    'metric_email_clicks_all_rate',
    'metric_email_clicks_unique_sum',
    'metric_email_clicks_unique_rate',
    'metric_email_clicks_to_open_all_rate',
    'metric_email_clicks_to_open_unique_rate',
    'metric_email_clicks_per_user_avg',
    'metric_email_complaints_sum',
    'metric_email_complaints_rate',
    'metric_email_unsubscribe_sum',
    'metric_email_unsubscribe_rate',
    'metric_website_session_sum',
    'metric_website_bounce_sum',
    'metric_website_bounce_rate',
    'metric_website_session_time_sum',
    'metric_website_session_time_avg',
];

export const CONVERTED = [
    'metric_website_transactions_sum',
    'metric_website_conversion_rate',
    'metric_website_revenue_sum',
    'metric_website_revenue_per_session',
    'metric_website_revenue_per_email_sent',
    'metric_website_average_order_value',
];

export const METRICS = [
    ...REACHED,
    ...DELIVERY,
    ...OPEN,
    ...ENGAGED,
    ...CONVERTED,
];

export interface MetricsSettingsProps {
    initialSelection?: string[];
    onChanged: (items: string[]) => void;
}

const MAX_METRICS = 8;

export const MetricsSettings = ({
    initialSelection,
    onChanged,
}: MetricsSettingsProps) => {
    const [selection, setSelection] = useState<string[]>(
        initialSelection || []
    );

    const updateSelection = useCallback(
        (name: string, checked: boolean) => {
            if (selection.length >= MAX_METRICS && checked) return;
            if (checked) {
                setSelection([...selection, name]);
            } else {
                setSelection(selection.filter(item => item !== name));
            }
        },
        [selection]
    );

    useEffect(() => {
        onChanged(selection);
    }, [selection, onChanged]);

    return (
        <Wrapper>
            <Box display="grid" gridTemplateColumns="repeat(4, 3fr)">
                {METRICS.map((metric: string) => {
                    return (
                        <FormGroup key={metric}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selection.includes(metric)}
                                        onChange={(
                                            evt: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            updateSelection(
                                                metric,
                                                evt.target.checked
                                            );
                                        }}
                                    />
                                }
                                label={getTitleForMetric(metric)}
                            />
                        </FormGroup>
                    );
                })}
            </Box>
        </Wrapper>
    );
};
