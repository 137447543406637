import axios from 'axios';
import { format } from 'date-fns';
import { BACKEND_DATE_FORMAT } from '../../constants';
import { getAPIUrl } from '../../utils/api';

export interface DateRange {
    startDate: Date;
    endDate: Date;
}

export interface FetchDashboardDataProps {
    workspaceId: string;
    dateRange: DateRange;
}

export const fetchDashboardData = ({
    workspaceId,
    dateRange,
}: FetchDashboardDataProps) => {
    const { startDate, endDate } = dateRange;

    return axios.get(
        `${getAPIUrl()}/workspaces/${workspaceId}/dashboard/core-metrics`,
        {
            params: {
                startDate: format(startDate, BACKEND_DATE_FORMAT),
                endDate: format(endDate, BACKEND_DATE_FORMAT),
            },
        }
    );
};
