import {
    Box,
    Chip,
    Container,
    Divider,
    Paper,
    Typography,
} from '@material-ui/core';
import { ReactElement } from 'react';
import { SeinoLogo } from '../Graphics/SeinoLogo';

export interface InfoPanelProps {
    showLogo?: boolean;
    title?: string;
    subtitle?: string;
    dividerTitle?: string;
    content: ReactElement;
}

export const InfoPanel = ({
    showLogo = true,
    title = undefined,
    subtitle = undefined,
    dividerTitle = undefined,
    content,
}: InfoPanelProps) => {
    return (
        <Container
            maxWidth={'sm'}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: 'calc(100vh - 48px)',
            }}
        >
            <Paper
                style={{ minWidth: '300px', padding: '1rem' }}
                variant="outlined"
            >
                {showLogo && (
                    <Box
                        m={4}
                        style={{ display: 'grid', justifyContent: 'start' }}
                        component="div"
                    >
                        <SeinoLogo />
                    </Box>
                )}
                {title && (
                    <Typography
                        mx={4}
                        my={2}
                        style={{ textAlign: 'left' }}
                        paragraph={true}
                        variant="h4"
                    >
                        {title}
                    </Typography>
                )}

                <Box m={4} component="div">
                    {subtitle && (
                        <Typography variant="subtitle2" paragraph={true}>
                            {subtitle}
                        </Typography>
                    )}
                </Box>
                <Divider textAlign="left">
                    {dividerTitle && <Chip label={dividerTitle} />}
                </Divider>
                <Box m={4} component="div">
                    {content}
                </Box>
            </Paper>
        </Container>
    );
};
