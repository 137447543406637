import { Fragment } from 'react';
import { useSeinoAuth } from '../../hooks/use-seino-auth';
import { SplashScreen } from '../SplashScreen/SplashScreen';
import { ErrorPage } from '../ErrorPage/ErrorPage';

export const Auth: React.FC = ({ children }) => {
    const { authState, isLoading } = useSeinoAuth();

    if (authState === 'error') {
        return <ErrorPage />;
    }

    return isLoading ? <SplashScreen /> : <Fragment>{children}</Fragment>;
};
