import {
    Box,
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../components/Form/Button';
import { RuleBuilder } from '../../../components/Form/RuleBuilder/RuleBuilder';
import { createGroupedGAViewOptions } from '../../../components/Form/RuleBuilder/utils';
import { Select } from '../../../components/Form/Select';
import { SeinoLogo } from '../../../components/Graphics/SeinoLogo';
import { SplashScreen } from '../../../components/SplashScreen/SplashScreen';
import { useSeinoAuth } from '../../../hooks/use-seino-auth';
import { MarketType, Rule } from '../../../store/data-source';
import { useSeinoStore } from '../../../store/seino-store';
import { ErrorPage } from './ErrorPage';

export const ConfigureWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Wrapper = styled.div`
    padding: 5rem 12rem;
`;

export const InfoBox = styled(Box)`
    margin-top: 3rem;
`;

const pageTitle = 'Connect your own data sources';
const pageStepTitle = 'Step 3 of 3: Connect your own data sources';

export const ConfigureRules = () => {
    const {
        dataSource: {
            gaViews,
            getGAViews,
            getAggregationRules,
            aggregationRules,
            configureAggregationRules,
            loading,
        },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    const { fetchToken } = useSeinoAuth();

    const navigate = useNavigate();

    useEffect(() => {
        const doIt = async () => {
            if (!currentWorkspaceId) return;

            getAggregationRules(await fetchToken(), currentWorkspaceId);
        };
        doIt();
    }, [getAggregationRules, fetchToken, currentWorkspaceId]);

    useEffect(() => {
        const doIt = async () => {
            if (!currentWorkspaceId) return;
            getGAViews(await fetchToken(), currentWorkspaceId);
        };
        doIt();
    }, [getGAViews, fetchToken, currentWorkspaceId]);

    const [marketType, setMarketType] = useState<MarketType | undefined>(
        MarketType.MULTI_MARKET
    );
    useEffect(() => {
        setMarketType(aggregationRules?.type || MarketType.MULTI_MARKET);
    }, [aggregationRules]);
    const [multiRules, setMultiRules] = useState<Rule[]>([]);

    const updateRules = useCallback(
        (ruleData: Rule[]) => {
            setMultiRules(ruleData);
        },
        [setMultiRules]
    );

    const [selectedSingleMarket, setSelectedSingleMarket] = useState('');

    const finalizeMapping = useCallback(async () => {
        if (!currentWorkspaceId) return;

        let options: any = { type: marketType };
        if (marketType === MarketType.SINGLE_MARKET) {
            options.rule = {
                view_id: selectedSingleMarket,
                market: 'nl',
            };
        } else {
            options.rules = multiRules;
        }
        configureAggregationRules(
            await fetchToken(),
            currentWorkspaceId,
            options
        );
    }, [
        marketType,
        fetchToken,
        currentWorkspaceId,
        multiRules,
        selectedSingleMarket,
        configureAggregationRules,
    ]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMarketType(event.target.value as MarketType);
    };

    const visibilityForm = useCallback(
        (type: MarketType) => (type === marketType ? 'block' : 'none'),
        [marketType]
    );

    if (loading.analytics) return <SplashScreen />;

    //TODO Make a nicer error page
    if (!gaViews || gaViews.length === 0) {
        return (
            <ErrorPage
                title={pageTitle}
                stepTitle={pageStepTitle}
                errorTitle="We were not able to retrieve any Google Analytics views"
                errorDescription="Please go back to step 2, and verify if your Google Analytics is configured correctly"
                backUrl="/config/data-sources"
            />
        );
    }

    return (
        <ConfigureWrapper>
            <Wrapper>
                <SeinoLogo />
                <InfoBox my={2}>
                    <Typography my={4} variant="h4">
                        {pageTitle}
                    </Typography>
                    <Box mt={4}>
                        <Divider />
                    </Box>
                    <Typography mt={1} mb={1} variant="h6">
                        {pageStepTitle}
                    </Typography>
                    <Box>
                        <Divider />
                    </Box>
                    <Box my={4}>
                        <RadioGroup
                            row
                            aria-label="markets"
                            name="markets"
                            value={marketType}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                value={MarketType.SINGLE_MARKET}
                                control={<Radio />}
                                label="I operate in a single market"
                            />
                            <FormControlLabel
                                value={MarketType.MULTI_MARKET}
                                control={<Radio />}
                                label="I operate in multiple markets"
                            />
                        </RadioGroup>
                    </Box>
                    <Box
                        my={2}
                        display={visibilityForm(MarketType.SINGLE_MARKET)}
                        style={{
                            width: '300px',
                        }}
                    >
                        <Select
                            testId="select-ga-view"
                            size="small"
                            onChange={(evt: any) => {
                                const [group, value] = evt.value.split('---');

                                console.log(`${value} selected from ${group}`);

                                setSelectedSingleMarket(value);
                            }}
                            options={createGroupedGAViewOptions(gaViews, true)}
                        />
                    </Box>
                    <Box
                        display={visibilityForm(MarketType.MULTI_MARKET)}
                        padding="0"
                        margin="0"
                    >
                        <RuleBuilder
                            customerGAViews={gaViews}
                            onRulesUpdated={updateRules}
                            initialRules={(aggregationRules as any).rules}
                        />
                    </Box>
                    <Grid
                        my={4}
                        container
                        justifyContent="space-between"
                        spacing={2}
                    >
                        <Grid item>
                            <Button
                                startIcon={<ArrowBack />}
                                onClick={() => {
                                    navigate('/config/data-sources');
                                }}
                                variant="text"
                                color="primary"
                                title="Go back"
                            />
                        </Grid>
                        <Grid item>
                            <LoadingButton
                                style={{ height: '60px' }}
                                disableElevation={true}
                                size="large"
                                variant="contained"
                                loading={loading.mapping}
                                onClick={finalizeMapping}
                                fullWidth={false}
                            >
                                Finalize mapping
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </InfoBox>
            </Wrapper>
        </ConfigureWrapper>
    );
};
