import { GetState, SetState, StateCreator, StoreApi } from 'zustand';
import { GAView } from '../../components/Form/RuleBuilder/CreateRuleRow';
import { getAPIUrl, getRedirectUri } from '../../utils/api';

// TODO This store is deprecated,
// prefer workspaceManagementStore and accountManagementStore

export interface ConnectGAOptions {
    authType: 'refresh_token' | 'service_account';
    serviceAccount?: string;
}

export type AggregationConfig = {
    type?: MarketType;
    rules?: Rule[];
    rule?: {
        market: string;
        view_id: string;
    };
};

export interface Rule {
    field?: string;
    condition?: string;
    value?: string;
    market?: string;
    view_id?: string;
    currency?: string;
}

export enum MarketType {
    MULTI_MARKET = 'multi_market',
    SINGLE_MARKET = 'single_market',
}

// TODO Migrate these to work with `LoadingStatus`
interface Loading {
    getSources: boolean;
    getRules: boolean;
    esp: boolean;
    activeCampaign: boolean;
    copernica: boolean;
    analytics: boolean;
    mapping: boolean;
}

export interface APIErrors {
    connectGoogleAnalytics?: Response;
}

export interface DataSourceStore {
    hasEmailProviderConnection: boolean;
    hasActiveCampaignConnection: boolean;
    activeCampaignUrl: string;
    hasCopernicaConnection: boolean;
    hasAnalyticsProviderConnection: boolean;
    hasConfiguredAggregationRules: boolean;
    loading: Loading;
    errors: APIErrors;
    canopyDeployCredentials: {
        url?: string;
        username?: string;
        password?: string;
    };
    dataSourceConnectionsInitialized: boolean;
    getDataSourceConnections: (token: string, workspaceId: string) => void;
    gaViews: GAView[];
    getGAViews: (token: string, workspaceId: string) => void;
    removeGoogleAnalyticsConnection: (
        token: string,
        workspaceId: string
    ) => void;
    getCanopyDeployCredentials: (token: string, workspaceId: string) => void;
    connectGoogleAnalytics: (
        token: string,
        workspaceId: string,
        options: ConnectGAOptions
    ) => void;
    getAggregationRules: (token: string, workspaceId: string) => void;
    aggregationRules: AggregationConfig;
    configureAggregationRules: (
        token: string,
        workspaceId: string,
        options: AggregationConfig
    ) => void;
    configureActiveCampaign: (
        token: string,
        workspaceId: string,
        url: string,
        key: string
    ) => Promise<boolean>;
    configureCopernica: (
        token: string,
        workspaceId: string,
        copernicaToken: string
    ) => Promise<boolean>;
}

export const dataSourceStore: StateCreator<DataSourceStore> = (
    set: SetState<DataSourceStore>,
    get: GetState<DataSourceStore>, // @ts-ignore
    api: StoreApi<DataSourceStore>
) => ({
    loading: {
        getSources: false,
        esp: false,
        activeCampaign: false,
        copernica: false,
        analytics: false,
        getRules: false,
        mapping: false,
    },
    errors: {},
    aggregationRules: {},
    canopyDeployCredentials: {},
    dataSourceConnectionsInitialized: false,
    gaViews: [],
    hasEmailProviderConnection: false,
    hasAnalyticsProviderConnection: false,
    hasActiveCampaignConnection: false,
    activeCampaignUrl: '',
    hasCopernicaConnection: false,
    hasConfiguredAggregationRules: false,
    getAggregationRules: async (token: string, workspaceId: string) => {
        if (!token) return;

        set({ loading: { ...get().loading, getRules: true } });

        const response = await fetch(
            `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/aggregation-rules`,
            {
                method: 'GET',
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        const loadedRules = await response.json();

        set({
            loading: { ...get().loading, getRules: false },
            aggregationRules: loadedRules,
        });
    },
    configureAggregationRules: async (
        token: string,
        workspaceId: string,
        options: AggregationConfig
    ) => {
        if (!token) return;

        set({ loading: { ...get().loading, mapping: true } });

        const response = await fetch(
            `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/aggregation-rules`,
            {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(options),
            }
        );

        set({ loading: { ...get().loading, mapping: false } });

        if (response.ok) {
            window.location.pathname = '/config/data-sources';
        }
    },
    connectGoogleAnalytics: async (
        token: string,
        workspaceId: string,
        options: ConnectGAOptions
    ) => {
        const { authType, serviceAccount } = options;

        let body = {
            redirectUri: getRedirectUri(),
            authenticationType: authType,
        };

        set({ loading: { ...get().loading, analytics: true } });

        if (authType === 'service_account' && serviceAccount) {
            body = Object.assign(
                {
                    serviceAccount: serviceAccount
                        .replace(/\r?\n|\r/g, ' ')
                        .trim(),
                },
                body
            );
        }
        const apiResponse = await fetch(
            `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/google-analytics/connect?redirectUri=${getRedirectUri()}`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(body),
            }
        );

        if (!apiResponse.ok) {
            set({
                errors: {
                    ...get().errors,
                    connectGoogleAnalytics: apiResponse,
                },
            });
        } else {
            const data = await apiResponse.json();
            window.location.href = data.url;
        }

        set({ loading: { ...get().loading, analytics: false } });
    },
    getCanopyDeployCredentials: async (token: string, workspaceId: string) => {
        if (!token) return;

        set({ loading: { ...get().loading, esp: true } });

        const response = await fetch(
            `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/canopy-deploy/connect`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        set({ loading: { ...get().loading, esp: false } });

        set({
            canopyDeployCredentials: {
                username: data.username,
                password: data.password,
                url: data.url,
            },
        });
    },
    getDataSourceConnections: async (token: string, workspaceId: string) => {
        if (!token) return;

        set({ loading: { ...get().loading, getSources: true } });

        const response = await fetch(
            `${getAPIUrl()}/workspaces/${workspaceId}/data-sources`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        set({
            loading: { ...get().loading, getSources: false },
            dataSourceConnectionsInitialized: true,
            hasEmailProviderConnection: data.hasEmailProviderConnection,
            hasActiveCampaignConnection: data.hasActiveCampaignConnection,
            hasCopernicaConnection: data.hasCopernicaConnection,
            hasAnalyticsProviderConnection: data.hasAnalyticsProviderConnection,
            hasConfiguredAggregationRules: data.hasConfiguredAggregationRules,
            activeCampaignUrl: data.activeCampaignUrl,
        });
    },
    removeGoogleAnalyticsConnection: async (
        token: string,
        workspaceId: string
    ) => {
        if (!token) return;

        await fetch(
            `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/google-analytics`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
    },
    getGAViews: async (token: string, workspaceId: string) => {
        if (!token) return;
        set({ loading: { ...get().loading, analytics: true } });

        const response = await fetch(
            `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/google-analytics/views`,

            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        set({ loading: { ...get().loading, analytics: false } });
        set({
            gaViews: data.views,
        });
    },
    configureActiveCampaign: async (token, workspaceId, url, key) => {
        set({ loading: { ...get().loading, activeCampaign: true } });

        const apiResponse = await fetch(
            `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/active-campaign/connect`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ url, key }),
            }
        );

        set({
            loading: {
                ...get().loading,
                activeCampaign: false,
            },
        });

        return apiResponse.ok;
    },
    configureCopernica: async (
        token: string,
        workspaceId: string,
        copernicaToken: string
    ) => {
        if (!token) return false;
        set({ loading: { ...get().loading, copernica: true } });

        const apiResponse = await fetch(
            `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/copernica/connect`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ token: copernicaToken }),
            }
        );

        set({
            loading: {
                ...get().loading,
                copernica: false,
            },
        });

        return apiResponse.ok;
    },
});
