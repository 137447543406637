import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Metrics } from '../components/MetricChart/Metrics';
import { ExplorerLayout } from '../layouts/ExplorerLayout';
import { SimpleLayout } from '../layouts/SimpleLayout';
import { AccountManagement } from '../pages/AccountManagement/AccountManagement';
import { Configure } from '../pages/Config/DataSources/Configure';
import { ConfigureActiveCampaign } from '../pages/Config/DataSources/ConfigureActiveCampaign';
import { ConfigureCanopyDeploy } from '../pages/Config/DataSources/ConfigureCanopyDeploy';
import { ConfigureCopernica } from '../pages/Config/DataSources/ConfigureCopernica';
import { ConfigureESP } from '../pages/Config/DataSources/ConfigureESP';
import { ConfigureGA } from '../pages/Config/DataSources/ConfigureGA';
import { ConfigureRules } from '../pages/Config/DataSources/ConfigureRules';
import { Explorer } from '../pages/Explorer/Explorer';
import { NoWorkspaces } from '../pages/Info/NoWorkspaces';
import { Login } from '../pages/Login/Login';
import { Logout } from '../pages/Logout/Logout';
import { Onboarding } from '../pages/Onboarding/Onboarding';
import { Profile } from '../pages/Profile/Profile';
import { WorkspaceManagement } from '../pages/WorkspaceManagement/WorkspaceManagement';
import { AccountAdminRoute, SeinoRoute } from './ProtectedRoute';

export interface IRouterProps {
    isAccountAdmin: boolean;
    onboardingRequired: boolean;
    hasWorkspaces: boolean;
}

export const SeinoRouter = ({
    isAccountAdmin,
    onboardingRequired,
    hasWorkspaces,
}: IRouterProps) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route element={<SimpleLayout />}>
                    <Route path="/onboarding" element={<Onboarding />} />
                    <Route
                        path="/account-management"
                        element={
                            <AccountAdminRoute isAccountAdmin={isAccountAdmin}>
                                <AccountManagement />
                            </AccountAdminRoute>
                        }
                    />
                    <Route
                        path="/account-management/:accountId"
                        element={
                            <AccountAdminRoute isAccountAdmin={isAccountAdmin}>
                                <AccountManagement />
                            </AccountAdminRoute>
                        }
                    />
                    <Route
                        path="/account-management/:accountId/:selectedTab"
                        element={
                            <AccountAdminRoute isAccountAdmin={isAccountAdmin}>
                                <AccountManagement />
                            </AccountAdminRoute>
                        }
                    />
                    <Route
                        path="/workspace-management/:workspaceId/:selectedTab"
                        element={
                            <AccountAdminRoute isAccountAdmin={isAccountAdmin}>
                                <WorkspaceManagement />
                            </AccountAdminRoute>
                        }
                    />
                </Route>
                <Route element={<ExplorerLayout />}>
                    <Route path="*" element={<>Not found</>} />
                    <Route
                        path="/profile"
                        element={
                            <SeinoRoute onboardingRequired={onboardingRequired}>
                                <Profile />
                            </SeinoRoute>
                        }
                    />
                    {/* Are these still relevant? */}
                    <Route
                        path="/config/data-sources/email-service-provider"
                        element={
                            <SeinoRoute onboardingRequired={onboardingRequired}>
                                <ConfigureESP />
                            </SeinoRoute>
                        }
                    />
                    <Route
                        path="/config/data-sources/email-service-provider/active-campaign"
                        element={
                            <SeinoRoute onboardingRequired={onboardingRequired}>
                                <ConfigureActiveCampaign />
                            </SeinoRoute>
                        }
                    />
                    <Route
                        path="/config/data-sources/email-service-provider/canopy-deploy"
                        element={
                            <SeinoRoute onboardingRequired={onboardingRequired}>
                                <ConfigureCanopyDeploy />
                            </SeinoRoute>
                        }
                    />
                    <Route
                        path="/config/data-sources/email-service-provider/copernica"
                        element={
                            <SeinoRoute onboardingRequired={onboardingRequired}>
                                <ConfigureCopernica />
                            </SeinoRoute>
                        }
                    />
                    <Route
                        path="/config/data-sources/analytics"
                        element={
                            <SeinoRoute onboardingRequired={onboardingRequired}>
                                <ConfigureGA />
                            </SeinoRoute>
                        }
                    />
                    <Route
                        path="/config/data-sources/rules"
                        element={
                            <SeinoRoute onboardingRequired={onboardingRequired}>
                                <ConfigureRules />
                            </SeinoRoute>
                        }
                    />
                    <Route
                        path="/reporting/dashboard"
                        element={
                            <SeinoRoute onboardingRequired={onboardingRequired}>
                                <Metrics />
                            </SeinoRoute>
                        }
                    />
                    <Route
                        path="/config/data-sources"
                        element={
                            <SeinoRoute onboardingRequired={onboardingRequired}>
                                <Configure />
                            </SeinoRoute>
                        }
                    />
                    <Route
                        path="/explorer"
                        element={
                            <SeinoRoute
                                conditions={[
                                    {
                                        navigateTo: '/info/no-workspaces',
                                        condition: () => hasWorkspaces,
                                    },
                                ]}
                                onboardingRequired={onboardingRequired}
                            >
                                <Explorer />
                            </SeinoRoute>
                        }
                    />
                    <Route
                        path="/"
                        element={<Navigate to="/explorer" replace />}
                    />
                </Route>
                <Route path="/info" element={<SimpleLayout />}>
                    <Route
                        path="no-workspaces"
                        element={
                            <NoWorkspaces isAccountAdmin={isAccountAdmin} />
                        }
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
