import { IAggFunc, ValueGetterParams } from "ag-grid-community";

export class WeightedValue {
    constructor(
        public value: number,
        public weight: number,
    ) { }

    valueOf() {
        return this.weight === 0
            ? 0
            : this.value / this.weight
    }

    toString() {
        return this.valueOf().toFixed(4)
    }
}

export const weightedValueGetter = (valueMetric: string, weightMetric: string) =>
    ({data}: ValueGetterParams) =>
        new WeightedValue(data?.[valueMetric] || 0, data?.[weightMetric] || 0)

export const weightedAverage: IAggFunc = ({values}) => {
    let totalValue = 0, totalWeight = 0

    values.forEach((weightedVal) => {
        totalValue += weightedVal.value
        totalWeight += weightedVal.weight
    })

    return new WeightedValue(totalValue, totalWeight)
}
