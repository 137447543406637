import { Link, List, ListItem, ListItemText } from '@material-ui/core';
import { CheckCircleOutline, InfoRounded } from '@material-ui/icons';
import React, { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ColorNames } from '../../../utils/styles';

const StatusList = styled(List)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`;

const StatusListItem = styled(ListItem)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const CenteredList = styled(List)`
    text-align: center;
`;

const AvatarListItem = styled(ListItem)`
    && {
        margin-bottom: 0;
        padding-bottom: 0;
        margin-top: 0;
        padding-top: 0;
        justify-content: center;
    }
`;

const StatusLink = styled(Link)`
    justify-content: center;
`;

const UppercaseListItemText = styled(ListItemText)`
    text-transform: uppercase;
    margin: 0.1rem;
    padding: 0.1rem;
    text-align: center;
    color: ${ColorNames.TEXT_COLOR};
`;

export interface Status {
    isSuccess: boolean;
    primaryText: string;
    url?: string;
}

export interface LinkText {
    success: string;
    failure: string;
}

export interface StatusOverviewProps {
    listOfStatus: Status[];
    linkText?: LinkText;
}

export const StatusOverview = ({
    listOfStatus,
    linkText = { success: 'Show configuration', failure: 'Configure' },
}: StatusOverviewProps) => {
    const navigate = useNavigate();

    const handleUrlClick = (event: MouseEvent, url: string) => {
        event.preventDefault();

        if (url) {
            navigate(url);
        }
    };

    const item = (index: number, status: Status) => (
        <StatusListItem key={`connection-${index}`}>
            <CenteredList>
                <AvatarListItem>
                    {status.isSuccess ? (
                        <CheckCircleOutline color="success" />
                    ) : (
                        <InfoRounded color="error" />
                    )}
                </AvatarListItem>
                <UppercaseListItemText secondary={status.primaryText} />
                {status?.url && (
                    <StatusLink
                        onClick={(event: MouseEvent) =>
                            handleUrlClick(event, status.url!)
                        }
                        variant={'body2'}
                        href={status.url}
                    >
                        {status.isSuccess ? linkText.success : linkText.failure}
                    </StatusLink>
                )}
            </CenteredList>
        </StatusListItem>
    );

    return (
        <StatusList>
            {listOfStatus.map((status, index) => item(index, status))}
        </StatusList>
    );
};
