/**
 * Implementation of split that doesn't truncate after the nth item.
 *
 *  > 'a_b_c'.split('_', 2)
 *  ['a', 'b']
 *
 * vs
 *
 *  > split('a_b_c', '_', 2)
 *  ['a', 'b_c']
 */
export const split = (
    str: string | null | undefined,
    delimiter: string,
    n: number
) => {
    if (str === null || typeof str === 'undefined') {
        return [];
    }

    const all = str?.split(delimiter);

    return n >= all.length
        ? all
        : all.slice(0, n - 1).concat(all.slice(n - 1).join(delimiter));
};
