import {
    Box,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Typography,
} from '@material-ui/core';
import { ArrowBack, Close, FileCopy } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../components/Form/Button';
import { SeinoLogo } from '../../../components/Graphics/SeinoLogo';
import { SplashScreen } from '../../../components/SplashScreen/SplashScreen';
import { useSeinoAuth } from '../../../hooks/use-seino-auth';
import { useSeinoStore } from '../../../store/seino-store';
import { ColorNames } from '../../../utils/styles';

export const ConfigureWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Wrapper = styled.div`
    padding: 5rem 12rem;
`;

export const InfoBox = styled(Box)`
    margin-top: 1rem;
`;

export const ConfigureCanopyDeploy = () => {
    const {
        dataSource: { canopyDeployCredentials, getCanopyDeployCredentials },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    const { fetchToken } = useSeinoAuth();

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const doIt = async () => {
            if (!currentWorkspaceId) return;
            getCanopyDeployCredentials(await fetchToken(), currentWorkspaceId);
        };
        doIt();
    }, [getCanopyDeployCredentials, fetchToken, currentWorkspaceId]);

    if (
        !canopyDeployCredentials.username ||
        !canopyDeployCredentials.password ||
        !canopyDeployCredentials.url
    ) {
        return (
            <ConfigureWrapper>
                <SplashScreen />
            </ConfigureWrapper>
        );
    }

    return (
        <ConfigureWrapper>
            <Wrapper>
                <SeinoLogo />
                <InfoBox my={2}>
                    <Typography my={4} variant="h4">
                        Connect your own data sources
                    </Typography>

                    <Box mt={4}>
                        <Divider />
                    </Box>

                    <Typography mt={1} mb={1} variant="h6">
                        Step 1 of 3: Configure your e-mail service provider
                    </Typography>

                    <Box mb={4}>
                        <Divider />
                    </Box>

                    <Box my={1}>
                        <Typography my={2}>
                            To setup your CanopyDeploy connection you need to
                            install the PEGA App in the CanopyDeploy Store.
                        </Typography>
                        <Typography my={2}>
                            When you install this app you can use your
                            credentials below to send data from CanopyDeploy to
                            your SEINō account.
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            paragraph={true}
                            variant="overline"
                            mt={4}
                            mb={1}
                        >
                            Canopy Deploy settings
                        </Typography>
                        <Box
                            padding="24px"
                            borderRadius="15px"
                            display="grid"
                            alignItems="center"
                            gridTemplateColumns="1fr 3fr 1fr"
                            style={{
                                gridRowGap: '24px',
                                backgroundColor:
                                    ColorNames.BACKGROUND_GREY_DARK,
                            }}
                        >
                            <Typography variant="body2" fontWeight="bold">
                                URL
                            </Typography>
                            <Typography variant="body2">
                                {canopyDeployCredentials.url}
                            </Typography>
                            <Box>
                                <IconButton
                                    onClick={() => {
                                        if (canopyDeployCredentials.url) {
                                            navigator.clipboard.writeText(
                                                canopyDeployCredentials.url
                                            );
                                            setOpenSnackbar(true);
                                        }
                                    }}
                                >
                                    <FileCopy />
                                </IconButton>
                            </Box>
                            <Typography variant="body2" fontWeight="bold">
                                Username
                            </Typography>

                            <Typography variant="body2">
                                {canopyDeployCredentials.username}
                            </Typography>
                            <Box />
                            <Typography variant="body2" fontWeight="bold">
                                Password
                            </Typography>
                            <Typography variant="body2">
                                <code>{canopyDeployCredentials.password}</code>
                            </Typography>
                            <Box>
                                <IconButton
                                    onClick={() => {
                                        if (canopyDeployCredentials.password) {
                                            navigator.clipboard.writeText(
                                                canopyDeployCredentials.password
                                            );
                                            setOpenSnackbar(true);
                                        }
                                    }}
                                >
                                    <FileCopy />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box my={4}>
                        <Divider />
                    </Box>

                    <Grid
                        my={4}
                        container
                        justifyContent="space-between"
                        spacing={2}
                    >
                        <Grid item>
                            <Button
                                startIcon={<ArrowBack />}
                                onClick={() => {
                                    navigate(
                                        '/config/data-sources/email-service-provider'
                                    );
                                }}
                                variant="text"
                                color="primary"
                                title="Go back"
                            />
                        </Grid>

                        <Grid item>
                            <Button
                                onClick={() => navigate('/config/data-sources')}
                                fullWidth={false}
                                variant="contained"
                                title="Continue"
                            />
                        </Grid>
                    </Grid>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={() => setOpenSnackbar(false)}
                        message="Copied to clipboard!"
                        action={
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => setOpenSnackbar(false)}
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        }
                    />
                </InfoBox>
            </Wrapper>
        </ConfigureWrapper>
    );
};
