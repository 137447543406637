import { Downloading, Info } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { CallButton, MessageWrapper, TextWrapper } from './CallToAction.styles';

export enum CallToActionState {
    OK = 'ok',
    WAITING_FOR_SETUP = 'waiting-for-setup',
    WAITING_FOR_DATA = 'waiting-for-data',
    TRIAL_MODE = 'trial-mode',
    TRIAL_EXPIRED = 'trial-expired',
}

export interface CallToActionProps {
    callToActionState?: CallToActionState;
    featureFlag: boolean;
}

const getContent = (state?: CallToActionState): JSX.Element | null => {
    switch (state) {
        case CallToActionState.TRIAL_EXPIRED:
            return (
                <MessageWrapper>
                    <Box display="flex" mx={2}>
                        <Downloading color="info" />
                        <TextWrapper variant="body2">
                            You are currently viewing demo data because your
                            free trial has expired. Please contact sales to
                            activate your account
                        </TextWrapper>
                    </Box>
                    <Box>
                        <CallButton
                            variant="contained"
                            size="small"
                            color="info"
                        >
                            Request Activation
                        </CallButton>
                    </Box>
                </MessageWrapper>
            );

        case CallToActionState.TRIAL_MODE:
            return (
                <MessageWrapper>
                    <Box display="flex" mx={2}>
                        <Downloading color="info" />
                        <TextWrapper variant="body2">
                            Free trial mode expires after 27 days.
                        </TextWrapper>
                    </Box>
                    <Box>
                        <CallButton
                            variant="contained"
                            size="small"
                            color="info"
                        >
                            Close Message
                        </CallButton>
                    </Box>
                </MessageWrapper>
            );

        case CallToActionState.WAITING_FOR_DATA:
            return (
                <MessageWrapper>
                    <Box mx={2}>
                        <Downloading color="info" />
                    </Box>
                    <TextWrapper variant="body2">
                        Your account is configured correctly, but we do not have
                        data for you yet. It can take ~2 hours for data to start
                        appearing in SEINo. Sit tight
                    </TextWrapper>
                    {/* TODO - hook this functionality up once it exists */}
                    {false ? (
                        <CallButton
                            variant="contained"
                            size="small"
                            color="info"
                        >
                            Notify me
                        </CallButton>
                    ) : (
                        <span></span>
                    )}
                </MessageWrapper>
            );

        case CallToActionState.WAITING_FOR_SETUP:
            return (
                <MessageWrapper>
                    <Box display="flex" mx={2}>
                        <Info color="info" />
                        <TextWrapper variant="body2">
                            We are currently importing and processing your data
                            sources. We can notify you when your data is
                            available
                        </TextWrapper>
                    </Box>
                    <Box>
                        <CallButton
                            disabled={true}
                            variant="contained"
                            size="small"
                            color="info"
                        >
                            Notify Me
                        </CallButton>
                    </Box>
                </MessageWrapper>
            );

        default:
            return null;
    }
};

export const CallToAction = ({
    callToActionState,
    featureFlag,
}: CallToActionProps) => {
    if (!featureFlag) return null;
    return getContent(callToActionState);
};
