import { Box } from '@mui/material';
import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSeinoAuth } from '../../hooks/use-seino-auth';
import { useSeinoStore } from '../../store/seino-store';
import { Auth0Workspace } from '../../store/user';
import { Select } from '../Form/Select';

export const WorkspaceSelector = ({ dark = true }: { dark?: boolean }) => {
    const {
        user: { currentWorkspaceId, workspaces },
    } = useSeinoStore();

    const [mappedWorkspaceOptions, setMappedWorkspaceOption] = useState<
        { value: string; label: string }[]
    >([]);

    const { selectWorkspace } = useSeinoAuth();

    useEffect(() => {
        const mapWorkspacesToOptions = (
            workspaces: Auth0Workspace[]
        ): { value: string; label: string }[] => {
            return workspaces.map(({ displayName, id }) => ({
                value: id,
                label: displayName,
            }));
        };

        const mapped = mapWorkspacesToOptions(workspaces);

        setMappedWorkspaceOption(mapped);
    }, [workspaces]);

    return (
        <Box width="250px">
            <Select
                dark={dark}
                border={false}
                size="small"
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                value={find(mappedWorkspaceOptions, [
                    'value',
                    currentWorkspaceId,
                ])}
                onChange={e => {
                    if (e?.value) selectWorkspace(e?.value);
                }}
                options={[
                    {
                        label: 'Select Workspace',
                        options: mappedWorkspaceOptions,
                    },
                ]}
            />
        </Box>
    );
};
