import { ValueFormatterParams } from 'ag-grid-community';

export const timeFormatter = (params: ValueFormatterParams) => {
    if (!params?.value) return '';

    const totalSeconds = parseInt(params.value)

    if (!totalSeconds) {
        return ''
    }

    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    const pad = (x: number) => x.toString().padStart(2, "0")

    return `${hours ? hours + ':' : ''}${hours ? pad(minutes) : minutes}:${pad(seconds)}`
};
