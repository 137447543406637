import { Box } from '@material-ui/core';
import { find, head } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { LoadingStatus } from '../../constants';
import { useSeinoStore } from '../../store/seino-store';
import { Select } from '../Form/Select';

export const ViewSelect = () => {
    const {
        views: { setSelectedViewKey, selectedViewKey, views },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    const [viewOptions, setViewOptions] = useState<any[]>([]);

    const handleViewSelected = useCallback(
        (evt: any) => {
            setSelectedViewKey(evt.value);
        },
        [setSelectedViewKey]
    );

    useEffect(() => {
        views.fetch({
            workspaceId: currentWorkspaceId,
        });
    }, [currentWorkspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!views.result || views.status !== LoadingStatus.SUCCESS) return;

        setViewOptions(
            views.result.map(({ title, viewId }) => ({
                value: viewId,
                label: title,
            }))
        );

        let newSelectedViewKey =
            window.localStorage.getItem('selectedViewKey') || '';
        let candidateView = newSelectedViewKey
            ? find(views.result, ['viewId', newSelectedViewKey])
            : undefined;

        if (candidateView) {
            setSelectedViewKey(candidateView.viewId);
        } else {
            let firstKey = head(views.result)?.viewId;
            firstKey && setSelectedViewKey(firstKey);
        }
    }, [views.status]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box px={2}>
            <Select
                size="large"
                border={false}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }}
                value={
                    find(viewOptions, ['value', selectedViewKey]) ||
                    viewOptions[0]
                }
                onChange={handleViewSelected}
                options={[
                    {
                        label: 'Select Views',
                        options: viewOptions,
                    },
                ]}
            ></Select>
        </Box>
    );
};
