import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Snackbar,
    Stack,
    TextField,
    Typography,
} from '@material-ui/core';
import { MailOutline } from '@material-ui/icons';
import { SvgIcon } from '@mui/material';
import React, {
    ChangeEvent,
    KeyboardEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import styled from 'styled-components';
import { LoadingStatus } from '../../constants';
import { theme } from '../../theme';

const MemberInvitationBox = styled(Box)`
    margin: 1rem 0 2rem 0;
`;

const Title = styled(Box)`
    margin-bottom: 1rem;
`;

const validEmailPattern = /^.+@[0-9a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

export interface MemberInvitationProps {
    status: LoadingStatus;
    onSubmit: (email: string, name: string) => void;
}
export const MemberInvitation = ({
    status,
    onSubmit,
}: MemberInvitationProps) => {
    const [shouldValidate, setShouldValidate] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [isValid, setIsValid] = useState(false);

    const emailOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    useEffect(() => {
        setShouldValidate(email.length > 0);
        setIsValid(!!email.match(validEmailPattern));
    }, [email]);

    const inviteButtonIcon = useCallback(() => {
        return status === LoadingStatus.LOADING ? (
            <CircularProgress color={'primary'} size={20} />
        ) : (
            <SvgIcon fontSize="small">
                <MailOutline />
            </SvgIcon>
        );
    }, [status]);

    useEffect(() => {
        if (status === LoadingStatus.SUCCESS) {
            setEmail('');
            setName('');
            setShouldValidate(false);
            setIsValid(false);
        }
    }, [status, setEmail, setName, setShouldValidate, setIsValid]);

    const onKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            onSubmit(email, name);
        }
    };

    return (
        <MemberInvitationBox>
            <Title>
                <Typography variant="body2">
                    Provide the e-mail address which will receive an invitation
                </Typography>
            </Title>

            <Stack
                direction="row"
                spacing={2}
                alignItems={'baseline'}
                justifyContent={'space-between'}
                maxWidth={500}
            >
                <TextField
                    color="info"
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                    id="member-name-label"
                    label="Name"
                    variant="standard"
                    value={name}
                    autoComplete="off"
                    onKeyPress={onKeyPress}
                    onChange={event => setName(event.target.value)}
                    sx={{ width: 400 }}
                />

                <TextField
                    color="info"
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                    id="member-email-label"
                    label="Email"
                    variant="standard"
                    value={email}
                    autoComplete="off"
                    error={shouldValidate && !isValid}
                    onKeyPress={onKeyPress}
                    onChange={emailOnChange}
                    sx={{ width: 400 }}
                />

                <Button
                    sx={{ paddingLeft: 4, paddingRight: 4 }}
                    size={'small'}
                    startIcon={inviteButtonIcon()}
                    disabled={
                        status === LoadingStatus.LOADING ||
                        (shouldValidate && !isValid)
                    }
                    variant={
                        status === LoadingStatus.LOADING
                            ? 'outlined'
                            : 'contained'
                    }
                    onClick={() => onSubmit(email, name)}
                    color={shouldValidate && !isValid ? 'error' : 'info'}
                >
                    {status === LoadingStatus.LOADING ? 'Sending' : 'Invite'}
                </Button>
            </Stack>

            <Typography
                variant="caption"
                color={theme.palette.error.main}
                display={shouldValidate && !isValid ? 'block' : 'none'}
                mt={1}
            >
                Please fill in a valid e-mail address
            </Typography>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={status === LoadingStatus.SUCCESS}
            >
                <Alert
                    sx={{ margin: '1rem 0 1rem 0' }}
                    variant={'filled'}
                    severity="success"
                >
                    Member successfully invited!
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={status === LoadingStatus.FAILED}
            >
                <Alert
                    sx={{ margin: '1rem 0 1rem 0' }}
                    variant={'filled'}
                    severity="error"
                >
                    Failed to invite member due to unexpected error.
                </Alert>
            </Snackbar>
        </MemberInvitationBox>
    );
};
