import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { ColorNames } from '../../utils/styles';

export const MainBox = styled(Box)<{ highlighted?: boolean }>`
    min-width: 100px;
    max-width: 350px;
    margin-top: 5px;
    text-align: left;
    height: 288px;
    width: 100;
    padding: 10px 2px;
    margin: 0 10px;
    cursor: pointer;

    color: ${({ highlighted }) =>
        highlighted ? ColorNames.WHITE : ColorNames.BLACK};

    background-color: ${({ highlighted }) =>
        highlighted ? ColorNames.PRIMARY_COLOR : ColorNames.WHITE};

    &:hover {
        cursor: pointer;
        background-color: ${({ highlighted }) =>
            highlighted ? ColorNames.PRIMARY_COLOR : ColorNames.GRAY_HOVER};
    }
`;

export const PercentageChange = styled(Typography)`
    color: ${(props: {
        highlighted?: boolean;
        percentagechange: number | undefined;
    }) =>
        props.highlighted
            ? ColorNames.WHITE
            : props.percentagechange && props.percentagechange < 0
            ? ColorNames.DANGER_COLOR
            : ColorNames.SUCCESS_COLOR};
`;

export const VSText = styled.div<{ highlighted?: boolean }>`
    margin-left: 4px;
    font-size: 12px;
    color: ${(props: { highlighted?: boolean }) =>
        props.highlighted ? ColorNames.WHITE : ColorNames.TEXT_GRAY};
`;

export const MetricsWrapper = styled.div`
    display: block;
    justify-content: space-around;
    padding: 0 8px;
`;

export const MetricChartGrid = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
`;

export const DashedLine = (highlighted: boolean = false) => ({
    series,
    lineGenerator,
    xScale,
    yScale,
}: any) => {
    return series.map(({ id, data, lineStyle }: any) => (
        <path
            key={id}
            d={lineGenerator(
                data.map((d: any) => {
                    return {
                        x: xScale(d.data.x),
                        y: yScale(d.data.y),
                    };
                })
            )}
            fill="none"
            stroke={
                id === 'last_period'
                    ? highlighted
                        ? '#ffffff'
                        : '#9e9e9e'
                    : '#60C2D5'
            }
            style={lineStyle}
        />
    ));
};
