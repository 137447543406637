import { ICellRendererFunc } from 'ag-grid-community';

export const marketRenderer: ICellRendererFunc = ({ value }) => {
    if (!value) return '';

    const marketNormalized = value.toUpperCase();

    return `<img src="/img/markets/${
        marketNormalized === 'UK' ? 'GB' : marketNormalized
    }.png" onerror="this.style.display='none'" style="vertical-align: text-bottom;"> ${marketNormalized}`;
};
