import { TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NoInfer } from './utils';

export type FormInputProps<Data extends object> = {
    name: keyof Data & string;
    label?: string;
    requiredMessage?: string;
} & TextFieldProps;

export const FormInput = <
    Data extends object = {
        'No form data provided (Use syntax: <FormInput<FormDataType>...)': true;
    }
>({
    name,
    type = 'text',
    label,
    placeholder,
    fullWidth,
    requiredMessage,
    children,
}: FormInputProps<NoInfer<Data>>) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <TextField
            type={type}
            label={label}
            margin="normal"
            size="small"
            placeholder={placeholder}
            fullWidth={fullWidth}
            variant="standard"
            error={!!errors[name]}
            helperText={errors[name]?.message}
            {...register(name as any, { required: requiredMessage })}
        >
            {children}
        </TextField>
    );
};
