import { Fade, Link } from '@material-ui/core';

import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { SeinoLogo } from '../Graphics/SeinoLogo';

const ErrorPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 75vh;
    width: 500px;
    margin: auto;
    justify-content: center;
    text-align: center;
`;

const LogoWrapper = styled.div`
    margin: 0 auto 3rem;
`;

const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Action = styled.div`
    margin: 0.125rem 0;
`;

export const ErrorPage = () => {
    const { logout } = useAuth0();

    return (
        <Fade in={true}>
            <ErrorPageWrapper>
                <LogoWrapper>
                    <SeinoLogo />
                </LogoWrapper>
                <p>Yikes, we encountered an error!</p>
                <p>
                    We've been notified and will try to fix it as soon as
                    possible. In the meantime, you can try the following to
                    mitigate the error:
                </p>

                <ActionsWrapper>
                    <Action>
                        <Link href="#" onClick={() => window.location.reload()}>
                            Refresh the page
                        </Link>
                    </Action>
                    <Action>
                        <Link
                            href="#"
                            onClick={() =>
                                logout({ returnTo: window.location.origin })
                            }
                        >
                            Sign in again
                        </Link>
                    </Action>
                    <Action>
                        <span>Try a different browser</span>
                    </Action>
                </ActionsWrapper>
            </ErrorPageWrapper>
        </Fade>
    );
};
