import { ColumnState } from "ag-grid-community";

/**
 * This function ensures the following behavior:
 *
 *  1. In pivot mode, AG grid shows all columns that have a value for `aggFunc`.
 *     Therefore, we need to ensure only those columns that should be visible
 *     should have a value for `aggFunc`, otherwise it should be `null`.
 *
 *  2. If the grid is in non-pivot mode, every column must have `aggFunc` set.
 *     Otherwise, its cells will be empty when rows are grouped.
 *
 *  3. Given that in our context there is only one sensible aggregation function
 *     for each column, we need to "pin" `aggFunc` to a predefined value listed
 *     in `aggFuncsByColId`.
 *
 */
export const fixAggFunc = (pivotEnabled: boolean) => (col: ColumnState) => ({
    ...col,
    aggFunc: pivotEnabled
        ? (col.hide ? null : aggFuncsByColId[col.colId || ''])
        : aggFuncsByColId[col.colId || '']
})

/**
 * Ensure that those and only those metrics columns that were visible in pivot
 * mode, are visible when switching back to non-pivot mode.
 */
export const fixHide = (col: ColumnState) => ({
    ...col,
    hide: col.colId?.startsWith("metric")
        ? !col.aggFunc
        : col.hide,
})

const aggFuncsByColId: Record<string, string> = {
    'metric_email_sent_all_sum': 'sum',
    'metric_email_sent_unique_sum': 'sum',
    'metric_email_open_unique_sum': 'sum',
    'metric_email_open_unique_rate': 'weightedAvg',
    'metric_email_clicks_unique_sum': 'sum',
    'metric_email_clicks_unique_rate': 'weightedAvg',
    'metric_email_clicks_all_sum': 'sum',
    'metric_email_clicks_all_rate': 'weightedAvg',
    'metric_email_clicks_to_open_all_rate': 'weightedAvg',
    'metric_email_clicks_to_open_unique_rate': 'weightedAvg',
    'metric_email_clicks_per_user_avg': 'weightedAvg',
    'metric_email_softbounce_sum': 'sum',
    'metric_email_softbounce_rate': 'weightedAvg',
    'metric_email_hardbounce_sum': 'sum',
    'metric_email_hardbounce_rate': 'weightedAvg',
    'metric_email_complaints_sum': 'sum',
    'metric_email_complaints_rate': 'weightedAvg',
    'metric_email_unsubscribe_sum': 'sum',
    'metric_email_unsubscribe_rate': 'weightedAvg',
    'metric_email_deliverability_rate': 'weightedAvg',
    'metric_website_session_sum': 'sum',
    'metric_website_session_time_sum': 'sum',
    'metric_website_bounce_sum': 'sum',
    'metric_website_session_time_avg': 'weightedAvg',
    'metric_website_transactions_sum': 'sum',
    'metric_website_conversion_rate': 'weightedAvg',
    'metric_website_revenue_sum': 'sum',
    'metric_website_revenue_per_session': 'weightedAvg',
    'metric_website_revenue_per_email_sent': 'weightedAvg',
    'metric_website_average_order_value': 'weightedAvg',
}
