import { addMonths, isAfter } from 'date-fns';
import { Dispatch } from 'react';
import { normalizeInterval } from './dateHelpers';

export type IntervalSide = 'start' | 'end';

export interface DateRangePickerState {
    isOpen: boolean;
    dateRange: Interval;
    currentSide: IntervalSide;
    lastVisibleMonth: Date;
}

export type DateRangePickerAction =
    | { type: 'toggle' }
    | { type: 'close' }
    | { type: 'selectDate'; date: Date }
    | { type: 'selectRange'; dateRange: Interval }
    | { type: 'adjustVisibleMonths'; offset: number }
    | { type: 'reset'; prevDateRange: Interval };

export type DateRangePickerDispatch = Dispatch<DateRangePickerAction>;

export function reducer(
    state: DateRangePickerState,
    action: DateRangePickerAction
): DateRangePickerState {
    switch (action.type) {
        case 'close':
            return { ...state, isOpen: false };

        case 'toggle':
            return { ...state, isOpen: !state.isOpen };

        case 'selectDate':
            return {
                ...state,
                dateRange: normalizeInterval({
                    start:
                        state.currentSide === 'start'
                            ? action.date
                            : state.dateRange.start,
                    end:
                        state.currentSide === 'end'
                            ? action.date
                            : isAfter(action.date, state.dateRange.end)
                            ? action.date
                            : state.dateRange.end,
                }),
                currentSide: state.currentSide === 'start' ? 'end' : 'start',
            };

        case 'selectRange':
            return {
                ...state,
                dateRange: normalizeInterval(action.dateRange),
                currentSide: 'start',
            };

        case 'adjustVisibleMonths':
            return {
                ...state,
                lastVisibleMonth: addMonths(
                    state.lastVisibleMonth,
                    action.offset
                ),
            };

        case 'reset':
            return {
                ...state,
                dateRange: normalizeInterval(action.prevDateRange),
                currentSide: 'start',
            };

        default:
            return state;
    }
}
