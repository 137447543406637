import {
    Box,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography,
} from '@material-ui/core';
import { ArrowBack, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../components/Form/Button';
import { SeinoLogo } from '../../../components/Graphics/SeinoLogo';
import { useSeinoAuth } from '../../../hooks/use-seino-auth';
import { useSeinoStore } from '../../../store/seino-store';

export const ConfigureWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Wrapper = styled.div`
    padding: 5rem 12rem;
`;

export const ConfigureCopernica = () => {
    const navigate = useNavigate();
    const { fetchToken } = useSeinoAuth();
    const [error, setError] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const [editingEnabled, setEditingEnabled] = useState(false);

    const {
        dataSource: {
            dataSourceConnectionsInitialized,
            configureCopernica,
            hasCopernicaConnection,
            loading: { copernica: loading },
        },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    useEffect(() => {
        if (!dataSourceConnectionsInitialized) {
            navigate('/config/data-sources');
        }
    }, [dataSourceConnectionsInitialized, navigate]);

    const handleSubmit = async () => {
        if (!currentWorkspaceId) return;

        const token = await fetchToken();
        const result = await configureCopernica(
            token,
            currentWorkspaceId,
            accessToken
        );

        if (result) {
            navigate('/config/data-sources');
        } else {
            setError(true);
        }
    };

    return (
        <ConfigureWrapper>
            <Wrapper>
                <SeinoLogo />
                <Typography my={4} variant="h4">
                    Connect your own data sources
                </Typography>
                <Box mt={4}>
                    <Divider />
                </Box>
                <Typography mt={1} mb={1} variant="h6">
                    Step 1 of 3: Configure your e-mail service provider
                </Typography>

                <Box mb={4}>
                    <Divider />
                </Box>

                <Typography style={{ maxWidth: 600 }}>
                    SEINō uses the Copernica REST API to retrieve data about
                    your mailings. In order to access the API, we need an access
                    token. Please create an access token through the{' '}
                    <Link
                        href="https://ms.copernica.com/en#/admin/account/access-tokens"
                        target="_blank"
                    >
                        API access token page
                    </Link>{' '}
                    in Copernica and copy it in the field below.
                </Typography>

                <Box mt={3}>
                    <TextField
                        label="Access token"
                        error={error && !loading && !!accessToken}
                        helperText={
                            accessToken &&
                            error &&
                            !loading &&
                            'The access token you provided was incorrect.'
                        }
                        disabled={loading}
                        fullWidth={true}
                        value={accessToken}
                        onFocus={e => e.target.select()}
                        onChange={e => setAccessToken(e.target.value)}
                        {...(hasCopernicaConnection && !editingEnabled
                            ? {
                                  disabled: true,
                                  value: '•'.repeat(64),
                                  InputProps: {
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <IconButton
                                                  onClick={() =>
                                                      setEditingEnabled(true)
                                                  }
                                                  edge="end"
                                                  sx={{ margin: 0 }}
                                              >
                                                  <Edit />
                                              </IconButton>
                                          </InputAdornment>
                                      ),
                                  },
                              }
                            : {})}
                    />
                </Box>
                <Grid
                    my={4}
                    container
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Grid item>
                        <Button
                            startIcon={<ArrowBack />}
                            onClick={() => {
                                navigate(
                                    '/config/data-sources/email-service-provider'
                                );
                            }}
                            variant="text"
                            color="primary"
                            title="Go back"
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            disabled={loading || !accessToken}
                            onClick={() => {
                                handleSubmit();
                            }}
                            fullWidth={false}
                            variant="contained"
                            title="Connect"
                            startIcon={
                                loading && <CircularProgress size={12} />
                            }
                        />
                    </Grid>
                </Grid>
            </Wrapper>
        </ConfigureWrapper>
    );
};
