import { ExitToApp } from '@material-ui/icons';
import { AccountCircle, Business, ManageAccounts } from '@mui/icons-material';
import {
    Box,
    Button,
    ListItemIcon,
    Menu,
    MenuItem,
    MenuList,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UsernameContainer } from './Header.styles';

export interface UserProps {
    username: string;
    showProfileLink: boolean;
    showAccountManagementLink: boolean;
}
const StyledMenuItem = styled(MenuItem)`
    && {
        color: black;
    }
`;
export const User = ({
    username,
    showProfileLink,
    showAccountManagementLink,
}: UserProps) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: { currentTarget: any }) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                size="small"
                disableElevation={true}
                variant="contained"
                style={{ textTransform: 'none', fontWeight: 400 }}
                onClick={handleClick}
            >
                <Box display="flex" alignItems="center" mr={1}>
                    <AccountCircle fontSize="small" color="secondary" />
                    <Box ml={1}>
                        <UsernameContainer variant="caption">
                            {username}
                        </UsernameContainer>
                    </Box>
                </Box>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuList dense>
                    {showProfileLink && (
                        <StyledMenuItem onClick={() => navigate('/profile')}>
                            <ListItemIcon>
                                <ManageAccounts fontSize="small" />
                            </ListItemIcon>
                            My profile
                        </StyledMenuItem>
                    )}
                    {showAccountManagementLink && (
                        <StyledMenuItem
                            onClick={() => navigate('/account-management')}
                        >
                            <ListItemIcon>
                                <Business fontSize="small" />
                            </ListItemIcon>
                            Manage account
                        </StyledMenuItem>
                    )}
                    <StyledMenuItem onClick={() => navigate('/logout')}>
                        <ListItemIcon>
                            <ExitToApp fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </StyledMenuItem>
                </MenuList>
            </Menu>
        </>
    );
};
