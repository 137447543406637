import { useAuth0 } from '@auth0/auth0-react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect } from 'react';
import { AUTH0_API_AUDIENCE, AUTH0_DEFAULT_SCOPE } from '../constants';
import { useSeinoStore } from '../store/seino-store';
import { Auth0Workspace, AuthenticationState } from '../store/user';
import { getAPIUrl } from '../utils/api';

const DEFAULT_OPTIONS = {
    audience: AUTH0_API_AUDIENCE,
    scope: AUTH0_DEFAULT_SCOPE,
};

// Move to separate file?
export interface InitialData {
    workspaces: Auth0Workspace[];
    isAccountAdmin: boolean;
    onboardingRequired: boolean;
}
const fetchInitialData = async (token: string): Promise<InitialData> => {
    const response = await fetch(`${getAPIUrl()}/me`, {
        headers: { Authorization: `Bearer ${token}` },
    });
    const {
        workspaces,
        isAccountAdmin,
        onboardingRequired = false,
    } = await response.json();

    return {
        workspaces: workspaces.map((x: any) => ({
            id: x.id,
            name: x.name,
            displayName: x.displayName,
            cubeToken: x.cubeToken,
        })),
        isAccountAdmin: isAccountAdmin,
        onboardingRequired: onboardingRequired,
    };
};

export interface SeinoAuth {
    isLoading: boolean;
    fetchToken: () => Promise<string>;
    authState: AuthenticationState;
    error: Error | null;
    currentWorkspaceId: string;
    selectWorkspace: (workspaceId: string) => void;
}

export const useSeinoAuth = (): SeinoAuth => {
    const {
        isLoading,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
        user,
    } = useAuth0();

    const {
        user: {
            authState,
            error,
            currentWorkspaceId,
            authenticate,
            switchWorkspace,
        },
    } = useSeinoStore();

    const ldClient = useLDClient();

    useEffect(() => {
        if (!isAuthenticated || !user) return;

        // Once authenticated, identify user in launchdarkly
        ldClient?.identify({
            key: user.sub,
            email: user.email,
            name: user.nickname,
        });
    }, [isAuthenticated, user, ldClient]);

    const fetchToken = useCallback(
        async () => await getAccessTokenSilently(DEFAULT_OPTIONS),
        [getAccessTokenSilently]
    );

    useEffect(() => {
        switch (true) {
            case isLoading:
                return;

            case authState === 'error':
                return;

            case authState === 'initializing' && !isAuthenticated:
                loginWithRedirect();
                return;

            case authState === 'initializing':
                authenticate(fetchToken, fetchInitialData);
                return;

            default:
                break;
        }
    }, [
        isLoading,
        authState,
        isAuthenticated,
        loginWithRedirect,
        authenticate,
        fetchToken,
        error,
    ]);

    return {
        isLoading: ['initializing', 'authenticating'].includes(authState),
        authState,
        error,
        fetchToken,
        currentWorkspaceId: currentWorkspaceId,
        selectWorkspace: (workspaceId: string) => {
            switchWorkspace(fetchToken, workspaceId);
        },
    };
};
