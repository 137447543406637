import { LoadingButton } from '@material-ui/lab';
import { Close, Delete, ErrorOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { LoadingStatus } from '../../../constants';
import { useSeinoStore } from '../../../store/seino-store';

const Wrapper = styled.div`
    background-color: #feeceb;
    padding: 24px;
    min-width: 566px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
`;

const DeleteViewPanel = ({ onClose }: { onClose: () => void }) => {
    const {
        views: { deleteView, selectedViewKey, currentView, views },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    const deleteIt = useCallback(async () => {
        if (currentWorkspaceId && selectedViewKey) {
            await deleteView.fetch({
                workspaceId: currentWorkspaceId,
                viewId: selectedViewKey,
            });

            views.fetch({ workspaceId: currentWorkspaceId });
        }
        setCloseOnComplete(true);
    }, [selectedViewKey, deleteView, currentWorkspaceId, views]);

    const [closeOnComplete, setCloseOnComplete] = useState(false);

    useEffect(() => {
        if (closeOnComplete && deleteView.status === LoadingStatus.SUCCESS) {
            onClose();
        }
    }, [closeOnComplete, onClose, deleteView.status]);

    return (
        <Wrapper>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center" mx={2}>
                    <Box mr={2}>
                        <ErrorOutline fontSize="small" color="error" />
                    </Box>
                    <Box>
                        <Typography>
                            Do you really want to delete this view:
                        </Typography>
                        <Typography fontWeight="bold">
                            {currentView.title}
                        </Typography>
                    </Box>
                </Box>
                <ButtonWrapper>
                    <LoadingButton
                        disableElevation={true}
                        size="small"
                        loading={deleteView.status === LoadingStatus.LOADING}
                        color="error"
                        endIcon={<Delete />}
                        data-testid="delete-view-button"
                        onClick={deleteIt}
                        variant="contained"
                        title="Save"
                    >
                        Yes, delete
                    </LoadingButton>
                    <LoadingButton
                        disableElevation={true}
                        startIcon={<Close />}
                        size="small"
                        onClick={onClose}
                        variant="contained"
                        color="primary"
                        title="Cancel"
                    >
                        No
                    </LoadingButton>
                </ButtonWrapper>
            </Box>
        </Wrapper>
    );
};

const Component = memo(DeleteViewPanel);
export { Component as DeleteViewPanel };
