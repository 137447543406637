import { Toolbar, Typography } from '@mui/material';
import styled from 'styled-components';
import { ColorNames } from '../../utils/styles';

export const SeinoToolbar = styled(Toolbar)`
    && {
        display: flex;
        padding: 0 8px;
        background-color: ${ColorNames.BLACK};
        justify-content: space-between;
        flex-direction: row;
        max-height: 48px;
        min-height: 48px;
        align-items: center;
    }
`;

export const UsernameContainer = styled(Typography)`
    text-transform: capitalize;
    color: ${ColorNames.WHITE};
`;
