import { CloudDone, CloudOff, CloudUpload } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { LoadingStatus } from '../../constants';
import { useSeinoStore } from '../../store/seino-store';

const UPDATE_LAST_SAVED_TOOLTIP_INTERVAL = 1000;

export const AutosaveIndicator = () => {
    const {
        views: { currentView, saveView },
    } = useSeinoStore();

    const [tooltip, setTooltip] = React.useState('');
    const [errored, setErrored] = useState(false);

    const getIcon = () => {
        switch (saveView.status) {
            case LoadingStatus.LOADING:
                return <CloudUpload color="info" />;
            case LoadingStatus.FAILED || errored:
                return <CloudOff color="error" />;

            default:
            case LoadingStatus.IDLE:
            case LoadingStatus.SUCCESS:
                return <CloudDone color="info" />;
        }
    };

    useEffect(() => {
        if (saveView.status === LoadingStatus.FAILED) {
            setErrored(true);
        }

        if (saveView.status === LoadingStatus.SUCCESS) {
            setErrored(false);
        }
    }, [saveView.status]);

    useEffect(() => {
        const updateTimeInterval = setInterval(
            () => setTooltip(formatDistanceToNow(currentView.modified)),
            UPDATE_LAST_SAVED_TOOLTIP_INTERVAL
        );
        return () => clearInterval(updateTimeInterval);
    }, [currentView.modified]);

    return (
        <Box mx={4}>
            <Tooltip title={`Last saved ${tooltip}`}>{getIcon()}</Tooltip>
        </Box>
    );
};
