import { Box, CircularProgress } from '@mui/material';
import { DetailGridInfo } from 'ag-grid-community';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo, useCallback, useEffect, useState } from 'react';
import {
    CallToAction,
    CallToActionState,
} from '../../components/CallToAction/CallToAction';
import { CALL_TO_ACTION_HEIGHT } from '../../components/CallToAction/CallToAction.styles';
import { ExplorerGrid } from '../../components/ExplorerGrid/ExplorerGrid';
import { CustomDimension } from '../../components/ExplorerGrid/grid/utils/customDimension';
import { GoalMetric } from '../../components/ExplorerGrid/grid/utils/goalMetric';
import { StoredView } from '../../components/ExplorerGrid/grid/utils/gridView';
import { Navigation } from '../../components/Navigation/Navigation';
import { LoadingStatus } from '../../constants';
import { useSeinoStore } from '../../store/seino-store';
import { GridContext } from './GridContext';
import { SnackbarMessage } from './SnackBarMessage';

export const NavigationHeight = 109;

const customDimensions: Record<string, CustomDimension[]> = {
    '01G5GS8F79D5E2ZRNBZ97W7Z9B': [
        {
            name: 'Custom mailtype',
            sourceField: 'broadcast_mailing_name',
            delimiter: '_',
            max: 5,
            index: 1,
        },
        {
            name: 'Custom category',
            sourceField: 'broadcast_mailing_name',
            delimiter: '_',
            max: 5,
            index: 2,
        },
        {
            name: 'Custom subject',
            sourceField: 'broadcast_mailing_name',
            delimiter: '_',
            max: 5,
            index: 3,
        },
        {
            name: 'Custom variant',
            sourceField: 'broadcast_mailing_name',
            delimiter: '_',
            max: 5,
            index: 4,
        },
    ],
};

const ExplorerWrapper = () => {
    const {
        dataGrid: { goalMetrics },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    useEffect(() => {
        if (currentWorkspaceId) {
            goalMetrics.fetch({ workspaceId: currentWorkspaceId });
        }
    }, [currentWorkspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

    if (
        [LoadingStatus.IDLE, LoadingStatus.LOADING].includes(goalMetrics.status)
    ) {
        return (
            <Box
                display="flex"
                width="100%"
                justifyContent="center"
                alignItems="center"
                minHeight="400px"
            >
                <CircularProgress />
            </Box>
        );
    }

    return <Explorer goalMetrics={goalMetrics.result || []} />;
};

interface ExplorerProps {
    goalMetrics: GoalMetric[];
}

const Explorer = ({ goalMetrics }: ExplorerProps) => {
    const { configureDataBanner } = useFlags();

    const {
        views: { dashboardMessage, currentView, autosave, firstDayOfWeek },
        dataGrid: { gridData, dateRange },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    const [grid, setGrid] = useState<DetailGridInfo | null>(null);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (currentWorkspaceId) {
            gridData.fetch({ dateRange, workspaceId: currentWorkspaceId });
        }
    }, [dateRange, currentWorkspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

    const autosaveCallback = useCallback(
        (view: StoredView) => {
            if (currentWorkspaceId) {
                autosave(view, currentWorkspaceId);
            }
        },
        [autosave, currentWorkspaceId]
    );

    const NO_DATA_BANNER =
        gridData &&
        gridData.status === LoadingStatus.SUCCESS &&
        gridData.result?.length === 0 &&
        configureDataBanner;

    return (
        <div>
            <GridContext.Provider value={{ grid }}>
                <Navigation />
            </GridContext.Provider>
            {NO_DATA_BANNER && (
                <CallToAction
                    callToActionState={CallToActionState.WAITING_FOR_DATA}
                    featureFlag={configureDataBanner}
                />
            )}
            <ExplorerGrid
                data={gridData.result}
                loading={gridData.status}
                autoSave={autosaveCallback}
                currentView={currentView}
                onApiReady={setGrid}
                firstDayOfWeek={firstDayOfWeek}
                customDimensions={customDimensions?.[currentWorkspaceId] || []}
                goalMetrics={goalMetrics}
                topMarginOffset={
                    NO_DATA_BANNER
                        ? NavigationHeight + CALL_TO_ACTION_HEIGHT
                        : NavigationHeight
                }
            />
            {dashboardMessage && <SnackbarMessage message={dashboardMessage} />}
        </div>
    );
};

const Component = memo(ExplorerWrapper);
export { Component as Explorer };
