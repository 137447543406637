import { find } from 'lodash';
import { AnalyticsView } from '../../../store/reseller-center';
import { countries } from '../../../utils/countries';
import { GAView } from './CreateRuleRow';
import { ConditionOptions, FieldOptions } from './helpers';

export const getTitleForRule = (
    rule: string,
    customerGAViews?: GAView[]
): string => {
    let returnValue = rule;

    const mappedGAViews = customerGAViews
        ? customerGAViews.map(viewData => {
              return { value: viewData.viewId, label: viewData.viewName };
          })
        : [];

    const searchList = [
        ...countries,
        ...FieldOptions,
        ...ConditionOptions,
        ...mappedGAViews,
    ];

    searchList.forEach(({ value }, index) => {
        if (rule === value) {
            returnValue = searchList[index].label;
        }
    });

    return returnValue;
};

export const getImageForCountryCode = (code: string): JSX.Element => {
    const found = find(countries, ['value', code]);
    if (!found) return <></>;

    return (
        <img
            src={`/img/markets/${code === 'UK' ? 'GB' : code}.png`}
            style={{ verticalAlign: 'text-bottom', paddingRight: '4px' }}
            alt=""
        />
    );
};

/**
 * @prefixValueWithGroup - prefixes the value with the group name,
 * this is a way of allowing the filter functionality to work
 * without having to supply a custom filter function.
 *
 * Tested with some real data (400kb) JSON file and this was unworkably slow.
 *
 * Prefixing the value with the [GROUP_NAME]---[VALUE] format allows the filter
 * to operate including the group name.
 *
 * NOTE:
 * Using this option means the value you receive from the Select will be prefixed
 * so you will have to retrieve it using
 * const [groupName, value] = value.split('---');
 */
export const createGroupedGAViewOptions = (
    views: GAView[] | AnalyticsView[],
    prefixValueWithGroup: boolean = false
) => {
    const result: any[] = [];

    new Set(views.map(({ propertyId }) => propertyId)).forEach(currentId => {
        result.push({
            label: views.filter(({ propertyId }) => propertyId === currentId)[0]
                .propertyName,
            options: views
                .filter(({ propertyId }) => propertyId === currentId)
                .map((view: GAView) => ({
                    value: prefixValueWithGroup
                        ? `${view.propertyName}---${view.viewId}`
                        : view.viewId,
                    label: view.viewName,
                })),
        });
    });

    return result;
};

// Grab the grouped option which matches the
// selected value to supply as `defaultValue` to the `Select` component
export const getDefaultOption = (
    groupedOptions: any[],
    selectedValue: string | undefined
):
    | (string & { label: string; value: string })
    | (string & readonly { label: string; value: string }[])
    | undefined => {
    if (!selectedValue) return;
    let result;
    groupedOptions.forEach(group => {
        group.options.forEach((option: { label: string; value: string }) => {
            if (option.value.indexOf(selectedValue) > -1) {
                result = option;
            }
        });
    });

    return result;
};
