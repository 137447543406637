import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header/Header';

export const SimpleLayout = ({
    showNavigation = false,
    showWorkspaceSelector = false,
}: {
    showNavigation?: boolean;
    showWorkspaceSelector?: boolean;
}) => {
    return (
        <>
            <Header
                showNavigation={showNavigation}
                showWorkspaceSelector={showWorkspaceSelector}
            />
            <Outlet />
        </>
    );
};
