import { Header } from "../components/Header/Header";
import { Outlet } from "react-router-dom";

export const ExplorerLayout = ({
    showNavigation = true,
    showWorkspaceSelector = true
}: {
    showNavigation?: boolean;
    showWorkspaceSelector?: boolean;
}) => {
  return (
    <>
      <Header
        showNavigation={showNavigation}
        showWorkspaceSelector={showWorkspaceSelector}
      />
      <Outlet />
    </>
  );
};
