import { StateCreator } from 'zustand';
import {
    AccountManagementStore,
    accountManagementStore,
} from './account-management';
import { DashboardStore, dashboardStore } from './dashboard';
import { DataGridStore, dataGridStore } from './data-grid';
import { DataSourceStore, dataSourceStore } from './data-source';
import { compose, createStore } from './helpers';
import { resellerCenterStore, ResellerCenterStore } from './reseller-center';
import { UserStore, userStore } from './user';
import { ViewStore, viewStore } from './view';
import {
    WorkspaceManagementStore,
    workspaceManagementStore,
} from './workspace-management';

export const LOADING_IDLE_TIMEOUT = 4000;

export type SeinoStore = {
    views: ViewStore;
    user: UserStore;
    dataSource: DataSourceStore;
    dashboard: DashboardStore;
    dataGrid: DataGridStore;
    resellerCenter: ResellerCenterStore;
    accountManagement: AccountManagementStore;
    workspaceManagement: WorkspaceManagementStore;
};

const storeCreator: StateCreator<SeinoStore> = (set, get, api) => {
    const views = compose<SeinoStore, 'views'>('views', viewStore)(
        set,
        get,
        api
    );

    const user = compose<SeinoStore, 'user'>('user', userStore)(set, get, api);

    const dashboardSource = compose<SeinoStore, 'dashboard'>(
        'dashboard',
        dashboardStore
    )(set, get, api);

    const dataSource = compose<SeinoStore, 'dataSource'>(
        'dataSource',
        dataSourceStore
    )(set, get, api);

    const dataGridSource = compose<SeinoStore, 'dataGrid'>(
        'dataGrid',
        dataGridStore
    )(set, get, api);

    const resellerCenterSource = compose<SeinoStore, 'resellerCenter'>(
        'resellerCenter',
        resellerCenterStore
    )(set, get, api);

    const accountManagement = compose<SeinoStore, 'accountManagement'>(
        'accountManagement',
        accountManagementStore
    )(set, get, api);

    const workspaceManagement = compose<SeinoStore, 'workspaceManagement'>(
        'workspaceManagement',
        workspaceManagementStore
    )(set, get, api);

    return {
        views: views,
        user: user,
        dataSource: dataSource,
        dashboard: dashboardSource,
        dataGrid: dataGridSource,
        resellerCenter: resellerCenterSource,
        accountManagement: accountManagement,
        workspaceManagement: workspaceManagement,
    };
};

export const useSeinoStore = createStore<SeinoStore>(storeCreator);
