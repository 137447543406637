import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    MetricChartGrid,
    MetricsWrapper,
    PercentageChange,
    VSText,
} from './MetricChart.styles';
import {
    formatMetric,
    getPercentageDifference,
    getTitleForMetric,
} from './utils';

export interface MetricPerformanceGridProps {
    metricName: string;
    highlighted?: boolean;
    data: any;
}

export const MetricPerformanceGrid = ({
    metricName,
    data,
    highlighted = false,
}: MetricPerformanceGridProps) => {
    const [percentageChange, setPercentageChange] = useState('0');
    const [percentageChangeYear, setPercentageChangeYear] = useState('0');

    useEffect(() => {
        if (!data || !metricName) return;

        // TODO refactor this logic for production
        const [current, last_period, last_year] = data;
        const { data: currentData = [] } = current;
        const { data: lastPeriodData = [] } = last_period;
        const { data: lastYearData = [] } = last_year;

        const currentPeriodValue =
            currentData.length > 1 ? +currentData[currentData.length - 1].y : 0;
        const lastPeriodValue =
            lastPeriodData.length > 1
                ? +lastPeriodData[lastPeriodData.length - 1].y
                : 0;

        const lastYearValue =
            lastYearData.length > 1
                ? +lastYearData[lastYearData.length - 1].y
                : 0;

        setPercentageChange(
            getPercentageDifference(currentPeriodValue, lastPeriodValue)
        );
        setPercentageChangeYear(
            getPercentageDifference(currentPeriodValue, lastYearValue)
        );
    }, [setPercentageChange, data]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <MetricsWrapper>
            <Box my={1}>
                <Typography variant="subtitle2">
                    {getTitleForMetric(metricName)}
                </Typography>
                <Typography variant="h6" component={'h2'}>
                    {formatMetric(
                        // sum of y values of data[0].data
                        data[0].data.map(({ y }: { y: number }) => y),
                        metricName
                    )}
                </Typography>
            </Box>
            <MetricChartGrid>
                <PercentageChange
                    highlighted={highlighted}
                    variant={'subtitle1'}
                    percentagechange={parseFloat(percentageChange)}
                >
                    {`${percentageChange}%`}
                </PercentageChange>
                <VSText highlighted={highlighted}>VS. LP</VSText>
            </MetricChartGrid>
            <MetricChartGrid>
                <PercentageChange
                    highlighted={highlighted}
                    variant={'subtitle1'}
                    percentagechange={parseFloat(percentageChangeYear)}
                >
                    {`${percentageChangeYear}%`}
                </PercentageChange>
                <VSText highlighted={highlighted}>VS. LY</VSText>
            </MetricChartGrid>
        </MetricsWrapper>
    );
};
