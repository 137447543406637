import {
    Box,
    CardActionArea,
    CardMedia,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../components/Form/Button';
import { SeinoLogo } from '../../../components/Graphics/SeinoLogo';

export const ConfigureWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Wrapper = styled.div`
    padding: 5rem 12rem;
`;

export const ConfigureESP = () => {
    const navigate = useNavigate();

    return (
        <ConfigureWrapper>
            <Wrapper>
                <SeinoLogo />

                <Typography my={4} variant="h4">
                    Connect your own data sources
                </Typography>

                <Box mt={4}>
                    <Divider />
                </Box>

                <Typography mt={1} mb={1} variant="h6">
                    Step 1 of 3: Configure your e-mail service provider
                </Typography>

                <Grid container my={4}>
                    <Grid item>
                        <ESPCard esp="canopy-deploy" />
                    </Grid>
                    <Grid item>
                        <ESPCard esp="copernica" />
                    </Grid>
                    <Grid item>
                        <ESPCard esp="active-campaign" />
                    </Grid>
                </Grid>

                <Button
                    startIcon={<ArrowBack />}
                    onClick={() => {
                        navigate('/config/data-sources');
                    }}
                    variant="text"
                    color="primary"
                    title="Go back"
                />
            </Wrapper>
        </ConfigureWrapper>
    );
};

interface ESPCardProps {
    esp: string;
}

const ESPCard = ({ esp }: ESPCardProps) => {
    const navigate = useNavigate();

    return (
        <Card
            sx={{
                display: 'flex',
                width: 200,
                height: 100,
                margin: 2,
            }}
            onClick={() => navigate(`${esp}`)}
        >
            <CardActionArea>
                <CardMedia
                    component="img"
                    image={`/img/esp/${esp}.svg`}
                    sx={{ padding: 4, boxSizing: 'border-box' }}
                />
            </CardActionArea>
        </Card>
    );
};
