import axios, { AxiosRequestConfig } from 'axios';
import { getAPIUrl } from './utils/api';

export const configureInterceptor = (fetchToken: () => Promise<string>) => {
    // Add a request interceptor
    axios.interceptors.request.use(
        async (config: AxiosRequestConfig) => {
            if (!config.url) return;

            // Do something before request is sent
            const base = new URL(config.url);
            if (base.origin === getAPIUrl()) {
                const token = await fetchToken();
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                };
            }
            return config;
        },
        (error: any) => {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        }
    );
};
