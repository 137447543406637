import { createTheme } from '@mui/material';
import { ColorNames } from './utils/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: ColorNames.BLACK,
            light: '#595959',
        },
        secondary: {
            main: ColorNames.WHITE,
        },
        divider: ColorNames.GREY_DISABLED,
        success: {
            main: ColorNames.SUCCESS_COLOR,
            light: ColorNames.SUCCESS_LIGHT_COLOR,
            dark: ColorNames.SUCCESS_DARK_COLOR,
            contrastText: ColorNames.WHITE,
        },
        info: {
            main: ColorNames.INFO_COLOR,
            contrastText: ColorNames.WHITE,
        },
        error: {
            main: ColorNames.DANGER_COLOR,
            light: ColorNames.DANGER_LIGHT_COLOR,
            dark: ColorNames.DANGER_DARK_COLOR,
            contrastText: ColorNames.WHITE,
        },
        warning: {
            main: ColorNames.WARNING_COLOR,
            light: ColorNames.WARNING_LIGHT_COLOR,
            dark: ColorNames.WARNING_DARK_COLOR,
            contrastText: ColorNames.WHITE,
        },
    },
    spacing: 8,
    typography: {
        h1: {
            fontSize: '4rem',
            fontWeight: 300,
        },
        h2: {
            fontSize: '3.25rem',
            fontWeight: 400,
        },
        h3: {
            fontSize: '2.75rem',
            fontWeight: 400,
        },
        h4: {
            fontSize: '2.125rem',
        },
        h5: {
            fontSize: '1.5rem',
        },
        h6: {
            fontSize: '1.25rem',
        },
        body1: {
            fontSize: '1rem',
        },
        subtitle1: {
            fontSize: '14px',
        },
        subtitle2: {
            fontSize: '1rem',
            fontWeight: 500,
        },
        body2: {
            fontSize: '0.875rem',
            color: ColorNames.TEXT_GRAY,
        },
        overline: {
            fontSize: '0.75rem',
        },
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    color: ColorNames.GREY_DISABLED,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.8rem',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderBottom: `1px double ${ColorNames.LIGHT_BLUE}`,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: `1px double ${ColorNames.GREY_DIVIDER}`,
                },
            },
        },
    },
});
