import { Card, CardActionArea, CardMedia, Grid } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useState } from 'react';
import { Button } from '../../../components/Form/Button';
import { ESP } from '../../../constants';
import { ConfigureActiveCampaign } from './DataSources/ConfigureActiveCampaign';
import { ConfigureCanopyDeploy } from './DataSources/ConfigureCanopyDeploy';
import { ConfigureCopernica } from './DataSources/ConfigureCopernica';

export interface ConfigureESPProps {
    esp?: ESP;
}

const getESPConfigurator = (esp: ESP) => {
    switch (esp) {
        case ESP.COPERNICA:
            return <ConfigureCopernica />;
        case ESP.ACTIVE_CAMPAIGN:
            return <ConfigureActiveCampaign />;
        case ESP.CANOPY_DEPLOY:
        default:
            return <ConfigureCanopyDeploy />;
    }
};

export const ConfigureESP = ({ esp }: ConfigureESPProps) => {
    const [selectedESP, setSelectedESP] = useState(esp);

    if (!selectedESP) {
        return (
            <Grid container my={1}>
                <Grid item>
                    <ESPCard
                        onSelectESP={setSelectedESP}
                        esp={ESP.CANOPY_DEPLOY}
                    />
                </Grid>
                <Grid item>
                    <ESPCard onSelectESP={setSelectedESP} esp={ESP.COPERNICA} />
                </Grid>
                <Grid item>
                    <ESPCard
                        onSelectESP={setSelectedESP}
                        esp={ESP.ACTIVE_CAMPAIGN}
                    />
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            {getESPConfigurator(selectedESP)}
            <Button
                startIcon={<ArrowBack />}
                onClick={() => setSelectedESP(undefined)}
                variant="text"
                color="primary"
                title="Go back"
            />
        </>
    );
};

interface ESPCardProps {
    esp: ESP;
    onSelectESP: (esp: ESP) => void;
}

const ESPCard = ({ esp, onSelectESP: onClick }: ESPCardProps) => {
    const getESPImageUrl = (esp: ESP): string => {
        let imageName = '';

        switch (esp) {
            case ESP.CANOPY_DEPLOY:
                imageName = 'canopy-deploy';
                break;
            case ESP.COPERNICA:
                imageName = 'copernica';
                break;
            case ESP.ACTIVE_CAMPAIGN:
                imageName = 'active-campaign';
                break;
            default:
                imageName = 'canopy-deploy';
                break;
        }

        return `/img/esp/${imageName}.svg`;
    };

    return (
        <Card
            sx={{
                display: 'flex',
                width: 200,
                height: 100,
                margin: 2,
            }}
            data-testid={`esp-card-${esp}`}
            onClick={() => onClick(esp)}
        >
            <CardActionArea>
                <CardMedia
                    component="img"
                    image={getESPImageUrl(esp)}
                    sx={{ padding: 4, boxSizing: 'border-box' }}
                />
            </CardActionArea>
        </Card>
    );
};
