import { Box } from '@material-ui/core';
import { toDate } from 'date-fns';
import locale from 'date-fns/locale/en-US';
import React from 'react';
import { useSeinoStore } from '../../store/seino-store';
import { DateRangePicker as NewDateRangePicker } from '../DateRangePicker/DateRangePicker';

export const RangePickerLab = () => {
    const {
        views: { firstDayOfWeek },
        dataGrid: {
            dateRange: { startDate, endDate },
            setDateRange,
        },
    } = useSeinoStore();

    return (
        <Box margin={1}>
            <NewDateRangePicker
                locale={locale}
                weekStartsOn={firstDayOfWeek}
                dateRange={{ start: startDate, end: endDate }}
                onChange={interval => {
                    setDateRange(toDate(interval.start), toDate(interval.end));
                }}
            />
        </Box>
    );
};
