import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback } from 'react';
import {
    DeepPartial,
    FormProvider,
    SubmitHandler,
    UnpackNestedValue,
    useForm,
} from 'react-hook-form';
import * as yup from 'yup';

interface FormProps<FormData extends object> {
    onSubmit: SubmitHandler<FormData>;
    defaultValues?: UnpackNestedValue<DeepPartial<FormData>>;
    validationSchema: yup.SchemaOf<FormData>;
}

type Props<FormData extends object> = React.PropsWithChildren<
    FormProps<FormData>
>;

export const FormWrapper = <FormData extends object>({
    onSubmit,
    validationSchema,
    defaultValues,
    children,
}: Props<FormData>) => {
    const methods = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const submitHandler: SubmitHandler<FormData> = useCallback(
        async data => {
            await onSubmit(data);
        },
        [onSubmit]
    );

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit<FormData>(submitHandler)}>
                {children}
            </form>
            {process.env.NODE_ENV === 'development' && (
                <DevTool control={methods.control} placement="top-left" />
            )}
        </FormProvider>
    );
};
