export const BACKEND_DATE_FORMAT = 'yyyy-MM-dd';
export const COL_STATE = 'colState';
export const FILTER_STATE = 'filterState';

export const AUTH0_API_AUDIENCE = 'https://api.seino.ai/';
export const AUTH0_DEFAULT_SCOPE = 'openid email profile';

export const LOCAL_STORAGE_KPIS = 'SEINO_KPIS';

export const GRID_VIEW_VERSION = '1.0';

export enum ESP {
    CANOPY_DEPLOY = 'canopyDeploy',
    COPERNICA = 'copernica',
    ACTIVE_CAMPAIGN = 'activeCampaign',
}

export const enum LoadingStatus {
    IDLE = 'IDLE',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export const COL_STATE_PROPS_MAP = {
    a: 'hide',
    b: 'pinned',
    c: 'sort',
    d: 'sortIndex',
    e: 'aggFunc',
    f: 'rowGroup',
    g: 'rowGroupIndex',
    h: 'pivot',
    i: 'width',
    j: 'pivotIndex',
    k: 'flex',
};

export interface ColMap {
    [key: string]: string;
}

export const COL_MAP: ColMap = {
    a: 'broadcast_campaign_type',
    b: 'broadcast_campaign_id',
    c: 'broadcast_campaign_name',
    d: 'broadcast_mailing_name',
    e: 'broadcast_mailing_subject',
    f: 'broadcast_mailing_type',
    g: 'broadcast_mailing_audience',
    h: 'broadcast_mailing_market',
    i: 'broadcast_mailing_send_date',
    j: 'broadcast_mailing_send_date_week',
    k: 'broadcast_mailing_send_date_month',
    l: 'broadcast_mailing_send_date_year',
    m: 'metric_email_sent_all_sum',
    n: 'metric_email_sent_unique_sum',
    o: 'metric_email_open_unique_sum',
    p: 'metric_email_open_unique_rate',
    q: 'metric_email_clicks_unique_sum',
    r: 'metric_email_clicks_unique_rate',
    s: 'metric_email_clicks_all_sum',
    t: 'metric_email_clicks_all_rate',
    u: 'metric_email_clicks_to_open_all_rate',
    v: 'metric_email_clicks_to_open_unique_rate',
    w: 'metric_email_clicks_per_user_avg',
    x: 'metric_email_softbounce_sum',
    y: 'metric_email_softbounce_rate',
    z: 'metric_email_hardbounce_sum',
    aa: 'metric_email_hardbounce_rate',
    ab: 'metric_email_complaints_sum',
    ac: 'metric_email_complaints_rate',
    ad: 'metric_email_unsubscribe_sum',
    ae: 'metric_email_unsubscribe_rate',
    af: 'metric_email_deliverability_rate',
    ag: 'metric_website_session_sum',
    ah: 'metric_website_session_time_sum',
    ai: 'metric_website_bounce_sum',
    aj: 'metric_website_session_time_avg',
    ak: 'metric_website_transactions_sum',
    al: 'metric_website_conversion_rate',
    am: 'metric_website_revenue_sum',
    an: 'metric_website_revenue_per_session',
    ao: 'metric_website_revenue_per_email_sent',
    ap: 'metric_website_average_order_value',
};

// TODO populate this list from the backend?
// Commented out values not yet supported formats (currency, time)
export const KPIS_METRICS: { [key: string]: string } = {
    metric_email_sent_all_sum: 'Sent all',
    metric_email_sent_unique_sum: 'Sent unique',
    metric_email_open_unique_sum: 'Open',
    metric_email_open_unique_rate: 'Open (%)',
    metric_email_clicks_unique_sum: 'Clicks unique (sum)',
    metric_email_clicks_unique_rate: 'Clicks unique (%)',
    metric_email_clicks_all_sum: 'Clicks all',
    metric_email_clicks_all_rate: 'Clicks all (%)',
    metric_email_clicks_to_open_all_rate: 'Clicks to open all (%)',
    metric_email_clicks_to_open_unique_rate: 'Clicks to open unique (%)',
    metric_email_clicks_per_user_avg: 'Clicks per user',
    metric_email_softbounce_sum: 'Soft bounces',
    metric_email_softbounce_rate: 'Soft bounces (%)',
    metric_email_hardbounce_sum: 'Hard bounces',
    metric_email_hardbounce_rate: 'Hard bounces (%)',
    metric_email_complaints_rate: 'Complaints (%)',
    metric_email_complaints_sum: 'Complaints',
    metric_email_unsubscribe_sum: ' Unsubscribes',
    metric_website_session_sum: 'Sessions',
    metric_website_bounce_sum: 'Bounces',
    metric_website_transactions_sum: 'Transactions',
    metric_website_revenue_sum: 'Revenue',
    metric_email_unsubscribe_rate: 'Deliverability (%)',
    metric_website_session_time_sum: 'Session time',
    metric_website_session_time_avg: 'Avg. Session Duration',
    metric_website_conversion_rate: 'Conversion Rate',
    metric_website_revenue_per_session: 'Revenue per session',
    metric_website_revenue_per_email_sent: 'Revenue per email sent',
    metric_website_average_order_value: 'Average order value',
};
