import { Container, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AccountSelector } from '../../components/AccountSelector/AccountSelector';
import { LoadingStatus } from '../../constants';
import { useSeinoStore } from '../../store/seino-store';
import { AccountTitle } from './components/ManageAccount/AccountTitle';
import { ManageAccount } from './components/ManageAccount/ManageAccount';

const StyledContainer = styled(Container)`
    margin: 2rem 0;
    width: 100%;
`;

const AccountInformationHeader = styled(Stack)`
    margin: 1rem 0 2rem 0;
    justify-content: space-between;
`;

export const AccountManagement = () => {
    const navigate = useNavigate();
    const {
        accountId: urlAccountId,
        selectedTab: urlTab = 'workspaces',
    } = useParams<{
        accountId: string | undefined;
        selectedTab: string;
    }>();

    useEffect(() => {
        setSelectedAccountId(urlAccountId);
        setSelectedTab(urlTab);
    }, [urlAccountId, urlTab]);

    const [selectedAccountId, setSelectedAccountId] = useState<
        string | undefined
    >(urlAccountId || undefined);
    const [selectedTab, setSelectedTab] = useState<string>(urlTab);

    const {
        accounts: { result, status, fetch: fetchAccounts },
    } = useSeinoStore(state => state.accountManagement);

    useEffect(() => {
        fetchAccounts(null);
    }, [fetchAccounts]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (result?.accounts && result?.accounts.length > 0) {
            setSelectedAccountId(urlAccountId || result?.accounts[0].id);
        }
    }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

    const workspaceOnSelect = (id: string) =>
        navigate(`/workspace-management/${id}/permissions`);

    return (
        <StyledContainer maxWidth={'xl'}>
            <AccountInformationHeader direction="row">
                <AccountTitle
                    loading={status === LoadingStatus.LOADING}
                    title={
                        result?.accounts?.find(a => a.id === selectedAccountId)
                            ?.displayName
                    }
                />

                {selectedAccountId && (
                    <AccountSelector
                        selectedAccountId={selectedAccountId}
                        onChange={value => {
                            setSelectedAccountId(value);
                            navigate(
                                `/account-management/${value}/${selectedTab}`
                            );
                        }}
                        accounts={
                            result?.accounts?.map(a => ({
                                id: a.id,
                                value: a.displayName,
                            })) || []
                        }
                    />
                )}
            </AccountInformationHeader>

            {selectedAccountId && (
                <ManageAccount
                    accountId={selectedAccountId}
                    selectedTab={selectedTab}
                    onTabChanged={tab => {
                        setSelectedTab(tab);
                        navigate(
                            `/account-management/${selectedAccountId}/${tab}`
                        );
                    }}
                    onWorkspaceSelected={workspaceId =>
                        workspaceOnSelect(workspaceId)
                    }
                />
            )}
        </StyledContainer>
    );
};
