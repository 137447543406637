import { Box, Button } from '@mui/material';
import { addDays, Day, eachWeekOfInterval, isFuture } from 'date-fns';
import { Dispatch, memo, useMemo } from 'react';
import styled from 'styled-components';
import { getWeekNumber } from '../../utils/weeks';
import { ensureRangeIsNotInFuture } from './dateHelpers';
import { DateRangePickerAction } from './reducer';

interface CalendarWeekNumbersProps {
    month: Interval;
    weekStartsOn: Day;
    dispatch: Dispatch<DateRangePickerAction>;
}

const CalendarWeekNumbers = ({
    month,
    weekStartsOn,
    dispatch,
}: CalendarWeekNumbersProps) => {
    const weeks = useMemo(() => eachWeekOfInterval(month, { weekStartsOn }), [
        month,
        weekStartsOn,
    ]);

    const selectWeek = (w: Date) => () => {
        dispatch({
            type: 'selectRange',
            dateRange: ensureRangeIsNotInFuture({
                start: w,
                end: addDays(w, 6),
            }),
        });
    };

    return (
        <Container>
            {weeks.map(w => (
                <StyledButton
                    key={getWeekNumber(w, weekStartsOn)}
                    onClick={selectWeek(w)}
                    disabled={isFuture(w)}
                >
                    {getWeekNumber(w, weekStartsOn)}
                </StyledButton>
            ))}
        </Container>
    );
};

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    justify-content: flex-start;
    margin-top: 64px;
    opacity: 0.5;
`;

const StyledButton = styled(Button).attrs({
    color: 'info',
    style: {
        minWidth: 0,
        height: 32,
        width: 32,
        borderRadius: 32,
        marginBottom: '4px',
    },
})``;

const Component = memo(CalendarWeekNumbers);
export { Component as CalendarWeekNumbers };
