import { Button, Chip, MenuItem, Select, Stack } from '@mui/material';
import React, { FormEvent, useEffect, useState } from 'react';
import { AvailableMember } from '../../store/workspace-management';

export interface GrantWorkspacePermissionProps {
    members: AvailableMember[];
    grantPermission: (memberId: string) => void;
}

export const GrantWorkspacePermission = ({
    members = [],
    grantPermission,
}: GrantWorkspacePermissionProps) => {
    const [selectedMember, setSelectedMember] = useState<string>('');

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const foundMember = members.find(m => m.id === selectedMember);
        if (foundMember !== undefined) {
            setSelectedMember('');
            grantPermission(foundMember.id);
        }
    };

    useEffect(() => setSelectedMember(''), [members, setSelectedMember]);

    return (
        <form onSubmit={onSubmit}>
            <Stack direction="row" spacing={4} mt={1}>
                <Select
                    disabled={members.length === 0}
                    displayEmpty
                    sx={{ width: 450 }}
                    variant="standard"
                    value={selectedMember}
                    onChange={e => setSelectedMember(e.target.value)}
                >
                    <MenuItem key={0} disabled value="">
                        <em>Select member...</em>
                    </MenuItem>
                    {members?.map(m => (
                        <MenuItem key={m.id} value={m.id}>
                            {m.name} ({m.email})
                            {m.type === 'invitation' && (
                                <Chip
                                    style={{
                                        marginLeft: '1rem',
                                    }}
                                    label={'INVITED'}
                                />
                            )}
                        </MenuItem>
                    ))}
                </Select>
                <Button
                    type="submit"
                    disabled={selectedMember.length === 0}
                    color="info"
                    variant="contained"
                >
                    Add
                </Button>
            </Stack>
        </form>
    );
};
