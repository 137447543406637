import * as Sentry from '@sentry/react';
import { GetState, SetState, StateCreator } from 'zustand';
import { InitialData } from '../../hooks/use-seino-auth';

export type AuthenticationState =
    | 'initializing' // Waiting for Auth0 SDK
    | 'authenticating' // Retrieving access token + workspaces
    | 'authenticated_without_workspace' // Signed in, but user is not linked to any workspaces
    | 'authenticated' // Signed in for specific workspace
    | 'error'; // An error occurred while authenticating, see `error` state property

export interface UserStore {
    authState: AuthenticationState;
    error: Error | null;
    tokenDeprecated: string;
    workspaces: Auth0Workspace[];
    isAccountAdmin: boolean;
    onboardingRequired: boolean;
    currentWorkspaceId: string;
    setCurrentWorkspaceId: (id: string) => void;

    authenticate: (
        getAccessToken: () => Promise<string>,
        fetchInitialData: (token: string) => Promise<InitialData>
    ) => void;

    switchWorkspace: (
        getAccessToken: (workspaceId: string) => Promise<string>,
        workspaceId: string
    ) => void;
}

export type Auth0Workspace = {
    id: string;
    name: string;
    displayName: string;
    cubeToken: string | null;
};

const initialWorkspaceId = window.localStorage.getItem('currentOrgid') || '';

const setTitle = (
    currentWorkspaceId: string,
    workspaces: Auth0Workspace[]
): void => {
    const currentWorkspace = workspaces.find(
        ws => ws.id === currentWorkspaceId
    );

    if (currentWorkspace) {
        document.title = `${currentWorkspace.displayName} | SEINō`;
    }
};

export const userStore: StateCreator<UserStore> = (
    set: SetState<UserStore>,
    get: GetState<UserStore>
) => ({
    authState: 'initializing',
    error: null,
    tokenDeprecated: '',
    workspaces: [],
    isAccountAdmin: false,
    onboardingRequired: false,
    currentWorkspaceId: initialWorkspaceId,
    setCurrentWorkspaceId: (workspaceId: string) => {
        set(state => ({
            ...state,
            currentWorkspaceId: workspaceId,
        }));
    },
    authenticate: async (fetchToken, fetchInitialData) => {
        set({ authState: 'authenticating' });
        try {
            const token = await fetchToken();
            const initialData = await fetchInitialData(token);

            // Set currentWorkspaceId to first workspace if
            //   A) there are initialData
            //   B) no currentWorkspaceId was found in local storage
            //   C) currentWorkspaceId was found in localStorage, but does not match any of the linked initialData
            const {
                workspaces,
                isAccountAdmin,
                onboardingRequired,
            } = initialData;
            const currentWorkspaceId =
                workspaces.length > 0 &&
                (!initialWorkspaceId ||
                    !workspaces.map(ws => ws.id).includes(initialWorkspaceId))
                    ? workspaces[0].id
                    : initialWorkspaceId;

            set({
                authState: 'authenticated',
                tokenDeprecated: token,
                workspaces: workspaces,
                isAccountAdmin: isAccountAdmin,
                currentWorkspaceId: currentWorkspaceId,
                onboardingRequired: onboardingRequired,
            });

            setTitle(currentWorkspaceId, workspaces);
        } catch (error) {
            set({ authState: 'error', error });
            Sentry.captureException(error);
        }
    },

    switchWorkspace: async (getAccessToken, workspaceId) => {
        set({ authState: 'authenticating' });
        try {
            const token = await getAccessToken(workspaceId);
            set({
                authState: 'authenticated',
                tokenDeprecated: token,
                currentWorkspaceId: workspaceId,
            });
            setTitle(workspaceId, get().workspaces);
            window.localStorage.setItem('currentOrgid', workspaceId);
        } catch (error) {
            set({ authState: 'error', error });
            Sentry.captureException(error);
        }
    },
});
