import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import { ArrowBack, CheckCircleOutline, Delete } from '@material-ui/icons';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SeinoLogo } from '../../../components/Graphics/SeinoLogo';
import { SplashScreen } from '../../../components/SplashScreen/SplashScreen';
import { useSeinoAuth } from '../../../hooks/use-seino-auth';
import { useSeinoStore } from '../../../store/seino-store';
import { Setup } from './GoogleAnalytics/Setup';

export const ConfigureWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Wrapper = styled.div`
    padding: 5rem 12rem;
`;

export const InfoBox = styled(Box)`
    margin-top: 3rem;
`;

export const ConfigureGA = () => {
    const {
        dataSource: {
            getDataSourceConnections,
            removeGoogleAnalyticsConnection,
            hasAnalyticsProviderConnection,
            loading,
        },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    const { fetchToken } = useSeinoAuth();

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [localLoading, setLocalLoading] = useState<boolean>(false);
    const [
        confirmDeleteDialogOpen,
        setConfirmDeleteDialogOpen,
    ] = useState<boolean>(false);

    useEffect(() => {
        const doIt = async () => {
            if (!currentWorkspaceId) return;
            getDataSourceConnections(await fetchToken(), currentWorkspaceId);
        };
        doIt();
    }, [getDataSourceConnections, fetchToken, currentWorkspaceId]);

    const removeAnalytics = async () => {
        setLocalLoading(true);
        try {
            const token = await fetchToken();
            removeGoogleAnalyticsConnection(token, currentWorkspaceId);
            getDataSourceConnections(token, currentWorkspaceId);
        } catch (err) {
            enqueueSnackbar('Removing Google Analytics connection failed.', {
                variant: 'error',
            });
            captureException(err);
        } finally {
            setLocalLoading(false);
            setConfirmDeleteDialogOpen(false);
        }
    };

    if (loading.getSources || localLoading) return <SplashScreen />;

    const alreadyConnectedResponse = (
        <>
            <Box mb={4}>
                <Typography mt={1} mb={1} variant="body1">
                    Google Analytics is already configured for this workspace
                </Typography>
                <List sx={{ width: 420 }}>
                    <ListItem
                        secondaryAction={
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => setConfirmDeleteDialogOpen(true)}
                            >
                                <Delete />
                            </IconButton>
                        }
                    >
                        <ListItemAvatar>
                            <CheckCircleOutline color="success" />
                        </ListItemAvatar>
                        <ListItemText
                            primary="Google Analytics V3 (UA)"
                            secondary="Successfully connected"
                        />
                    </ListItem>
                </List>

                <Button
                    startIcon={<ArrowBack />}
                    onClick={() => {
                        navigate('/config/data-sources');
                    }}
                    variant="text"
                    color="primary"
                >
                    Go back
                </Button>
            </Box>
            <Dialog
                open={confirmDeleteDialogOpen}
                onClose={() => setConfirmDeleteDialogOpen(false)}
            >
                <DialogContent>
                    Are you sure you want to remove this Google Analytics
                    connection?
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setConfirmDeleteDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button color="error" onClick={() => removeAnalytics()}>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

    return (
        <ConfigureWrapper>
            <Wrapper>
                <SeinoLogo />
                <InfoBox my={2}>
                    <Typography my={4} variant="h4">
                        Connect your own data sources
                    </Typography>

                    <Box mt={4}>
                        <Divider />
                    </Box>

                    <Typography mt={1} mb={1} variant="h6">
                        Step 2 of 3: Setup the connection to your Analytics
                    </Typography>

                    <Box mb={4}>
                        <Divider />
                    </Box>

                    {hasAnalyticsProviderConnection && alreadyConnectedResponse}

                    {!hasAnalyticsProviderConnection && <Setup />}
                </InfoBox>
            </Wrapper>
        </ConfigureWrapper>
    );
};
