import { components } from 'react-select';
import styled from 'styled-components';
import { getImageForCountryCode } from './utils';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const MarketOption = (props: any) => {
    return (
        <Wrapper>
            <components.Option {...props}>
                {getImageForCountryCode(props.value)}
                {props.label}
            </components.Option>
        </Wrapper>
    );
};

export const FieldOptions = [
    { value: 'content_name', label: 'Content Name' },
    { value: 'campaign_name', label: 'Campaign Name' },
    { value: 'market', label: 'Market' },
];

export const ConditionOptions = [
    { value: 'contains', label: 'Contains' },
    { value: 'starts_with', label: 'Starts with' },
    { value: 'ends_with', label: 'Ends with' },
];
