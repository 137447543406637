import { Box, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React from 'react';
import { DateRangePickerDispatch } from './reducer';

interface MonthSwitcherProps {
    dispatch: DateRangePickerDispatch;
    direction: 'next' | 'previous';
}

export const MonthSwitcher = ({ dispatch, direction }: MonthSwitcherProps) => {
    const handleClick = () =>
        dispatch({
            type: 'adjustVisibleMonths',
            offset: direction === 'next' ? 1 : -1,
        });

    const icon = direction === 'next' ? <ChevronRight /> : <ChevronLeft />;

    return (
        <Box>
            <IconButton onClick={handleClick}>{icon}</IconButton>
        </Box>
    );
};
