import styled from 'styled-components';
import { Input } from '@material-ui/core';
import { ColorNames } from '../../utils/styles';

export const Label = styled.p`
    font-weight: 300;
    margin-bottom: 0;
    min-height: 27px;
    font-size: 0.8rem;
`;

export const StyledInput = styled(Input)`
    && {
        border-radius: 4px;
        border: 1px solid ${ColorNames.LIGHT_BLUE};
        padding: 0.4rem 1rem 0.2rem 0.6rem;
        background: #F9F9F9;
    }
`;
