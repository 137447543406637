import {
    Box,
    Chip,
    IconButton,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow as MuiTableRow,
    Typography,
} from '@material-ui/core';
import { Cancel } from '@mui/icons-material';
import { Fragment, useState } from 'react';
import styled from 'styled-components';

export type ItemType = 'invitation' | 'member';

export type Role = 'member' | 'admin';
export interface Item {
    id: string;
    name: string;
    type: ItemType;
    role: Role;
    email: string;
    isCurrentUser: boolean;
}

const Email = styled('span')`
    margin: 0 0.3em;
    text-decoration: underline;
`;

const CELL_HEIGHT = 65;

const InvitationChip = () => {
    return (
        <Chip
            style={{
                marginLeft: '1rem',
            }}
            label="Invited"
        />
    );
};

const CurrentUserChip = () => {
    return (
        <Chip
            style={{
                marginLeft: '1rem',
            }}
            label="You"
        />
    );
};

const RoleSelector = ({
    item,
    onChange,
    disabled = false,
}: {
    item: Item;
    onChange?: (item: Item, newValue: Role) => void;
    disabled?: boolean;
}) => (
    <Select
        key={`${item.id}-role-selector`}
        labelId="role-label"
        id="role-standard"
        value={item.role}
        style={{ minWidth: 150 }}
        disabled={disabled}
        variant="standard"
        onChange={event => {
            onChange?.(item, event.target.value as Role);
        }}
        label="Role"
    >
        <MenuItem key={`${item.id}-admin`} value="admin">
            Admin
        </MenuItem>
        <MenuItem key={`${item.id}-member`} value="member">
            Member
        </MenuItem>
    </Select>
);

const SkeletonTableRow = () => {
    return (
        <MuiTableRow key={Math.floor(Math.random() * 100)}>
            <TableCell width="400px">
                <Typography variant="body1">
                    <Skeleton width="50%" />
                </Typography>
            </TableCell>
            <TableCell width="400px">
                <Typography variant="body1">
                    <Skeleton width="50%" />
                </Typography>
            </TableCell>
            <TableCell width="200px">
                <Skeleton width="150px" height="2rem" />
            </TableCell>
            <TableCell align="right">
                <IconButton size="small">
                    <Skeleton variant="circular" width="20px" height="20px" />
                </IconButton>
            </TableCell>
        </MuiTableRow>
    );
};

const MemberTableRow = ({
    item,
    onRemove,
    roleOnChange,
}: {
    item: Item;
    onRemove: (item: Item) => void;
    roleOnChange: (item: Item, newRole: Role) => void;
}) => {
    return (
        <MuiTableRow key={item.id}>
            <TableCell width="400px">
                <Stack direction="row">
                    <Typography variant="body1" sx={{ lineHeight: 2 }}>
                        {item.name}
                    </Typography>
                    {item.isCurrentUser && <CurrentUserChip />}
                </Stack>
            </TableCell>
            <TableCell width="400px">
                <Typography variant="body1">
                    <Email>{item.email}</Email>
                </Typography>
            </TableCell>
            <TableCell width="200px">
                <RoleSelector
                    item={item}
                    onChange={(item, newRole) => roleOnChange(item, newRole)}
                    disabled={item.isCurrentUser}
                />
            </TableCell>
            <TableCell align="right">
                {!item.isCurrentUser && (
                    <IconButton onClick={() => onRemove(item)} size="small">
                        <Cancel color="info" fontSize="small" />
                    </IconButton>
                )}
            </TableCell>
        </MuiTableRow>
    );
};

const InvitationTableRow = ({
    item,
    onRemove,
}: {
    item: Item;
    onRemove: (item: Item) => void;
}) => {
    return (
        <MuiTableRow key={item.id}>
            <TableCell width="400px">
                <Stack direction="row">
                    <Typography variant="body1" sx={{ lineHeight: 2 }}>
                        {item.name}
                    </Typography>
                    {item.type === 'invitation' && <InvitationChip />}
                </Stack>
            </TableCell>
            <TableCell width="400px">
                <Typography variant="body1" sx={{ lineHeight: 2 }}>
                    <Email>{item.email}</Email>
                </Typography>
            </TableCell>
            <TableCell width="200px">
                <RoleSelector item={item} disabled />
            </TableCell>
            <TableCell align="right">
                <IconButton onClick={() => onRemove(item)} size="small">
                    <Cancel color="info" fontSize="small" />
                </IconButton>
            </TableCell>
        </MuiTableRow>
    );
};

const TableRow = ({
    item,
    onRemove,
    onChangeRole,
}: {
    item: Item;
    onRemove: (item: Item) => void;
    onChangeRole: (item: Item, newRole: Role) => void;
}) => {
    if (item.type === 'member') {
        return (
            <MemberTableRow
                key={item.id}
                item={item}
                onRemove={() => onRemove(item)}
                roleOnChange={(item, newRole) => onChangeRole(item, newRole)}
            />
        );
    } else if (item.type === 'invitation') {
        return (
            <InvitationTableRow
                key={item.id}
                item={item}
                onRemove={() => onRemove(item)}
            />
        );
    } else {
        return null;
    }
};

export interface MemberListProps {
    items: Item[];
    loading: boolean;
    onRemove: (item: Item) => void;
    onChangeMemberRole: (item: Item, newRole: Role) => void;
}
export const MemberList = ({
    items,
    loading,
    onRemove,
    onChangeMemberRole,
}: MemberListProps) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = Math.max(0, (1 + page) * rowsPerPage - items.length);

    const emptyRowsBody = (
        <MuiTableRow style={{ height: CELL_HEIGHT * emptyRows }}>
            <TableCell colSpan={4} />
        </MuiTableRow>
    );

    const emptyBody = (
        <>
            <MuiTableRow style={{ height: CELL_HEIGHT * rowsPerPage - 1 }}>
                <TableCell colSpan={4}>No members found</TableCell>
            </MuiTableRow>
        </>
    );

    const Body = ({
        items,
        currentPage,
        rowsPerPage,
    }: {
        items: Item[];
        currentPage: number;
        rowsPerPage: number;
    }) => {
        const rows =
            rowsPerPage > 0
                ? items.slice(
                      currentPage * rowsPerPage,
                      currentPage * rowsPerPage + rowsPerPage
                  )
                : items;

        return (
            <>
                {rows.map(item => (
                    <TableRow
                        key={item.id}
                        item={item}
                        onRemove={item => onRemove(item)}
                        onChangeRole={(item, newRole) =>
                            onChangeMemberRole(item, newRole)
                        }
                    />
                ))}

                {emptyRows > 0 && emptyRowsBody}
            </>
        );
    };

    return (
        <Fragment>
            <TableContainer>
                <Table>
                    <TableHead>
                        <MuiTableRow>
                            <TableCell width="400px" variant="head">
                                Member
                            </TableCell>
                            <TableCell width="400px" variant="head">
                                Email
                            </TableCell>
                            <TableCell width="200px" variant="head">
                                Role
                            </TableCell>
                            <TableCell align="right" variant="head">
                                Remove
                            </TableCell>
                        </MuiTableRow>
                    </TableHead>
                    <TableBody>
                        {loading && (
                            <>
                                <SkeletonTableRow />
                                <SkeletonTableRow />
                                <SkeletonTableRow />
                                <SkeletonTableRow />
                                <SkeletonTableRow />
                            </>
                        )}

                        {!loading && items.length === 0 && emptyBody}
                        {!loading && items.length > 0 && (
                            <Body
                                items={items}
                                currentPage={page}
                                rowsPerPage={rowsPerPage}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {!loading ? (
                <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            ) : (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    minHeight="52px"
                >
                    <Skeleton variant="rectangular" width={300} height={30} />
                </Box>
            )}
        </Fragment>
    );
};
