import { Box, Typography } from '@material-ui/core';
import {
    ArrowBack,
    CheckCircleOutline,
    ErrorOutlineRounded,
} from '@material-ui/icons';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../components/Form/Button';
import { SeinoLogo } from '../../../components/Graphics/SeinoLogo';
import { SplashScreen } from '../../../components/SplashScreen/SplashScreen';
import { useSeinoAuth } from '../../../hooks/use-seino-auth';
import { useSeinoStore } from '../../../store/seino-store';

export interface ConfigureProps {}

export const ConfigureWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const FormWrapper = styled.form`
    padding: 5rem 12rem;
    max-width: 420px;
`;

export const Configure = () => {
    const {
        dataSource: {
            getDataSourceConnections,
            hasEmailProviderConnection,
            hasConfiguredAggregationRules,
            hasAnalyticsProviderConnection,
            loading,
        },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    const { fetchToken } = useSeinoAuth();

    const navigate = useNavigate();

    useEffect(() => {
        const doIt = async () => {
            if (!currentWorkspaceId) return;
            getDataSourceConnections(await fetchToken(), currentWorkspaceId);
        };
        doIt();
    }, [getDataSourceConnections, fetchToken, currentWorkspaceId]);

    const FinishLater = useCallback(() => {
        if (
            !hasAnalyticsProviderConnection ||
            !hasEmailProviderConnection ||
            !hasConfiguredAggregationRules
        )
            return (
                <Box my={8}>
                    <Button
                        startIcon={<ArrowBack />}
                        variant="text"
                        color="primary"
                        size="small"
                        fullWidth={false}
                        onClick={() => navigate('/')}
                        title="Finish later"
                    />
                </Box>
            );

        return <></>;
    }, [
        navigate,
        hasConfiguredAggregationRules,
        hasEmailProviderConnection,
        hasAnalyticsProviderConnection,
    ]);

    if (loading.getSources) return <SplashScreen />;

    return (
        <ConfigureWrapper>
            <FormWrapper>
                <SeinoLogo />
                <Typography my={4} variant="h4">
                    What would you like to explore first?
                </Typography>
                <Typography my={4} variant="subtitle1">
                    Exploring our demo data is a great first step. But you can
                    also play around with your own data.
                </Typography>
                <Box my={4}>
                    <Box my={2}>
                        <Button
                            fullWidth={true}
                            onClick={() => {
                                navigate(
                                    '/config/data-sources/email-service-provider'
                                );
                            }}
                            color={
                                hasEmailProviderConnection
                                    ? 'success'
                                    : 'primary'
                            }
                            variant={
                                hasEmailProviderConnection
                                    ? 'outlined'
                                    : 'contained'
                            }
                            endIcon={
                                hasEmailProviderConnection ? (
                                    <CheckCircleOutline />
                                ) : (
                                    <ErrorOutlineRounded />
                                )
                            }
                            title={'1. Setup your ESP'}
                        />
                    </Box>
                    <Box my={2}>
                        <Button
                            fullWidth={true}
                            onClick={() => {
                                navigate('/config/data-sources/analytics');
                            }}
                            color={
                                hasAnalyticsProviderConnection
                                    ? 'success'
                                    : 'primary'
                            }
                            variant={
                                hasAnalyticsProviderConnection
                                    ? 'outlined'
                                    : 'contained'
                            }
                            endIcon={
                                hasAnalyticsProviderConnection ? (
                                    <CheckCircleOutline />
                                ) : (
                                    <ErrorOutlineRounded />
                                )
                            }
                            disabled={!hasEmailProviderConnection}
                            title="2. Setup your analytics"
                        />
                    </Box>
                    <Box my={2}>
                        <Button
                            fullWidth={true}
                            color={
                                hasConfiguredAggregationRules
                                    ? 'success'
                                    : 'primary'
                            }
                            variant={
                                hasConfiguredAggregationRules
                                    ? 'outlined'
                                    : 'contained'
                            }
                            endIcon={
                                hasConfiguredAggregationRules ? (
                                    <CheckCircleOutline />
                                ) : (
                                    <ErrorOutlineRounded />
                                )
                            }
                            onClick={() => {
                                navigate('/config/data-sources/rules');
                            }}
                            disabled={!hasAnalyticsProviderConnection}
                            title="3. Map tracking to markets"
                        />
                    </Box>
                    {hasEmailProviderConnection &&
                        hasAnalyticsProviderConnection &&
                        hasConfiguredAggregationRules && (
                            <Box my={8}>
                                <Button
                                    variant="contained"
                                    onClick={() => navigate('/')}
                                    disabled={!hasAnalyticsProviderConnection}
                                    title="Go to dashboard"
                                />
                            </Box>
                        )}

                    <FinishLater />
                </Box>
            </FormWrapper>
        </ConfigureWrapper>
    );
};
