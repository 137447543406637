import { ButtonProps, Button as MuiButton } from '@material-ui/core';

export const Button = ({ color, variant, ...props }: ButtonProps) => {
    return (
        <MuiButton
            variant={variant ?? 'contained'}
            color={color ?? 'primary'}
            {...props}
            disableElevation={true}
        >
            {props.title}
        </MuiButton>
    );
};
