import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { useState } from 'react';
import { Permission } from '../../store/workspace-management';
import { PermissionList } from '../PermissionList/PermissionList';

export interface PermissionManagementProps {
    permissions: Permission[];
    loading: boolean;
    withdrawPermission: (permission: Permission) => void;
}

export const PermissionManagement = ({
    loading,
    permissions,
    withdrawPermission,
}: PermissionManagementProps) => {
    const [selectedSubject, setSelectedSubject] = useState<
        Permission | undefined
    >(undefined);

    const handleClose = () => setSelectedSubject(undefined);

    const handleConfirm = () => {
        if (selectedSubject !== undefined) {
            withdrawPermission(selectedSubject);
        }
        setSelectedSubject(undefined);
        handleClose();
    };

    return (
        <>
            <Dialog open={selectedSubject !== undefined} onClose={handleClose}>
                <Box style={{ minWidth: '600px' }}>
                    <DialogTitle>Remove permission</DialogTitle>
                    <DialogContent>
                        Are you sure you want to withdraw the permission of{' '}
                        {selectedSubject?.name}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>Cancel</Button>
                        <Button
                            onClick={handleConfirm}
                            variant="contained"
                            color="info"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <PermissionList
                loading={loading}
                onRemove={item => setSelectedSubject(item)}
                permissions={permissions}
            />
        </>
    );
};
