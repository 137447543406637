import axios from 'axios';
import { getAPIUrl } from '../../utils/api';

export interface ConnectGAOptions {
    authType: 'refresh_token' | 'service_account';
    serviceAccount?: string;
}

export interface FetchWorkspaceProps {
    workspaceId: string;
}

export interface MemberPermissionProps extends FetchWorkspaceProps {
    memberId: string;
}

export interface MemberInvitationProps extends FetchWorkspaceProps {
    token: string;
}

export const fetchWorkspace = ({ workspaceId }: FetchWorkspaceProps) => {
    return axios.get(`${getAPIUrl()}/workspaces/${workspaceId}`);
};

export const fetchPermissions = ({ workspaceId }: FetchWorkspaceProps) => {
    return axios.get(`${getAPIUrl()}/workspaces/${workspaceId}/permissions`);
};

export const fetchAvailableMembers = ({ workspaceId }: FetchWorkspaceProps) => {
    return axios.get(
        `${getAPIUrl()}/workspaces/${workspaceId}/available-permissions`
    );
};

export const fetchAggregationRules = ({ workspaceId }: FetchWorkspaceProps) => {
    return axios.get(
        `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/aggregation-rules`
    );
};

export interface Rule {
    field?: string;
    condition?: string;
    value?: string;
    market?: string;
    view_id?: string;
    currency?: string;
}

export type MarketType = 'multi_market' | 'single_market';

interface SingleMarketRules {
    type: MarketType;
    rule: {
        market: string;
        view_id: string;
    };
}

interface MultiMarketRules {
    type: MarketType;
    rules: Rule[];
}

type RulesPayload = SingleMarketRules | MultiMarketRules;

export interface PutAggregationRulesProps extends FetchWorkspaceProps {
    payload: RulesPayload;
}

export const putAggregationRules = ({
    workspaceId,
    payload,
}: PutAggregationRulesProps) => {
    return axios.put(
        `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/aggregation-rules`,
        payload
    );
};

export const fetchGaViews = ({ workspaceId }: FetchWorkspaceProps) => {
    return axios.get(
        `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/google-analytics/views`
    );
};

export const fetchGoalMetrics = ({ workspaceId }: FetchWorkspaceProps) => {
    return axios.get(`${getAPIUrl()}/workspaces/${workspaceId}/goal-metrics`);
};

export const grantPermission = ({
    workspaceId,
    memberId,
}: MemberPermissionProps) => {
    return axios.post(`${getAPIUrl()}/workspaces/${workspaceId}/permissions`, {
        id: memberId,
    });
};

export const withdrawPermission = ({
    workspaceId,
    memberId,
}: MemberPermissionProps) => {
    return axios.delete(
        `${getAPIUrl()}/workspaces/${workspaceId}/permissions/${memberId}`
    );
};

export const withdrawInvitation = ({
    workspaceId,
    token,
}: MemberInvitationProps) => {
    return axios.delete(
        `${getAPIUrl()}/workspaces/${workspaceId}/invitations/${token}`
    );
};

export const fetchDataSources = ({ workspaceId }: FetchWorkspaceProps) => {
    return axios.get(`${getAPIUrl()}/workspaces/${workspaceId}/data-sources`);
};

export const removeGAConnection = ({ workspaceId }: FetchWorkspaceProps) => {
    return axios.delete(
        `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/google-analytics`
    );
};

export interface ConfigureGoogleProps extends FetchWorkspaceProps {
    options: ConnectGAOptions;
}

export const configureGoogle = async ({
    workspaceId,
    options,
}: ConfigureGoogleProps) => {
    const { authType } = options;

    const body = {
        redirectUri: window.location.href,
        authenticationType: authType,
    };

    const result: any = await axios.post(
        `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/google-analytics/connect`,
        body
    );

    window.location.href = result.data.url;
};

export interface CanopyDeployCredentials {
    url?: string;
    username?: string;
    password?: string;
}

export const fetchCanopyDeployCredentials = ({
    workspaceId,
}: FetchWorkspaceProps) => {
    return axios.post(
        `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/canopy-deploy/connect`
    );
};

export interface ConfigureCopernicaProps extends FetchWorkspaceProps {
    token: string;
}

export interface ConfigureESPResult {
    ok: boolean;
}

export const configureCopernica = ({
    workspaceId,
    token,
}: ConfigureCopernicaProps) => {
    return axios.post(
        `${getAPIUrl()}/workspaces/${workspaceId}/data-sources/copernica/connect`,
        { token }
    );
};

export interface ConfigureActiveCampaignProps extends FetchWorkspaceProps {
    url: string;
    key: string;
}

export const configureActiveCampaign = ({
    workspaceId,
    url,
    key,
}: ConfigureActiveCampaignProps) => {
    return axios.post(
        `
        ${getAPIUrl()}/workspaces/${workspaceId}/data-sources/active-campaign/connect`,
        { url, key }
    );
};
