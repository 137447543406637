import {
    Box,
    CircularProgress,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { RuleBuilder } from '../../../components/Form/RuleBuilder/RuleBuilder';
import {
    createGroupedGAViewOptions,
    getDefaultOption,
} from '../../../components/Form/RuleBuilder/utils';
import { Select } from '../../../components/Form/Select';
import { LoadingStatus } from '../../../constants';
import { MarketType, Rule } from '../../../store/data-source';
import { useSeinoStore } from '../../../store/seino-store';
import { generateResellerCenterWorkspaceUrl } from '../../../utils/url-generator';
import { StatusOverview } from './StatusOverview';

export interface MarketMappingProps {
    workspaceId: string;
}

export const Mapping = ({ workspaceId }: MarketMappingProps) => {
    const {
        workspaceManagement: {
            gaViews,
            aggregationRules,
            configureAggregationRules,
            workspace,
        },
    } = useSeinoStore();

    const [selectedSingleMarket, setSelectedSingleMarket] = useState<{
        value: string;
        label: string;
    }>({ value: '', label: '' });

    const [marketType, setMarketType] = useState(MarketType.SINGLE_MARKET);
    const [multiRules, setMultiRules] = useState<Rule[]>([]);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const commitConfig = useCallback(
        (rules: Rule[]) => {
            const configure = async () => {
                const payload =
                    marketType === MarketType.SINGLE_MARKET
                        ? {
                              type: MarketType.SINGLE_MARKET,
                              rule: {
                                  market: 'nl',
                                  view_id: selectedSingleMarket.value,
                              },
                          }
                        : {
                              type: MarketType.MULTI_MARKET,
                              rules,
                          };

                await configureAggregationRules.fetch({
                    workspaceId,
                    payload,
                });
                workspace.fetch({ workspaceId });
            };

            configure();
            setUnsavedChanges(false);
        },
        [
            marketType,
            workspaceId,
            selectedSingleMarket,
            configureAggregationRules,
            workspace,
        ]
    );

    const updateConfig = useCallback((ruleData: Rule[]) => {
        setMultiRules(ruleData);
        setUnsavedChanges(true);
    }, []);

    useEffect(() => {
        if (aggregationRules.result?.type) {
            setMarketType(aggregationRules.result.type);
            setMultiRules(aggregationRules.result?.rules || []);
        }
    }, [aggregationRules.status, JSON.stringify(aggregationRules.result)]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        gaViews.fetch({ workspaceId });
        aggregationRules.fetch({ workspaceId });
    }, [workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

    const visibilityForm = useCallback(
        (type: MarketType) => (type === marketType ? 'block' : 'none'),
        [marketType]
    );

    let gaViewsOptions: { value: string; label: string }[] = [];
    if (gaViews.result?.views) {
        gaViewsOptions = createGroupedGAViewOptions(gaViews.result.views);
    }

    const handleChangeMarketType = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const selectedMarketType = event.target.value as MarketType;
        setMarketType(selectedMarketType);
        setUnsavedChanges(true);
    };

    if (aggregationRules.status === LoadingStatus.FAILED) {
        return (
            <StatusOverview
                linkText={{
                    success: 'Show',
                    failure: 'Update your analytics configuration',
                }}
                listOfStatus={[
                    {
                        isSuccess: false,
                        primaryText:
                            'We were not able to retrieve any Google Analytics views',
                        url: generateResellerCenterWorkspaceUrl(
                            workspaceId,
                            `/data-connections?connectionType=analytics`
                        ),
                    },
                ]}
            />
        );
    }

    // TODO create a HOC for components which depend on api data, which renders a loader when dependencies
    // are not yet available
    if (
        aggregationRules.status === LoadingStatus.LOADING ||
        gaViews.status === LoadingStatus.LOADING
    ) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="300px"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Fragment>
            <Box my={4}>
                <RadioGroup
                    row
                    aria-label="markets"
                    name="markets"
                    value={marketType}
                    onChange={handleChangeMarketType}
                >
                    <FormControlLabel
                        data-testid="single-market-radio-button"
                        value={MarketType.SINGLE_MARKET}
                        control={<Radio />}
                        label="I operate in a single market"
                    />
                    <FormControlLabel
                        data-testid="multi-market-radio-button"
                        value={MarketType.MULTI_MARKET}
                        control={<Radio />}
                        label="I operate in multiple markets"
                    />
                </RadioGroup>
            </Box>
            <Box
                my={2}
                maxWidth="350px"
                display={visibilityForm(MarketType.SINGLE_MARKET)}
            >
                <Select
                    testId="select-ga-view"
                    size="small"
                    defaultValue={getDefaultOption(
                        gaViewsOptions,
                        aggregationRules.result?.rule?.view_id
                    )}
                    onChange={(evt: any) => {
                        const [group, value] = evt.value.split('---');

                        console.log(`${value} selected from ${group}`);

                        setSelectedSingleMarket(value || evt);
                        setUnsavedChanges(true);
                    }}
                    options={gaViewsOptions}
                />
            </Box>
            <Box
                display={visibilityForm(MarketType.MULTI_MARKET)}
                padding="0"
                margin="0"
            >
                {gaViews.result && (
                    <RuleBuilder
                        customerGAViews={gaViews.result?.views || []}
                        onRulesUpdated={updateConfig}
                        initialRules={multiRules}
                    />
                )}
            </Box>
            <Box width="100%" textAlign="right" my={2}>
                <LoadingButton
                    variant="contained"
                    size="large"
                    data-testid="save-button"
                    disabled={!unsavedChanges}
                    loading={
                        configureAggregationRules.status ===
                        LoadingStatus.LOADING
                    }
                    onClick={() => commitConfig(multiRules)}
                >
                    Save
                </LoadingButton>
            </Box>
        </Fragment>
    );
};
