import { GetState, SetState, StateCreator } from 'zustand';
import { LoadingStatus } from '../../constants';
import { getAPIUrl } from '../../utils/api';
import { ConnectGAOptions, MarketType, Rule } from '../data-source';
import { LOADING_IDLE_TIMEOUT } from '../seino-store';

export type SubscriptionInterval = 'monthly' | 'yearly';
export type WorkspaceType = 'demo' | 'paid';

export interface PricingInfo {
    interval: SubscriptionInterval;
    analytics: {
        price: number;
    };
    canopyDeploy: {
        price: number;
    };
    copernica: {
        price: number;
    };
    dataExplorer: {
        price: number;
    };
}

export interface Pricing {
    monthly: PricingInfo;
    yearly: PricingInfo;
}

export interface Member {
    id: string;
    name: string;
    email: string;
}

export interface InvitedMember {
    id: string;
    name: string;
    email: string;
    expiresAt: string;
}

export interface AssociatedAccount {
    id: string;
    name: string;
}

export interface Organization {
    name: string;
    id: string;
    members: Member[];
    invitedMembers: InvitedMember[];
}
export enum DataSourceStatus {
    CONFIGURED,
    NOT_CONFIGURED,
}

export interface DataSource {
    source: string;
    status: DataSourceStatus;
}

export type SubscriptionStatus =
    | 'active'
    | 'past_due'
    | 'unpaid'
    | 'canceled'
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'ended';

export interface PricingItem {
    quantity: number;
    price: number;
    currency: string;
    interval: SubscriptionInterval;
    name: string;
}

export interface InvoiceInfo {
    total: number;
    currency: string;
    periodStart: number;
    periodEnd: number;
    dueDate: number;
}

export type Subscription = {
    status: SubscriptionStatus;
    currentPeriodStart: number;
    currentPeriodEnd: number;
    trialEnd: number | null;
    upcomingInvoice: InvoiceInfo;
    items: PricingItem[];
};

export type Workspace = Organization & {
    subscription: Subscription;
    type: WorkspaceType;
    associatedAccount: AssociatedAccount;
    hasEmailProviderConnection: boolean;
    hasAnalyticsProviderConnection: boolean;
    hasConfiguredAggregationConfig: boolean;
};

export type Account = Organization & { workspaces: Workspace[] };

// TODO: Combine with config from data-source store
export type AggregationConfig = {
    type?: MarketType;
    rules?: Rule[];
    rule?: {
        market: string;
        view_id: string;
    };
};

// TODO: Combine with config from data-source store
export interface AnalyticsView {
    viewId: string;
    propertyId: string;
    propertyName: string;
    viewName: string;
}

export interface APIErrors {
    unauthorized: boolean;
}

export interface AddWorkspaceOptions {
    name: string;
    analyticsProperties: number;
    esp: 'canopy_deploy' | 'copernica';
    subscriptionInterval: SubscriptionInterval;
    trial: boolean;
    type: WorkspaceType;
}

export interface ResellerCenterStore {
    errors: APIErrors;

    getAccounts: (token: string) => void;
    accounts: Account[];
    getAccountsStatus: LoadingStatus;
    addAccount: (token: string, name: string) => void;
    addAccountStatus: LoadingStatus;

    resetResellerState: () => void;

    inviteMemberToWorkspace: (
        token: string,
        accountId: string,
        workspaceId: string,
        email: string
    ) => void;
    inviteMemberToWorkspaceStatus: LoadingStatus;

    deleteMember: (
        token: string,
        accountId: string,
        workspaceId: string,
        memberId: string
    ) => void;

    pricing: Pricing | undefined;
    deleteMemberStatus: LoadingStatus;
    getPricing: (token: string) => void;
    getPricingStatus: LoadingStatus;
    deleteInvitation: (
        token: string,
        accountId: string,
        workspaceId: string,
        memberId: string
    ) => void;
    deleteInvitationStatus: LoadingStatus;

    addWorkspace: (
        token: string,
        accountId: string,
        options: AddWorkspaceOptions
    ) => void;
    addWorkspaceStatus: LoadingStatus;

    selectAndLoadWorkspace: (
        token: string,
        accountId: string,
        workspaceId: string
    ) => void;
    clearSelectedWorkspace: () => void;
    selectedWorkspace: Workspace | undefined;
    workspaceLoadingStatus: LoadingStatus;

    updateWorkspaceName: (
        token: string,
        workspaceId: string,
        newName: string
    ) => void;
    updateWorkspaceNameStatus: LoadingStatus;

    getAggregationConfig: (
        token: string,
        accountId: string,
        workspaceId: string
    ) => void;
    aggregationConfig: AggregationConfig;
    aggregationConfigLoadingStatus: LoadingStatus;

    getAnalyticViews: (
        token: string,
        accountId: string,
        workspaceId: string
    ) => void;
    analyticViews: AnalyticsView[];
    analyticViewsLoadingStatus: LoadingStatus;

    configureCanopyDeploy: (
        token: string,
        accountId: string,
        workspaceId: string
    ) => void;
    configureCanopyDeployStatus: LoadingStatus;

    configureCopernica: (
        token: string,
        accessToken: string,
        accountId: string,
        workspaceId: string
    ) => void;
    configureCopernicaStatus: LoadingStatus;

    configureGoogle: (
        token: string,
        accountId: string,
        workspaceId: string,
        options: ConnectGAOptions
    ) => void;
    configureGoogleStatus: LoadingStatus;
    removeGoogleAnalyticsConnection: (
        token: string,
        accountId: string,
        workspaceId: string
    ) => void;

    configurationDetails: any; // TODO type this properly when all 5 steps are implemented
}

const initialState = {
    accounts: [],
    configurationDetails: {},
    getAccountsStatus: LoadingStatus.IDLE,
    inviteMemberToWorkspaceStatus: LoadingStatus.IDLE,
    deleteMemberStatus: LoadingStatus.IDLE,
    deleteInvitationStatus: LoadingStatus.IDLE,
    selectedWorkspace: undefined,
    pricing: undefined,
    workspaceLoadingStatus: LoadingStatus.IDLE,
    addAccountStatus: LoadingStatus.IDLE,
    addWorkspaceStatus: LoadingStatus.IDLE,
    getPricingStatus: LoadingStatus.IDLE,
    aggregationConfig: {},
    aggregationConfigLoadingStatus: LoadingStatus.IDLE,
    analyticViews: [],
    analyticViewsLoadingStatus: LoadingStatus.IDLE,
    updateWorkspaceNameStatus: LoadingStatus.IDLE,
    configureCanopyDeployStatus: LoadingStatus.IDLE,
    configureGoogleStatus: LoadingStatus.IDLE,
    configureCopernicaStatus: LoadingStatus.IDLE,
};

export const resellerCenterStore: StateCreator<ResellerCenterStore> = (
    set: SetState<ResellerCenterStore>,
    get: GetState<ResellerCenterStore>
) => ({
    errors: {
        unauthorized: false,
    },

    ...initialState,

    resetResellerState: () => set({ ...initialState }),

    getAccounts: async (token: string) => {
        if (!token) return;

        set({
            getAccountsStatus: LoadingStatus.LOADING,
            errors: {
                unauthorized: false,
            },
        });

        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts`,
            {
                method: 'GET',
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (response.ok) {
            const { accounts } = await response.json();
            set({
                getAccountsStatus: LoadingStatus.SUCCESS,
                accounts: accounts,
            });
        } else {
            set({
                getAccountsStatus: LoadingStatus.FAILED,
                errors: {
                    ...get().errors,
                    unauthorized: true,
                },
            });
        }

        set({ getAccountsStatus: LoadingStatus.IDLE });
    },

    addAccount: async (token: string, name: string) => {
        if (!token) return;

        set({
            getAccountsStatus: LoadingStatus.LOADING,
            errors: {
                unauthorized: false,
            },
        });

        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts`,
            {
                method: 'POST',
                headers: { Authorization: `Bearer ${token}` },
                body: JSON.stringify({ name }),
            }
        );

        if (response.ok) {
            get().getAccounts(token);
        } else {
            set({
                addAccountStatus: LoadingStatus.FAILED,
            });
        }

        set({ addAccountStatus: LoadingStatus.IDLE });
    },
    getPricing: async (token: string) => {
        if (!token) return;

        set({
            getPricingStatus: LoadingStatus.LOADING,
            errors: {
                unauthorized: false,
            },
        });

        const response = await fetch(
            `${getAPIUrl()}/reseller-center/pricing`,
            {
                method: 'GET',
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (response.ok) {
            set({ pricing: await response.json() });
        } else {
            set({
                getPricingStatus: LoadingStatus.FAILED,
            });
        }

        set({ getPricingStatus: LoadingStatus.IDLE });
    },
    addWorkspace: async (
        token: string,
        accountId: string,
        options: AddWorkspaceOptions
    ) => {
        if (!token) return;

        set({
            addWorkspaceStatus: LoadingStatus.LOADING,
        });

        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts/${accountId}/workspaces`,
            {
                method: 'POST',
                headers: { Authorization: `Bearer ${token}` },
                body: JSON.stringify(options),
            }
        );

        if (!response.ok) {
            set({
                addWorkspaceStatus: LoadingStatus.FAILED,
            });
        }

        if (response.ok) {
            set({
                addWorkspaceStatus: LoadingStatus.SUCCESS,
            });
            const { workspaceId } = await response.json();
            get().selectAndLoadWorkspace(token, accountId, workspaceId);
        }

        setTimeout(
            () => set({ inviteMemberToWorkspaceStatus: LoadingStatus.IDLE }),
            LOADING_IDLE_TIMEOUT
        );
    },
    inviteMemberToWorkspace: async (
        token: string,
        accountId: string,
        workspaceId: string,
        email: string
    ) => {
        if (!token) return;

        set({
            inviteMemberToWorkspaceStatus: LoadingStatus.LOADING,
        });

        let payload: { email: string } = { email };

        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts/${accountId}/workspaces/${workspaceId}/members`,
            {
                method: 'POST',
                headers: { Authorization: `Bearer ${token}` },
                body: JSON.stringify(payload),
            }
        );

        if (!response.ok) {
            set({
                errors: { ...get().errors },
                inviteMemberToWorkspaceStatus: LoadingStatus.FAILED,
            });
        }

        if (response.ok) {
            set({ inviteMemberToWorkspaceStatus: LoadingStatus.SUCCESS });

            const selectedWorkspace = get().selectedWorkspace;
            if (selectedWorkspace) {
                get().selectAndLoadWorkspace(
                    token,
                    selectedWorkspace?.associatedAccount?.id,
                    selectedWorkspace?.id
                );
            }
        }

        setTimeout(
            () => set({ inviteMemberToWorkspaceStatus: LoadingStatus.IDLE }),
            LOADING_IDLE_TIMEOUT
        );
    },
    deleteMember: async (
        token: string,
        accountId: string,
        workspaceId: string,
        memberId: string
    ) => {
        if (!token) return;

        set({
            deleteMemberStatus: LoadingStatus.LOADING,
        });

        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts/${accountId}/workspaces/${workspaceId}/members/${memberId}`,
            {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (response.ok) {
            set({ deleteMemberStatus: LoadingStatus.SUCCESS });
            const selectedWorkspace = get().selectedWorkspace;
            if (selectedWorkspace) {
                get().selectAndLoadWorkspace(
                    token,
                    selectedWorkspace?.associatedAccount?.id,
                    selectedWorkspace?.id
                );
            }
        } else {
            set({
                errors: { ...get().errors },
                deleteMemberStatus: LoadingStatus.FAILED,
            });
        }

        setTimeout(
            () => set({ deleteMemberStatus: LoadingStatus.IDLE }),
            LOADING_IDLE_TIMEOUT
        );
    },
    deleteInvitation: async (
        token: string,
        accountId: string,
        workspaceId: string,
        memberId: string
    ) => {
        if (!token) return;

        set({
            deleteInvitationStatus: LoadingStatus.LOADING,
        });

        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts/${accountId}/workspaces/${workspaceId}/invitations/${memberId}`,
            {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (response.ok) {
            set({ deleteInvitationStatus: LoadingStatus.SUCCESS });
            const selectedWorkspace = get().selectedWorkspace;
            if (selectedWorkspace) {
                get().selectAndLoadWorkspace(
                    token,
                    selectedWorkspace?.associatedAccount?.id,
                    selectedWorkspace?.id
                );
            }
        } else {
            set({
                errors: { ...get().errors },
                deleteInvitationStatus: LoadingStatus.FAILED,
            });
        }

        setTimeout(
            () => set({ deleteInvitationStatus: LoadingStatus.IDLE }),
            LOADING_IDLE_TIMEOUT
        );
    },
    selectAndLoadWorkspace: async (
        token: string,
        accountId: string,
        workspaceId: string
    ) => {
        set({
            workspaceLoadingStatus: LoadingStatus.LOADING,
        });

        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts/${accountId}/workspaces/${workspaceId}`,
            {
                method: 'GET',
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        if (response.ok) {
            const { workspace } = await response.json();
            set({
                selectedWorkspace: workspace,
                workspaceLoadingStatus: LoadingStatus.SUCCESS,
            });
        } else {
            set({
                workspaceLoadingStatus: LoadingStatus.FAILED,
                errors: {
                    ...get().errors,
                    unauthorized: true,
                },
            });
        }

        setTimeout(
            () => set({ workspaceLoadingStatus: LoadingStatus.IDLE }),
            LOADING_IDLE_TIMEOUT
        );
    },
    clearSelectedWorkspace: () => {
        set({
            selectedWorkspace: undefined,
        });
    },
    getAggregationConfig: async (
        token: string,
        accountId: string,
        workspaceId: string
    ) => {
        if (!token) return;

        set({ aggregationConfigLoadingStatus: LoadingStatus.IDLE });

        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts/${accountId}/workspaces/${workspaceId}/aggregation-rules`,
            {
                method: 'GET',
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        const loadedConfig = await response.json();

        set({
            aggregationConfigLoadingStatus: LoadingStatus.SUCCESS,
            aggregationConfig: loadedConfig,
        });

        setTimeout(
            () => set({ aggregationConfigLoadingStatus: LoadingStatus.IDLE }),
            LOADING_IDLE_TIMEOUT
        );
    },
    getAnalyticViews: async (
        token: string,
        accountId: string,
        workspaceId: string
    ) => {
        if (!token) return;

        set({ analyticViewsLoadingStatus: LoadingStatus.LOADING });
        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts/${accountId}/workspaces/${workspaceId}/data-sources/google-analytics/views`,

            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        if (response.ok) {
            set({
                analyticViewsLoadingStatus: LoadingStatus.SUCCESS,
                analyticViews: data.views,
            });
        } else {
            set({ analyticViewsLoadingStatus: LoadingStatus.FAILED });
        }

        setTimeout(
            () => set({ analyticViewsLoadingStatus: LoadingStatus.IDLE }),
            LOADING_IDLE_TIMEOUT
        );
    },
    updateWorkspaceName: (
        token: string,
        workspaceId: string,
        newName: string
    ) => {
        if (!token) return;

        set({ updateWorkspaceNameStatus: LoadingStatus.LOADING });

        setTimeout(() => {
            set({ updateWorkspaceNameStatus: LoadingStatus.SUCCESS });

            setTimeout(
                () => set({ updateWorkspaceNameStatus: LoadingStatus.IDLE }),
                LOADING_IDLE_TIMEOUT
            );
        }, 2000);
    },
    configureCanopyDeploy: async (
        token: string,
        accountId: string,
        workspaceId: string
    ) => {
        if (!token) return;

        set({ configureCanopyDeployStatus: LoadingStatus.LOADING });
        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts/${accountId}/workspaces/${workspaceId}/data-sources/canopy-deploy/connect`,

            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        if (response.ok) {
            set({
                configureCanopyDeployStatus: LoadingStatus.SUCCESS,
                configurationDetails: {
                    ...get().configurationDetails,
                    canopyDeploy: data,
                },
            });
        } else {
            set({ configureCanopyDeployStatus: LoadingStatus.FAILED });
        }

        setTimeout(
            () => set({ configureCanopyDeployStatus: LoadingStatus.IDLE }),
            LOADING_IDLE_TIMEOUT
        );
    },
    configureCopernica: async (
        token: string,
        accessToken: string,
        accountId: string,
        workspaceId: string
    ) => {
        if (!token) return;

        set({ configureCopernicaStatus: LoadingStatus.LOADING });
        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts/${accountId}/workspaces/${workspaceId}/data-sources/copernica/connect`,

            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    token: accessToken,
                    workspaceId,
                    accountId,
                }),
            }
        );

        const data = await response.json();

        if (response.ok) {
            set({
                configureCopernicaStatus: LoadingStatus.SUCCESS,
                configurationDetails: {
                    ...get().configurationDetails,
                    copernica: data,
                },
            });
        } else {
            set({ configureCopernicaStatus: LoadingStatus.FAILED });
        }

        setTimeout(
            () => set({ configureCopernicaStatus: LoadingStatus.IDLE }),
            LOADING_IDLE_TIMEOUT
        );
    },
    configureGoogle: async (
        token: string,
        accountId: string,
        workspaceId: string,
        options: ConnectGAOptions
    ) => {
        if (!token) return;
        const { authType } = options;

        const body = {
            redirectUri: window.location.href,
            authenticationType: authType,
        };

        set({ configureGoogleStatus: LoadingStatus.LOADING });
        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts/${accountId}/workspaces/${workspaceId}/data-sources/google-analytics/connect`,

            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(body),
            }
        );

        const { url } = await response.json();

        if (response.ok) {
            set({
                selectedWorkspace: {
                    ...get().selectedWorkspace!,
                    hasAnalyticsProviderConnection: true,
                },
                configureGoogleStatus: LoadingStatus.SUCCESS,
            });
            window.location.href = url;
        } else {
            set({
                configureGoogleStatus: LoadingStatus.FAILED,
            });
        }

        setTimeout(
            () => set({ configureGoogleStatus: LoadingStatus.IDLE }),
            LOADING_IDLE_TIMEOUT
        );
    },
    removeGoogleAnalyticsConnection: async (
        token: string,
        accountId: string,
        workspaceId: string
    ) => {
        if (!token) return;

        set({ configureGoogleStatus: LoadingStatus.LOADING });
        const response = await fetch(
            `${getAPIUrl()}/reseller-center/accounts/${accountId}/workspaces/${workspaceId}/google-analytics`,

            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (response.ok) {
            set({
                selectedWorkspace: {
                    ...get().selectedWorkspace!,
                    hasAnalyticsProviderConnection: false,
                },
                configureGoogleStatus: LoadingStatus.SUCCESS,
            });
        } else {
            set({
                configureGoogleStatus: LoadingStatus.FAILED,
            });
        }

        setTimeout(
            () => set({ configureGoogleStatus: LoadingStatus.IDLE }),
            LOADING_IDLE_TIMEOUT
        );
    },
});
