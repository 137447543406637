import { useAuth0 } from '@auth0/auth0-react';
import { Help } from '@mui/icons-material';
import { Box, IconButton, Tab, Tabs } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSeinoStore } from '../../store/seino-store';
import { GraphicLogo } from '../Graphics/GraphicLogo';
import { WorkspaceSelector } from '../WorkspaceSelector/WorkspaceSelector';
import { SeinoToolbar } from './Header.styles';
import { User } from './User';

const LeftSide = styled(Box)`
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding-left: 8px;
`;

const StyledTabsList = styled(Tabs)`
    display: flex;
    overflow: auto;
`;

interface LinkTabProps {
    label: string;
    href: string;
}

export const Header = ({
    showNavigation = true,
    showWorkspaceSelector = true,
}: {
    showNavigation?: boolean;
    showWorkspaceSelector?: boolean;
}) => {
    const { user } = useAuth0();
    const {
        user: { isAccountAdmin, onboardingRequired },
    } = useSeinoStore();
    const [value, setValue] = React.useState(0);

    const { settings } = useFlags();

    const handleChange = useCallback(
        (_: React.SyntheticEvent, newValue: number) => {
            setValue(newValue);
        },
        []
    );

    const navigate = useNavigate();

    const LinkTab = (props: LinkTabProps) => {
        return (
            <Tab
                component="a"
                onClick={(
                    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                    event.preventDefault();

                    navigate(props.href);
                }}
                {...props}
            />
        );
    };

    const handleHelp = () => {
        window.open('https://support.seino.ai', '_blank');
    };

    const getTabs = useCallback(() => {
        return (
            <StyledTabsList
                onChange={handleChange}
                value={value}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
            >
                <LinkTab href="/explorer" label="Explorer" />
            </StyledTabsList>
        );
    }, [handleChange, value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <SeinoToolbar>
                <LeftSide>
                    <GraphicLogo onClick={() => navigate('/')} />
                    {showWorkspaceSelector && <WorkspaceSelector />}
                    {showNavigation && getTabs()}
                </LeftSide>
                <Box>
                    <IconButton onClick={handleHelp}>
                        <Help color="secondary" fontSize="small" />
                    </IconButton>
                    <User
                        username={user?.name || user?.nickname || ''}
                        showProfileLink={!onboardingRequired}
                        showAccountManagementLink={
                            (settings as boolean) && isAccountAdmin
                        }
                    />
                </Box>
            </SeinoToolbar>
        </>
    );
};
