import { Box, Typography } from '@material-ui/core';
import { Layer, ResponsiveLine } from '@nivo/line';
import React, { useEffect, useState } from 'react';
import { useSeinoStore } from '../../store/seino-store';
import { prepareData } from './gen-data';
import { ChartData } from './MetricChart';
import { DashedLine } from './MetricChart.styles';
import { Tooltip } from './Tooltip';
import { dateFormat, getTitleForMetric } from './utils';

export interface MetricChartDataPoint {
    value: number;
    percentageChange: number;
}

export interface MetricChartHighlightedProps {
    metricName: string;
    data: ChartData[];
}

export const MetricChartHighlighted = (props: MetricChartHighlightedProps) => {
    const { metricName, data } = props;

    const [preparedData, setPreparedData] = useState(prepareData(data));

    const {
        dashboard: { dateRange },
    } = useSeinoStore();

    useEffect(() => {
        setPreparedData(prepareData(data));
    }, [dateRange, data]); // eslint-disable-line react-hooks/exhaustive-deps

    const options: Layer[] = [
        'grid',
        'markers',
        'areas',
        'crosshair',
        'points',
        'slices',
        'mesh',
        'legends',
        'axes',
        DashedLine(),
    ];

    // TODO clear up the flow of this component when finalized
    if (!preparedData || !metricName) return null;

    const chartYScale =
        metricName.indexOf('rate') > -1
            ? 1
            : Math.max(
                  ...[
                      ...preparedData[0].data.map(({ y }) => y as number),
                      ...preparedData[1].data.map(({ y }) => y as number),
                  ]
              );

    return (
        <Box width="100%">
            <Box
                style={{
                    height: 400,
                    margin: '5px',
                }}
            >
                <Box display="flex">
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                        }}
                        variant="h6"
                        component={'h2'}
                    >
                        {`${getTitleForMetric(metricName)}`}
                    </Typography>
                    <Typography
                        sx={{ fontWeight: 'regular' }}
                        mx={1}
                        variant="h6"
                        component={'h2'}
                    >
                        vs
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                        }}
                        variant="h6"
                        component={'h2'}
                    >
                        {`Last Period`}
                    </Typography>
                </Box>
                <ResponsiveLine
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                    pointColor={{ theme: 'background' }}
                    useMesh={true}
                    data={preparedData}
                    tooltip={({ point }) => {
                        const selectionIndex =
                            point.index < data.length
                                ? point.index
                                : point.index - data.length;

                        const date = dateFormat(data[selectionIndex].date);
                        const compareDate = dateFormat(
                            data[selectionIndex].lastPeriodDate
                        );

                        const value = data[selectionIndex].value;
                        const comparisonValue =
                            data[selectionIndex].lastPeriodValue;

                        const delta = parseFloat(
                            (
                                (value - comparisonValue) /
                                comparisonValue
                            ).toFixed(2)
                        );

                        return (
                            <Tooltip
                                date={date}
                                compareDate={compareDate}
                                value={value.toFixed(2)}
                                delta={delta}
                            />
                        );
                    }}
                    margin={{ top: 50, right: 20, bottom: 80, left: 80 }}
                    layers={options}
                    enableGridX={false}
                    enablePoints={true}
                    enableGridY={true}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: chartYScale,
                    }}
                    curve="linear"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={null}
                    pointSize={10}
                    pointLabelYOffset={-12}
                />
            </Box>
        </Box>
    );
};
