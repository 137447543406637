import { ColumnState } from 'ag-grid-community';

export const DEFAULT_COL_STATE: ColumnState = {
    hide: true,
    pinned: null,
    sort: null,
    sortIndex: null,
    rowGroup: false,
    rowGroupIndex: null,
    pivot: false,
    width: undefined,
    pivotIndex: null,
};

export const revenuePivot = [
    {
        colId: 'ag-Grid-AutoColumn',
        width: 200,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_campaign_type',
        width: 132,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_campaign_id',
        width: 124,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_campaign_name',
        width: 170,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_name',
        width: 170,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_subject',
        width: 170,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_type',
        width: 121,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_audience',
        width: 98,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_market',
        width: 84,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: true,
        rowGroupIndex: 0,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_send_date',
        width: 191,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_send_date_week',
        width: 75,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: true,
        pivotIndex: 0,
    },
    {
        colId: 'broadcast_mailing_send_date_month',
        width: 81,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_send_date_year',
        width: 71,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_sent_all_sum',
        width: 23,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_sent_unique_sum',
        width: 23,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_open_unique_sum',
        width: 23,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_open_unique_rate',
        width: 23,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_unique_sum',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_unique_rate',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_all_sum',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_all_rate',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_to_open_all_rate',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_to_open_unique_rate',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_per_user_avg',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_softbounce_sum',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_softbounce_rate',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_hardbounce_sum',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_hardbounce_rate',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_complaints_sum',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_complaints_rate',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_unsubscribe_sum',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_unsubscribe_rate',
        width: 23,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_deliverability_rate',
        width: 144,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_session_sum',
        width: 68,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_session_time_sum',
        width: 68,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_bounce_sum',
        width: 68,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_session_time_avg',
        width: 68,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_transactions_sum',
        width: 68,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_conversion_rate',
        width: 68,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_revenue_sum',
        width: 682,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_revenue_per_session',
        width: 68,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_revenue_per_email_sent',
        width: 68,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_average_order_value',
        width: 68,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
];

export const automatedStandard = [
    {
        colId: 'ag-Grid-AutoColumn',
        width: 200,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_campaign_type',
        width: 132,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_campaign_id',
        width: 124,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_campaign_name',
        width: 170,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_name',
        width: 170,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_subject',
        width: 170,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_type',
        width: 121,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_audience',
        width: 98,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_market',
        width: 84,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: true,
        rowGroupIndex: 0,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_send_date',
        width: 191,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_send_date_week',
        width: 75,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_send_date_month',
        width: 81,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_send_date_year',
        width: 71,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_sent_all_sum',
        width: 159,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_sent_unique_sum',
        width: 113,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_open_unique_sum',
        width: 193,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_open_unique_rate',
        width: 171,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_unique_sum',
        width: 160,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_unique_rate',
        width: 146,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_all_sum',
        width: 95,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_all_rate',
        width: 119,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_to_open_all_rate',
        width: 168,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_to_open_unique_rate',
        width: 194,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_per_user_avg',
        width: 131,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_softbounce_sum',
        width: 122,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_softbounce_rate',
        width: 145,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_hardbounce_sum',
        width: 125,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_hardbounce_rate',
        width: 149,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_complaints_sum',
        width: 110,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_complaints_rate',
        width: 133,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_unsubscribe_sum',
        width: 124,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_unsubscribe_rate',
        width: 144,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_deliverability_rate',
        width: 144,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_session_sum',
        width: 94,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_session_time_sum',
        width: 118,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_bounce_sum',
        width: 93,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_session_time_avg',
        width: 173,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_transactions_sum',
        width: 118,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_conversion_rate',
        width: 139,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_revenue_sum',
        width: 92,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_revenue_per_session',
        width: 165,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_revenue_per_email_sent',
        width: 181,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_average_order_value',
        width: 161,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
];

export const broadcastStandard = [
    {
        colId: 'ag-Grid-AutoColumn',
        width: 200,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_campaign_type',
        width: 132,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_campaign_id',
        width: 124,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_campaign_name',
        width: 170,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_name',
        width: 106,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_subject',
        width: 106,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_type',
        width: 121,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_audience',
        width: 98,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_market',
        width: 84,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: true,
        rowGroupIndex: 0,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_send_date',
        width: 191,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_send_date_week',
        width: 75,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_send_date_month',
        width: 81,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'broadcast_mailing_send_date_year',
        width: 71,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_sent_all_sum',
        width: 106,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_sent_unique_sum',
        width: 106,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_open_unique_sum',
        width: 106,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_open_unique_rate',
        width: 104,
        hide: false,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_unique_sum',
        width: 160,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_unique_rate',
        width: 146,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_all_sum',
        width: 95,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_all_rate',
        width: 119,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_to_open_all_rate',
        width: 168,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_to_open_unique_rate',
        width: 194,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_clicks_per_user_avg',
        width: 131,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_softbounce_sum',
        width: 122,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_softbounce_rate',
        width: 145,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_hardbounce_sum',
        width: 125,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_hardbounce_rate',
        width: 149,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_complaints_sum',
        width: 110,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_complaints_rate',
        width: 133,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_unsubscribe_sum',
        width: 124,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_unsubscribe_rate',
        width: 144,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_email_deliverability_rate',
        width: 144,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_session_sum',
        width: 94,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_session_time_sum',
        width: 118,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_bounce_sum',
        width: 93,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_session_time_avg',
        width: 173,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_transactions_sum',
        width: 118,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_conversion_rate',
        width: 139,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_revenue_sum',
        width: 92,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_revenue_per_session',
        width: 165,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_revenue_per_email_sent',
        width: 181,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
    {
        colId: 'metric_website_average_order_value',
        width: 161,
        hide: true,
        pinned: null,
        sort: null,
        sortIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
    },
];

export const uncompressedConfigCustomized = [
    {
        colId: 'broadcast_campaign_type',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 132,
    },
    {
        colId: 'broadcast_campaign_id',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 124,
    },
    {
        colId: 'broadcast_campaign_name',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 170,
    },
    {
        colId: 'broadcast_mailing_name',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 170,
    },
    {
        colId: 'broadcast_mailing_subject',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 170,
    },
    {
        colId: 'broadcast_mailing_type',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 121,
    },
    {
        colId: 'broadcast_mailing_audience',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 98,
    },
    {
        colId: 'broadcast_mailing_market',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 84,
    },
    {
        colId: 'broadcast_mailing_send_date',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 191,
    },
    {
        colId: 'broadcast_mailing_send_date_week',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 75,
    },
    {
        colId: 'broadcast_mailing_send_date_month',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 81,
    },
    {
        colId: 'broadcast_mailing_send_date_year',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 71,
    },
    {
        colId: 'metric_email_sent_all_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 87,
    },
    {
        colId: 'metric_email_sent_unique_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 113,
    },
    {
        colId: 'metric_email_open_unique_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 73,
    },
    {
        colId: 'metric_email_open_unique_rate',
        hide: false,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 183,
    },
    {
        colId: 'metric_email_clicks_unique_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 160,
    },
    {
        colId: 'metric_email_clicks_unique_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 146,
    },
    {
        colId: 'metric_email_clicks_all_sum',
        hide: false,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 146,
    },
    {
        colId: 'metric_email_clicks_all_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 119,
    },
    {
        colId: 'metric_email_clicks_to_open_all_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 168,
    },
    {
        colId: 'metric_email_clicks_to_open_unique_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 194,
    },
    {
        colId: 'metric_email_clicks_per_user_avg',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 131,
    },
    {
        colId: 'metric_email_softbounce_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 122,
    },
    {
        colId: 'metric_email_softbounce_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 145,
    },
    {
        colId: 'metric_email_hardbounce_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 125,
    },
    {
        colId: 'metric_email_hardbounce_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 149,
    },
    {
        colId: 'metric_email_complaints_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 110,
    },
    {
        colId: 'metric_email_complaints_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 133,
    },
    {
        colId: 'metric_email_unsubscribe_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 124,
    },
    {
        colId: 'metric_email_unsubscribe_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 144,
    },
    {
        colId: 'metric_email_deliverability_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 144,
    },
    {
        colId: 'metric_website_session_sum',
        hide: false,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 142,
    },
    {
        colId: 'metric_website_session_time_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 118,
    },
    {
        colId: 'metric_website_bounce_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 93,
    },
    {
        colId: 'metric_website_session_time_avg',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 173,
    },
    {
        colId: 'metric_website_transactions_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 118,
    },
    {
        colId: 'metric_website_conversion_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 139,
    },
    {
        colId: 'metric_website_revenue_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 92,
    },
    {
        colId: 'metric_website_revenue_per_session',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 165,
    },
    {
        colId: 'metric_website_revenue_per_email_sent',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 181,
    },
    {
        colId: 'metric_website_average_order_value',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 161,
    },
];

export const uncompressedConfigExpected = [
    {
        colId: 'broadcast_campaign_type',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 132,
    },
    {
        colId: 'broadcast_campaign_id',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 124,
    },
    {
        colId: 'broadcast_campaign_name',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 170,
    },
    {
        colId: 'broadcast_mailing_name',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 170,
    },
    {
        colId: 'broadcast_mailing_subject',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 170,
    },
    {
        colId: 'broadcast_mailing_type',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 121,
    },
    {
        colId: 'broadcast_mailing_audience',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 98,
    },
    {
        colId: 'broadcast_mailing_market',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 84,
    },
    {
        colId: 'broadcast_mailing_send_date',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 191,
    },
    {
        colId: 'broadcast_mailing_send_date_week',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 75,
    },
    {
        colId: 'broadcast_mailing_send_date_month',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 81,
    },
    {
        colId: 'broadcast_mailing_send_date_year',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 71,
    },
    {
        colId: 'metric_email_sent_all_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 87,
    },
    {
        colId: 'metric_email_sent_unique_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 113,
    },
    {
        colId: 'metric_email_open_unique_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 73,
    },
    {
        colId: 'metric_email_open_unique_rate',
        hide: false,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 183,
    },
    {
        colId: 'metric_email_clicks_unique_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 160,
    },
    {
        colId: 'metric_email_clicks_unique_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 146,
    },
    {
        colId: 'metric_email_clicks_all_sum',
        hide: false,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 146,
    },
    {
        colId: 'metric_email_clicks_all_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 119,
    },
    {
        colId: 'metric_email_clicks_to_open_all_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 168,
    },
    {
        colId: 'metric_email_clicks_to_open_unique_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 194,
    },
    {
        colId: 'metric_email_clicks_per_user_avg',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 131,
    },
    {
        colId: 'metric_email_softbounce_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 122,
    },
    {
        colId: 'metric_email_softbounce_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 145,
    },
    {
        colId: 'metric_email_hardbounce_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 125,
    },
    {
        colId: 'metric_email_hardbounce_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 149,
    },
    {
        colId: 'metric_email_complaints_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 110,
    },
    {
        colId: 'metric_email_complaints_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 133,
    },
    {
        colId: 'metric_email_unsubscribe_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 124,
    },
    {
        colId: 'metric_email_unsubscribe_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 144,
    },
    {
        colId: 'metric_email_deliverability_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 144,
    },
    {
        colId: 'metric_website_session_sum',
        hide: false,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 142,
    },
    {
        colId: 'metric_website_session_time_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 118,
    },
    {
        colId: 'metric_website_bounce_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 93,
    },
    {
        colId: 'metric_website_session_time_avg',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 173,
    },
    {
        colId: 'metric_website_transactions_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 118,
    },
    {
        colId: 'metric_website_conversion_rate',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 139,
    },
    {
        colId: 'metric_website_revenue_sum',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 92,
    },
    {
        colId: 'metric_website_revenue_per_session',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 165,
    },
    {
        colId: 'metric_website_revenue_per_email_sent',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 181,
    },
    {
        colId: 'metric_website_average_order_value',
        hide: true,
        pinned: null,
        pivot: false,
        pivotIndex: null,
        rowGroup: false,
        rowGroupIndex: null,
        sort: null,
        sortIndex: null,
        width: 161,
    },
];
