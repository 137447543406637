import { Box } from '@material-ui/core';
import React from 'react';
import { RangePickerLab } from '../Rangepicker/RangePickerLab';
import { ViewSelect } from '../ViewSelect/ViewSelect';
import { AutosaveIndicator } from './AutosaveIndicator';
import { SeinoToolbar } from './Navigation.styles';
import { ViewIconButtons } from './ViewIconButtons/ViewIconButtons';

export const Navigation = () => {
    return (
        <SeinoToolbar color="secondary">
            <Box width="400px">
                <ViewSelect />
            </Box>
            <Box display="flex" alignItems="center">
                <AutosaveIndicator />
                <ViewIconButtons />
                <Box>
                    <RangePickerLab />
                </Box>
            </Box>
        </SeinoToolbar>
    );
};
