import { GetState, SetState, StateCreator } from 'zustand';
import {
    CanopyDeployCredentials,
    configureActiveCampaign,
    ConfigureActiveCampaignProps,
    configureCopernica,
    ConfigureCopernicaProps,
    ConfigureESPResult,
    configureGoogle,
    ConfigureGoogleProps,
    fetchAggregationRules,
    fetchAvailableMembers,
    fetchCanopyDeployCredentials,
    fetchDataSources,
    fetchGaViews,
    fetchPermissions,
    fetchWorkspace,
    FetchWorkspaceProps,
    grantPermission,
    MemberInvitationProps,
    MemberPermissionProps,
    putAggregationRules,
    PutAggregationRulesProps,
    removeGAConnection,
    withdrawInvitation,
    withdrawPermission,
} from '../../api-client';
import { GAView } from '../../components/Form/RuleBuilder/CreateRuleRow';
import { createFetchState, FetchState } from '../helpers';

export interface DataSources {
    hasCopernicaConnection: boolean;
    hasEmailProviderConnection: boolean;
    hasAnalyticsProviderConnection: boolean;
    hasConfiguredAggregationRules: boolean;
    hasActiveCampaignConnection: boolean;
    activeCampaignUrl: string;
}

export type AggregationConfig = {
    type?: MarketType;
    rules?: Rule[];
    rule?: {
        market: string;
        view_id: string;
    };
};

export interface Rule {
    field?: string;
    condition?: string;
    value?: string;
    market?: string;
    view_id?: string;
    currency?: string;
}

export enum MarketType {
    MULTI_MARKET = 'multi_market',
    SINGLE_MARKET = 'single_market',
}

export interface Permission {
    id: string;
    name: string;
    email: string;
    role: string;
    type: 'member' | 'invitation';
    isAccountAdmin: boolean;
}

export interface PermissionsResult {
    permissions: Permission[];
}

export interface AvailableMembersResult {
    members: AvailableMember[];
}

export interface AvailableMember {
    id: string;
    name: string;
    email: string;
    type: 'invitation' | 'member';
}

export interface GAViewsResult {
    views: GAView[];
}

interface Account {
    id: string;
    name: string;
}

export interface Workspaces {
    workspaces: Workspace[];
}

export interface Workspace {
    id: string;
    name: string;
    permissions: Permission[];
    associatedAccount: Account;
    hasEmailProviderConnection: boolean;
    hasAnalyticsProviderConnection: boolean;
    hasConfiguredAggregationRules: boolean;
}

export interface WorkspaceManagementStore {
    workspace: FetchState<FetchWorkspaceProps, Workspace>;
    permissions: FetchState<FetchWorkspaceProps, PermissionsResult>;
    availableMembers: FetchState<FetchWorkspaceProps, AvailableMembersResult>;
    aggregationRules: FetchState<FetchWorkspaceProps, AggregationConfig>;
    configureAggregationRules: FetchState<PutAggregationRulesProps, null>;
    gaViews: FetchState<FetchWorkspaceProps, GAViewsResult>;
    grantMember: FetchState<MemberPermissionProps, null>;
    withdrawPermission: FetchState<MemberPermissionProps, null>;
    withdrawInvitation: FetchState<MemberInvitationProps, null>;
    dataSources: FetchState<FetchWorkspaceProps, DataSources>;
    removeGAConnection: FetchState<FetchWorkspaceProps, null>;
    configureGoogle: FetchState<ConfigureGoogleProps, any>;
    canopyDeployCredentials: FetchState<
        FetchWorkspaceProps,
        CanopyDeployCredentials
    >;
    configureCopernica: FetchState<ConfigureCopernicaProps, ConfigureESPResult>;
    configureActiveCampaign: FetchState<
        ConfigureActiveCampaignProps,
        ConfigureESPResult
    >;
}

export const workspaceManagementStore: StateCreator<WorkspaceManagementStore> = (
    set: SetState<WorkspaceManagementStore>,
    get: GetState<WorkspaceManagementStore>
) => ({
    workspace: createFetchState<FetchWorkspaceProps, Workspace>(
        'workspace',
        fetchWorkspace
    )(set, get),
    permissions: createFetchState<FetchWorkspaceProps, PermissionsResult>(
        'permissions',
        fetchPermissions
    )(set, get),
    availableMembers: createFetchState<
        FetchWorkspaceProps,
        AvailableMembersResult
    >('availableMembers', fetchAvailableMembers)(set, get),
    aggregationRules: createFetchState<FetchWorkspaceProps, AggregationConfig>(
        'aggregationRules',
        fetchAggregationRules
    )(set, get),
    gaViews: createFetchState<FetchWorkspaceProps, GAViewsResult>(
        'gaViews',
        fetchGaViews
    )(set, get),
    grantMember: createFetchState<MemberPermissionProps, null>(
        'grantMember',
        grantPermission
    )(set, get),
    withdrawPermission: createFetchState<MemberPermissionProps, null>(
        'withdrawPermission',
        withdrawPermission
    )(set, get),
    withdrawInvitation: createFetchState<MemberInvitationProps, null>(
        'withdrawInvitation',
        withdrawInvitation
    )(set, get),
    dataSources: createFetchState<FetchWorkspaceProps, DataSources>(
        'dataSources',
        fetchDataSources
    )(set, get),
    removeGAConnection: createFetchState<FetchWorkspaceProps, null>(
        'removeGAConnection',
        removeGAConnection
    )(set, get),
    configureGoogle: createFetchState<ConfigureGoogleProps, any>(
        'configureGoogle',
        configureGoogle
    )(set, get),
    canopyDeployCredentials: createFetchState<
        FetchWorkspaceProps,
        CanopyDeployCredentials
    >('canopyDeployCredentials', fetchCanopyDeployCredentials)(set, get),
    configureCopernica: createFetchState<
        ConfigureCopernicaProps,
        ConfigureESPResult
    >('configureCopernica', configureCopernica)(set, get),
    configureActiveCampaign: createFetchState<
        ConfigureActiveCampaignProps,
        ConfigureESPResult
    >('configureActiveCampaign', configureActiveCampaign)(set, get),
    configureAggregationRules: createFetchState<PutAggregationRulesProps, null>(
        'configureAggregationRules',
        putAggregationRules
    )(set, get),
});
