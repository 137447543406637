import { Box, Button } from '@material-ui/core';
import {
    format,
    getDay,
    isFirstDayOfMonth,
    isLastDayOfMonth,
    isToday,
} from 'date-fns';
import { memo } from 'react';
import { DateRangePickerDispatch } from './reducer';

interface CalendarDayProps {
    dispatch: DateRangePickerDispatch;
    date: Date;
    inRange: boolean;
    firstOfRange: boolean;
    lastOfRange: boolean;
    weekStartsOn: Day;
    disabled: boolean;
}

const CalendarDay = ({
    dispatch,
    date,
    weekStartsOn,
    inRange,
    firstOfRange,
    lastOfRange,
    disabled,
}: CalendarDayProps) => {
    const dayOfWeek = (7 + getDay(date) - weekStartsOn) % 7;

    const firstInWeek = dayOfWeek === 0 || isFirstDayOfMonth(date);
    const lastInWeek = dayOfWeek === 6 || isLastDayOfMonth(date);
    const handleClick = () => dispatch({ type: 'selectDate', date });

    return (
        <Box
            sx={{
                gridColumnStart: date.getDate() === 1 ? dayOfWeek + 1 : 'auto',
                backgroundColor: inRange ? 'primary.light' : 'unset',
                ...((firstOfRange || firstInWeek) && roundBorderLeft),
                ...((lastOfRange || lastInWeek) && roundBorderRight),
            }}
        >
            <Button
                data-testid={`calendar-date-${format(date, 'yyyy-MM-dd')}`}
                disabled={disabled}
                variant={firstOfRange || lastOfRange ? 'contained' : 'text'}
                color={
                    inRange && !firstOfRange && !lastOfRange
                        ? 'secondary'
                        : 'primary'
                }
                disableElevation={true}
                onClick={handleClick}
                sx={{
                    fontWeight: isToday(date) ? 700 : 400,
                    minWidth: 0,
                    fontSize: 14,
                    width: 32,
                    height: 32,
                    borderRadius: 32,
                }}
            >
                {date.getDate()}
            </Button>
        </Box>
    );
};

const roundBorderLeft = {
    borderTopLeftRadius: 32,
    borderBottomLeftRadius: 32,
};

const roundBorderRight = {
    borderTopRightRadius: 32,
    borderBottomRightRadius: 32,
};

const Component = memo(CalendarDay);
export { Component as CalendarDay };
