import { Select, Typography } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { NoInfer } from './utils';

export interface FormSelectProps<Data extends object> {
    name: keyof Data & string;
    label: string;
    requiredMessage?: string;
    children?: React.ReactNode;
}

export const FormSelect = <
    Data extends object = {
        'No form data provided (Use syntax: <FormSelect<FormDataType> ...)': true;
    }
>({
    name,
    label,
    requiredMessage,
    children,
}: FormSelectProps<NoInfer<Data>>) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <>
            <Select
                {...register(name as any, {
                    required: requiredMessage,
                })}
                fullWidth
                label={label}
                variant="standard"
                displayEmpty
                error={!!errors[name]?.message}
                defaultValue=""
            >
                {children}
            </Select>
            <Typography color="error" variant="subtitle1">
                {errors[name]?.message}
            </Typography>
        </>
    );
};
