import { Typography } from '@mui/material';
import { memo } from 'react';
import styled from 'styled-components';
import { getWeekdays } from '../../utils/weeks';

interface CalendarWeekdaysProps {
    weekStartsOn: Day;
    locale: Locale;
}

const CalendarWeekdays = ({ weekStartsOn, locale }: CalendarWeekdaysProps) => {
    const weekdays = getWeekdays(locale, 'narrow', weekStartsOn);

    return (
        <>
            {weekdays.map((w, i) => (
                <Weekday key={i}>{w}</Weekday>
            ))}
        </>
    );
};

const Weekday = styled(Typography).attrs({ component: 'div', fontSize: 14 })`
    text-align: center;
    user-select: none;
    opacity: 0.4;
    height: 24px;
`;

const Component = memo(CalendarWeekdays);
export { Component as CalendarWeekdays };
