import styled from 'styled-components';
import { ColorNames } from '../../utils/styles';

export const SeinoToolbar = styled.div`
    background-color: ${ColorNames.WHITE};
    border-bottom: 1px solid ${ColorNames.LIGHT_BLUE};
    justify-content: space-between;
    align-items: center;
    display: flex;

    .divider {
        margin: 0 0.5rem;
    }
`;

export const DrawerHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-between;
`;

export const Content = styled.main`
    flex-grow: 1;
    margin-top: 22px;
    display: flex;
`;

export const TitleContainer = styled.div`
    text-transform: capitalize;
    user-select: none;
    padding: 0 15px;
`;
