import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { Box, CircularProgress, ThemeProvider } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import './App.css';
import { Auth } from './components/AuthWrapper/Auth';
import { useSeinoAuth } from './hooks/use-seino-auth';
import { configureInterceptor } from './interceptor';
import { SeinoRouter } from './router/Router';
import { useSeinoStore } from './store/seino-store';
import { theme } from './theme';

const App = () => {
    // Memoize state, dispatch to prevent
    // unnecessary re-renders
    const {
        user: {
            workspaces,
            isAccountAdmin,
            onboardingRequired,
            currentWorkspaceId,
        },
        dataGrid: { darkMode },
    } = useSeinoStore();

    const { isLoading, fetchToken } = useSeinoAuth();
    const cubejsApi = cubejs(
        workspaces.find(w => w.id === currentWorkspaceId)?.cubeToken || '',
        {
            apiUrl: `${process.env.REACT_APP_CUBE_API_URL}`,
        }
    );

    configureInterceptor(fetchToken);

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <div className={`App${darkMode ? '-dark' : ''}`}>
                        <Auth>
                            {isLoading ? (
                                <Box
                                    display="flex"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    minHeight="400px"
                                >
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <CubeProvider cubejsApi={cubejsApi}>
                                    <SeinoRouter
                                        onboardingRequired={onboardingRequired}
                                        isAccountAdmin={isAccountAdmin}
                                        hasWorkspaces={workspaces.length > 0}
                                    />
                                </CubeProvider>
                            )}
                        </Auth>
                    </div>
                </SnackbarProvider>
            </ThemeProvider>
        </StylesProvider>
    );
};

function getKey() {
    const prodKey = 'sdk-c4a82fd0-7526-4786-9c84-afc8c09f6ef9';
    const devKey = 'sdk-72b94cd5-a52f-453e-a236-de00576a1864';
    return process.env.NODE_ENV === 'production' ? prodKey : devKey;
}

function getID() {
    const prodClientSideId = '60c31c02b025eb0bf4cc03f0';
    const devClientSideId = '60c31c02b025eb0bf4cc03ef';
    return process.env.NODE_ENV === 'production'
        ? prodClientSideId
        : devClientSideId;
}

export default withLDProvider({
    clientSideID: getID(),
    user: {
        key: getKey(),
        name: 'Paul Jones',
        email: 'paul@seino.ai',
    },
    options: {
        bootstrap: 'localStorage',
    },
})(App);
