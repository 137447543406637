import { Box, Divider, Paper, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { useSeinoStore } from '../../store/seino-store';
import { ColorNames } from '../../utils/styles';
import { MetricChartDataWrapper } from './MetricChartDataWrapper';

export interface MetricChartGroupProps {
    group: string[];
    data: any;
    setSelected: (selected: string) => void;
    selectedMetric: string;
    enabledMetrics: string[];
    name: string;
}

const StyledDivider = styled(Divider)`
    height: 80%;
    width: 1px;
`;

const SegmentHeader = styled(Paper)`
    && {
        background-color: ${ColorNames.GRAY_HOVER};
        height: 25px;
        border-radius: 0;
        font-weight: 800;
        text-transform: uppercase;
        color: ${ColorNames.BLACK};
    }
`;

export const MetricChartGroup = ({
    group,
    data,
    selectedMetric,
    name,
    setSelected,
    enabledMetrics,
}: MetricChartGroupProps) => {
    const filtered = group.filter(standardMetrics =>
        enabledMetrics.includes(standardMetrics)
    );

    const {
        dashboard: { dateRange },
    } = useSeinoStore();

    if (!filtered.length) return <></>;

    return (
        <Box>
            <SegmentHeader>
                <Box ml={2} display="flex" alignItems="center">
                    <Typography variant="body2">{name}</Typography>
                </Box>
            </SegmentHeader>
            <Box display="flex" mt={1}>
                {filtered.map((metric, index) => (
                    <React.Fragment key={metric}>
                        <MetricChartDataWrapper
                            dateRange={dateRange}
                            highlighted={selectedMetric === metric}
                            metricName={metric}
                            onClick={setSelected}
                        />
                        {index !== group.length - 1 && (
                            <StyledDivider orientation="vertical" />
                        )}
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    );
};
