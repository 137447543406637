import React from 'react';
import styled from 'styled-components';
import { Plan, PlanCard } from './PlanCard';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 1rem;
`;

export const Plans = () => {
    return (
        <Container>
            <PlanCard plan={Plan.FREE} />
            <PlanCard plan={Plan.PRO} />
            <PlanCard plan={Plan.ENTERPRISE} />
        </Container>
    );
};
