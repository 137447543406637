import { Link, Typography } from '@mui/material';
import { InfoPanel } from '../../components/InfoPanel/InfoPanel';

export const NoWorkspaces = ({
    isAccountAdmin = false,
}: {
    isAccountAdmin: boolean;
}) => {
    const reachOut = (
        <li>
            or,
            <Link
                href="mailto:hello@seino.ai?subject=Onboarding"
                style={{ marginInline: 4 }}
            >
                reach out
            </Link>
            to us to if you suspect something is wrong.
        </li>
    );

    const steps = isAccountAdmin ? (
        <ul>
            <li>
                <Link
                    style={{ marginInlineEnd: 4 }}
                    color="primary"
                    href="/account-management"
                >
                    add a new workspace
                </Link>
                by using the account management portal
            </li>
            {reachOut}
        </ul>
    ) : (
        <ul>
            <li>
                ask your account administrator to grant you workspace
                permissions.
            </li>
            {reachOut}
        </ul>
    );

    return (
        <InfoPanel
            dividerTitle="We noticed that you're currently not assigned to any workspace"
            content={
                <>
                    <Typography mt={4} paragraph={true} variant="body1">
                        To make use of this feature, you must be assigned to at
                        least 1 workspace.
                    </Typography>

                    <Typography variant="body1">
                        A few things to try:
                        {steps}
                    </Typography>
                </>
            }
        />
    );
};
