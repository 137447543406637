import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { CancelOutlined, CheckCircleOutline, Delete } from '@material-ui/icons';
import { Button, Skeleton } from '@mui/material';
import React from 'react';

export interface GoogleAnalyticsConnectionProps {
    isConnected: boolean;
    onConnect: () => void;
    onDisconnect: () => void;
    loading: boolean;
}

export const GoogleAnalyticsConnection = ({
    isConnected,
    onConnect,
    onDisconnect,
    loading,
}: GoogleAnalyticsConnectionProps) => {
    const DisconnectButton = () => (
        <IconButton
            edge="end"
            aria-label="disconnect"
            disabled={loading}
            onClick={() => onDisconnect()}
        >
            <Delete />
        </IconButton>
    );

    const ConnectButton = () => (
        <Button
            variant="contained"
            size="small"
            disabled={loading}
            color="primary"
            onClick={() => onConnect()}
        >
            Connect
        </Button>
    );

    const LoadingButton = () => (
        <Skeleton width="80px">
            <IconButton edge="end" aria-label="loading">
                <Delete />
            </IconButton>
        </Skeleton>
    );

    const AnalyticsButton = () => {
        if (loading) {
            return <LoadingButton />;
        }

        return isConnected ? <DisconnectButton /> : <ConnectButton />;
    };

    const Title = () => {
        if (loading) {
            return (
                <Typography variant="body1">
                    <Skeleton width="120px" />
                </Typography>
            );
        }

        return (
            <Typography variant="body1">
                {isConnected
                    ? 'Google Analytics is already configured for this workspace'
                    : 'Configure your Google Analytics'}
            </Typography>
        );
    };

    const Status = () => {
        const status = isConnected ? (
            <CheckCircleOutline color="success" />
        ) : (
            <CancelOutlined color="error" />
        );

        return loading ? (
            <Skeleton variant="circular">{status}</Skeleton>
        ) : (
            status
        );
    };

    return (
        <Box mb={4}>
            <Typography mt={1} mb={1} variant="body1">
                <Title />
            </Typography>
            <List sx={{ width: 420 }}>
                <ListItem secondaryAction={<AnalyticsButton />}>
                    <ListItemAvatar>
                        <Status />
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            loading ? (
                                <Skeleton width="180px" />
                            ) : (
                                'Google Analytics V3 (UA)'
                            )
                        }
                        secondary={
                            loading ? (
                                <Skeleton width="100px" />
                            ) : isConnected ? (
                                'Successfully connected'
                            ) : (
                                'Disconnected'
                            )
                        }
                    />
                </ListItem>
            </List>
        </Box>
    );
};
