import { Create, Delete, LibraryAdd, TrendingUp } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ColorNames } from '../../../utils/styles';
import { KPIDashboard } from '../../KPI/KPIDashboard';
import { DeleteViewPanel } from './DeleteViewPanel';
import { SaveViewPanel } from './SaveViewPanel';
import { ViewAction } from './ViewAction';

const ButtonWrapper = styled(Box)`
    display: flex;
    text-align: right;
    align-items: center;
    & div {
        border-right: 1px solid ${ColorNames.GREY_DIVIDER};
        padding: 0 10px;
    }
`;

export const ViewIconButtons = () => {
    const [openId, setOpenId] = useState<string | null>(null);

    const handleClick = useCallback((id: string | null) => {
        setOpenId(id);
    }, []);

    const handleClose = useCallback(() => {
        setOpenId(null);
    }, []);

    return (
        <ButtonWrapper>
            <Box>
                <ViewAction
                    id="rename"
                    tooltip="Rename view"
                    open={openId === 'rename'}
                    onClick={handleClick}
                    Icon={<Create color="primary" fontSize="small" />}
                    panel={
                        <SaveViewPanel mode="rename" onClose={handleClose} />
                    }
                />
            </Box>
            <Box>
                <ViewAction
                    id="kpi"
                    tooltip="Set targets"
                    open={openId === 'kpi'}
                    onClick={handleClick}
                    Icon={<TrendingUp color="primary" fontSize="small" />}
                    panel={<KPIDashboard onClose={handleClose} />}
                />
            </Box>
            <Box>
                <ViewAction
                    id="delete"
                    tooltip="Delete view"
                    open={openId === 'delete'}
                    onClick={handleClick}
                    Icon={<Delete color="primary" fontSize="small" />}
                    panel={<DeleteViewPanel onClose={handleClose} />}
                />
            </Box>
            <Box>
                <ViewAction
                    id="copy"
                    tooltip="Duplicate view"
                    open={openId === 'copy'}
                    onClick={handleClick}
                    Icon={<LibraryAdd color="primary" fontSize="small" />}
                    panel={
                        <SaveViewPanel mode="duplicate" onClose={handleClose} />
                    }
                />
            </Box>
        </ButtonWrapper>
    );
};
