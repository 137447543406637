import styled from 'styled-components';
import { CircularProgress, Fade } from '@material-ui/core';

const LogoutWrapper = styled.div`
    position: absolute;
    z-index: 10000000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    right: 0;
    text-align: center;

    .App-dark & {
        background-color: #181818;
    }
`;

const SpinnerContainer = styled.div`
    margin-top: 150px;
`;

export const Login = () => {
    return (
        <Fade in={true}>
            <LogoutWrapper>
                <SpinnerContainer>
                    <CircularProgress />
                </SpinnerContainer>
            </LogoutWrapper>
        </Fade>
    );
};
