import { Box, Button, ClickAwayListener, Paper, Popper } from '@mui/material';
import { subMonths, toDate } from 'date-fns';
import { rangeRight } from 'lodash';
import { useMemo, useReducer, useRef } from 'react';
import styled from 'styled-components';
import { CalendarMonth } from './CalendarMonth';
import { normalizeInterval } from './dateHelpers';
import { MonthSwitcher } from './MonthSwitcher';
import { PickerToggle } from './PickerToggle';
import { DateRangePickerState, reducer } from './reducer';

export interface DateRangePickerProps {
    dateRange: Interval;
    onChange: (interval: Interval) => void;
    locale: Locale;
    weekStartsOn: Day;
    calendarCount?: number;
}

export const DateRangePicker = ({
    dateRange: prevDateRange,
    onChange,
    locale,
    weekStartsOn,
    calendarCount = 3,
}: DateRangePickerProps) => {
    const initialState: DateRangePickerState = {
        isOpen: false,
        dateRange: normalizeInterval(prevDateRange),
        currentSide: 'start',
        lastVisibleMonth: toDate(prevDateRange.end),
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const toggleRef = useRef(null);

    const visibleMonths = useMemo(
        () =>
            rangeRight(calendarCount).map(i =>
                subMonths(state.lastVisibleMonth, i)
            ),
        [calendarCount, state.lastVisibleMonth]
    );

    const close = () => state.isOpen && dispatch({ type: 'close' });
    const reset = () => dispatch({ type: 'reset', prevDateRange });
    const apply = () => {
        dispatch({ type: 'close' });
        onChange(state.dateRange);
    };

    return (
        <ClickAwayListener onClickAway={close}>
            <Box>
                <PickerToggle
                    dispatch={dispatch}
                    dateRange={prevDateRange}
                    ref={toggleRef}
                />
                <Popper
                    open={state.isOpen}
                    anchorEl={toggleRef.current}
                    placement="bottom-end"
                    style={{ zIndex: 10 }}
                >
                    <PopupSurface>
                        <CalendarContainer>
                            <MonthSwitcher
                                dispatch={dispatch}
                                direction="previous"
                            />

                            <CalendarGrid>
                                {visibleMonths.map(m => (
                                    <CalendarMonth
                                        key={m.getMonth()}
                                        dispatch={dispatch}
                                        month={m}
                                        dateRange={state.dateRange}
                                        currentSide={state.currentSide}
                                        weekStartsOn={weekStartsOn}
                                        locale={locale}
                                    />
                                ))}
                            </CalendarGrid>

                            <MonthSwitcher
                                dispatch={dispatch}
                                direction="next"
                            />
                        </CalendarContainer>

                        <ButtonContainer>
                            <Button
                                data-testid="close-picker"
                                variant="text"
                                onClick={reset}
                            >
                                Reset
                            </Button>
                            <Button
                                data-testid="apply-dates"
                                variant="contained"
                                onClick={apply}
                            >
                                Apply
                            </Button>
                        </ButtonContainer>
                    </PopupSurface>
                </Popper>
            </Box>
        </ClickAwayListener>
    );
};

const PopupSurface = styled(Paper).attrs({ elevation: 8 })`
    display: flex;
    flex-direction: column;
    margin: 16px;
    padding: 16px;
`;

const CalendarContainer = styled(Box)`
    display: flex;
    flex-direction: row;
`;

const CalendarGrid = styled(Box)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    row-gap: 16px;
`;

const ButtonContainer = styled(Box)`
    display: flex;
    justify-content: flex-end;
`;
