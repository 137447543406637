import { Box } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Container, Paper, Stack, Tab } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { MemberInvitation } from '../../../../components/MemberInvitation/MemberInvitation';
import { WorkspaceList } from '../../../../components/WorkspaceList/WorkspaceList';
import { LoadingStatus } from '../../../../constants';
import { useSeinoAuth } from '../../../../hooks/use-seino-auth';
import { useSeinoStore } from '../../../../store/seino-store';
import { AddWorkspaceDialog } from '../AddWorkspace/AddWorkspaceDialog';
import { MemberManagement } from './MemberManagement';

export interface ManageAccountProps {
    accountId: string;
    selectedTab: string;
    onTabChanged: (tab: string) => void;
    onWorkspaceSelected: (workspaceId: string) => void;
}

export const ManageAccount = ({
    accountId,
    selectedTab,
    onTabChanged,
    onWorkspaceSelected,
}: ManageAccountProps) => {
    const { createWorkspace } = useFlags();
    const {
        accountManagement: {
            addWorkspace,
            account,
            deleteMember,
            deleteInvitation,
            changeMemberRole,
            members,
            inviteMember,
            invitations,
        },
    } = useSeinoStore();
    const [showAddWorkspaceDialog, setShowAddWorkspaceDialog] = useState(false);

    const { fetchToken } = useSeinoAuth();
    useEffect(() => {
        account.fetch({ accountId });
        members.fetch({ accountId });
        invitations.fetch({ accountId });
    }, [accountId, fetchToken]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAddWorkspace = async (name: string) => {
        await addWorkspace.fetch({ accountId, name });
        setShowAddWorkspaceDialog(false);
        account.fetch({ accountId });
    };

    const memberInvitationOnSubmit = async (
        accountId: string,
        email: string,
        name: string
    ) => {
        await inviteMember.fetch({ accountId, name: name, email: email });
        invitations.fetch({ accountId });
    };

    const onDeleteMember = async (accountId: string, memberId: string) => {
        await deleteMember.fetch({ accountId, memberId });
        members.fetch({ accountId });
    };

    const onDeleteInvitation = async (accountId: string, token: string) => {
        await deleteInvitation.fetch({ accountId, token });
        invitations.fetch({ accountId });
    };

    const onChangeMemberRole = async (
        accountId: string,
        memberId: string,
        newRole: string
    ) => {
        await changeMemberRole.fetch({ accountId, memberId, newRole });
        members.fetch({ accountId });
        invitations.fetch({ accountId });
    };

    return (
        <Container style={{ padding: 0 }} maxWidth={'xl'}>
            <TabContext value={selectedTab}>
                <TabList
                    onChange={(_, newValue) => onTabChanged(newValue)}
                    aria-label="Workspace Settings"
                >
                    <Tab label="Workspaces" value="workspaces" />
                    <Tab label="Members" value="members" />
                </TabList>

                <TabPanel style={{ padding: 0 }} value="workspaces">
                    <Box square={true} variant={'elevation'} component={Paper}>
                        <Stack padding={2} direction="column" gap={1}>
                            <WorkspaceList
                                loading={
                                    account.status === LoadingStatus.LOADING
                                }
                                onSelect={id => onWorkspaceSelected(id)}
                                workspaces={account.result?.workspaces || []}
                            />
                            {createWorkspace && (
                                <Button
                                    color="info"
                                    onClick={() =>
                                        setShowAddWorkspaceDialog(
                                            value => !value
                                        )
                                    }
                                    variant="contained"
                                    style={{ maxWidth: 250 }}
                                    startIcon={<Add />}
                                >
                                    Add workspace
                                </Button>
                            )}
                        </Stack>
                    </Box>
                </TabPanel>
                <TabPanel style={{ padding: 0 }} value="members">
                    <Box square={true} variant={'elevation'} component={Paper}>
                        <Stack padding={2} direction="column" gap={1}>
                            <MemberInvitation
                                status={inviteMember.status}
                                onSubmit={(email: string, name: string) =>
                                    memberInvitationOnSubmit(
                                        accountId,
                                        email,
                                        name
                                    )
                                }
                            />

                            <MemberManagement
                                loading={
                                    members.status === LoadingStatus.LOADING ||
                                    invitations.status ===
                                        LoadingStatus.LOADING ||
                                    changeMemberRole.status ===
                                        LoadingStatus.LOADING
                                }
                                members={members?.result?.members || []}
                                invitations={
                                    invitations?.result?.invitations || []
                                }
                                deleteMember={(id: string) =>
                                    onDeleteMember(accountId, id)
                                }
                                deleteInvitation={token =>
                                    onDeleteInvitation(accountId, token)
                                }
                                onChangeMemberRole={(
                                    memberId: string,
                                    role: string
                                ) =>
                                    onChangeMemberRole(
                                        accountId,
                                        memberId,
                                        role
                                    )
                                }
                            />
                        </Stack>
                    </Box>
                </TabPanel>
            </TabContext>
            <AddWorkspaceDialog
                open={showAddWorkspaceDialog}
                onConfirm={handleAddWorkspace}
                handleClose={() => setShowAddWorkspaceDialog(false)}
            />
        </Container>
    );
};
