function notEmpty(value: any): boolean {
    return !!value;
}

function onlyUnique<T>(value: T, index: number, self: Array<T>): boolean {
    return self.indexOf(value) === index;
}

export const utmParam = (paramName: string) => ({ data }: { data: any }) =>
    data?.utm_metrics
        ?.map((x: any) => x[paramName])
        ?.filter(notEmpty)
        ?.filter(onlyUnique)
        ?.sort((a: any, b: any) => (a < b ? -1 : 1))
        ?.join(', ');
