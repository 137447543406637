import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AUTH0_API_AUDIENCE, AUTH0_DEFAULT_SCOPE } from './constants';
import './index.css';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain={
                process.env.NODE_ENV === 'production'
                    ? 'login.seino.ai'
                    : 'seino-development.eu.auth0.com'
            }
            clientId={
                process.env.NODE_ENV === 'production'
                    ? 'CHlU5KZbY8rX7IgQ0VORLnUI9aSM55wM'
                    : 'fEqWvMBvhsy8zngelf6wRoTlNHf6OzUs'
            }
            redirectUri={window.location.origin}
            cacheLocation={'memory'}
            audience={AUTH0_API_AUDIENCE}
            scope={AUTH0_DEFAULT_SCOPE}
        >
            <App />
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
