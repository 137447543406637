import { Button } from '@mui/material';
import { format } from 'date-fns';
import React, { forwardRef } from 'react';
import { DateRangePickerDispatch } from './reducer';

interface PickerToggleProps {
    dispatch: DateRangePickerDispatch;
    dateRange: Interval;
}

export const PickerToggle = forwardRef<HTMLButtonElement, PickerToggleProps>(
    ({ dispatch, dateRange }, ref) => {
        return (
            <Button
                data-testid="toggle-date-range-picker"
                ref={ref}
                onClick={() => dispatch({ type: 'toggle' })}
            >
                <span>{format(dateRange.start, 'dd-MM-yyyy')}</span>
                <span style={{ margin: '0 8px' }}>-</span>
                <span>{format(dateRange.end, 'dd-MM-yyyy')}</span>
            </Button>
        );
    }
);
