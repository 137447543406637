import {
    ColumnEvent,
    ColumnPivotModeChangedEvent,
    ColumnValueChangedEvent,
    DetailGridInfo,
    FilterModifiedEvent,
    GridReadyEvent,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { debounce, isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { StoredView } from '../../components/ExplorerGrid/grid/utils/gridView';
import { COL_MAP, GRID_VIEW_VERSION, LoadingStatus } from '../../constants';
import { addMissingColumns } from './grid/utils/addMissingColumns';
import { CustomDimension } from './grid/utils/customDimension';
import { fixAggFunc, fixHide } from './grid/utils/fixColumnState';
import { GoalMetric } from './grid/utils/goalMetric';
import { gridLabels } from './grid/utils/gridLabels';
import { createGridOptions } from './gridOptions';

// TODO move to .env file later for maintainability
LicenseManager.setLicenseKey(
    'CompanyName=SEIN B.V.,LicensedApplication=SEINO,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-024281,ExpiryDate=26_February_2023_[v2]_MTY3NzM2OTYwMDAwMA==cea6edd8ed4fd7a20dcff9bf3eff5063'
);

export interface ExplorerGridProps {
    currentView: StoredView;
    data: any;
    loading: LoadingStatus;
    onApiReady: (grid: DetailGridInfo) => void;
    autoSave: (view: StoredView) => void;
    firstDayOfWeek: Day;
    customDimensions: CustomDimension[];
    goalMetrics: GoalMetric[];
    topMarginOffset?: number;
}

export const ExplorerGrid = ({
    loading,
    data,
    currentView,
    autoSave,
    onApiReady,
    firstDayOfWeek,
    customDimensions,
    goalMetrics,
    topMarginOffset = 109,
}: ExplorerGridProps) => {
    const [grid, setGrid] = useState<DetailGridInfo | null>(null);

    // Set the grid reference to something
    useEffect(() => {
        if (grid) {
            onApiReady(grid);
        }
    }, [grid, onApiReady]);

    // Handle loading state in the grid
    useEffect(() => {
        if (loading === LoadingStatus.LOADING) {
            grid?.api?.showLoadingOverlay();
        } else if (loading === LoadingStatus.FAILED) {
            grid?.api?.showNoRowsOverlay();
        } else {
            grid?.api?.hideOverlay();
        }
    }, [grid, loading]);

    const onGridReady = useCallback(
        (grid: DetailGridInfo) => {
            setGrid(grid);
        },
        [setGrid]
    );

    const applyKPIS = useCallback(() => {
        if (!currentView) return;
        const { kpiData = {} } = currentView;

        Object.values(COL_MAP).forEach(colId => {
            const column = grid?.columnApi?.getColumn(colId);

            column?.setColDef(
                {
                    ...column.getColDef(),
                    cellRendererParams: {
                        kpiData: Object.keys(kpiData).includes(colId)
                            ? kpiData
                            : null,
                    },
                },
                {}
            );
        });

        grid?.api?.refreshCells({
            force: true,
        });
    }, [grid, currentView]);

    // Apply an updated view to the grid
    useEffect(() => {
        if (currentView?.view) {
            grid?.columnApi?.applyColumnState({
                state: addMissingColumns(
                    currentView.view,
                    grid?.columnApi.getAllColumns()
                ).map(fixAggFunc(currentView.pivotMode)),
                applyOrder: true,
            });
            grid?.columnApi?.setPivotMode(currentView.pivotMode);
            grid?.api?.setFilterModel(currentView?.filterModel);
        }
        if (currentView?.kpiData) {
            applyKPIS();
        }
    }, [currentView, grid, applyKPIS]);

    const restoreState = useCallback(() => {
        if (currentView?.view) {
            grid?.columnApi?.applyColumnState({
                state: addMissingColumns(
                    currentView.view,
                    grid?.columnApi.getAllColumns()
                ).map(fixAggFunc(currentView.pivotMode)),
                applyOrder: true,
            });
            grid?.columnApi?.setPivotMode(currentView.pivotMode);
            grid?.api?.setFilterModel(currentView?.filterModel);
        }
        applyKPIS();
    }, [grid, currentView, applyKPIS]);

    const triggerAutosave = useCallback(() => {
        const configObj: StoredView = {
            ...currentView,
            version: GRID_VIEW_VERSION,
            modified: Date.now(),
            view: grid?.columnApi?.getColumnState()!,
            pivotMode: grid?.columnApi?.isPivotMode() || false,
            filterModel: grid?.api?.getFilterModel() || {},
            created: currentView.created,
        };

        autoSave(configObj);
    }, [currentView, autoSave, grid]);

    const stateChanged = useCallback(
        ({ source }: ColumnEvent) => {
            if (source === undefined || source === 'api') return;

            triggerAutosave();
        },
        [triggerAutosave]
    );

    const columnValueChanged = ({ columnApi }: ColumnValueChangedEvent) => {
        columnApi.setColumnState(
            columnApi.isPivotMode()
                ? columnApi.getColumnState().map(fixHide)
                : columnApi
                      .getColumnState()
                      .map(fixAggFunc(columnApi.isPivotMode()))
        );
    };

    const filterModifed = useCallback(
        (evt: FilterModifiedEvent) => {
            if (!isEqual(currentView.filterModel, evt.api.getFilterModel())) {
                currentView.filterModel = evt.api.getFilterModel();
                triggerAutosave();
            }
        },
        [currentView, triggerAutosave]
    );

    const pivotModeChanged = useCallback(
        ({ columnApi }: ColumnPivotModeChangedEvent) => {
            columnApi.setColumnState(
                columnApi
                    .getColumnState()
                    .map(fixAggFunc(columnApi.isPivotMode()))
            );

            if (currentView.pivotMode !== columnApi.isPivotMode()) {
                currentView.pivotMode = columnApi.isPivotMode();
                triggerAutosave();
            }
        },
        [currentView, triggerAutosave]
    );

    return (
        <div
            data-testid="grid"
            className={`ag-theme-balham`}
            style={{
                width: '100%',
                height: `calc(100vh - ${topMarginOffset}px)`,
            }}
        >
            <AgGridReact
                localeText={gridLabels}
                masterDetail={true}
                onFirstDataRendered={restoreState}
                onRowDataUpdated={restoreState}
                onGridReady={(evt: GridReadyEvent) =>
                    onGridReady((evt as unknown) as DetailGridInfo)
                }
                rowData={data}
                gridOptions={createGridOptions({
                    firstDayOfWeek,
                    customDimensions,
                    goalMetrics,
                })}
                onFilterModified={filterModifed}
                onFilterChanged={triggerAutosave}
                onColumnPivotModeChanged={pivotModeChanged}
                onColumnPivotChanged={stateChanged}
                onSortChanged={stateChanged}
                onColumnVisible={stateChanged}
                onColumnRowGroupChanged={stateChanged}
                onColumnPinned={stateChanged}
                onColumnMoved={stateChanged}
                onColumnValueChanged={columnValueChanged}
                onColumnResized={debounce(stateChanged, 2000)}
            />
        </div>
    );
};
