import { Box } from '@material-ui/core';
import { Layer, ResponsiveLine } from '@nivo/line';
import React, { useEffect, useState } from 'react';
import { useSeinoStore } from '../../store/seino-store';
import { prepareData } from './gen-data';
import { DashedLine, MainBox } from './MetricChart.styles';
import { MetricPerformanceGrid } from './MetricPerformanceGrid';

export interface ChartData {
    date: Date;
    lastPeriodDate: Date;
    value: number;
    lastPeriodValue: number;
    lastYearDate: Date;
    lastYearValue: number;
}

export interface MetricChartProps {
    metricName: string;
    highlighted?: boolean;
    onClick?: (metricName: string) => void;
    data: ChartData[];
}

export const MetricChart = (props: MetricChartProps) => {
    const { metricName, onClick, data, highlighted } = props;

    const [preparedData, setPreparedData] = useState(prepareData(data, true));

    const {
        dashboard: { dateRange },
    } = useSeinoStore();

    useEffect(() => {
        setPreparedData(prepareData(data, true));
    }, [dateRange, metricName]); // eslint-disable-line react-hooks/exhaustive-deps

    const basicOptions: Layer[] = ['axes', DashedLine()];

    const chartYScale =
        metricName.indexOf('rate') > -1
            ? 1
            : Math.max(
                  ...[
                      ...preparedData[0].data.map(({ y }) => y as number),
                      ...preparedData[1].data.map(({ y }) => y as number),
                  ]
              );

    return (
        <MainBox
            highlighted={highlighted}
            onClick={() => onClick && onClick(metricName)}
        >
            <Box
                style={{
                    height: 85,
                    margin: '5px',
                }}
            >
                <ResponsiveLine
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                    pointColor={{ theme: 'background' }}
                    data={preparedData}
                    margin={{
                        top: 30,
                        right: 10,
                        bottom: 30,
                        left: 0,
                    }}
                    layers={basicOptions}
                    enableGridX={false}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: chartYScale,
                    }}
                    curve="linear"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={null}
                    pointSize={10}
                    pointLabelYOffset={-12}
                />
                <MetricPerformanceGrid
                    metricName={metricName}
                    highlighted={highlighted}
                    data={preparedData}
                />
            </Box>
        </MainBox>
    );
};

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
