import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GoogleAnalyticsConnection } from '../../../components/Connections/GoogleAnalyticsConnection';
import { LoadingStatus } from '../../../constants';
import { useSeinoStore } from '../../../store/seino-store';

export const ConfigureWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem auto;
`;

export const InfoBox = styled(Box)`
    margin-top: 3rem;
`;

export const ConfigureGA = () => {
    const {
        workspaceManagement: {
            dataSources,
            removeGAConnection,
            workspace,
            configureGoogle,
        },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    const { enqueueSnackbar } = useSnackbar();

    const [
        confirmDeleteDialogOpen,
        setConfirmDeleteDialogOpen,
    ] = useState<boolean>(false);

    useEffect(() => {
        if (!currentWorkspaceId) return;
        dataSources.fetch({ workspaceId: currentWorkspaceId });
    }, [dataSources.fetch, currentWorkspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

    const onConnect = async () => {
        await configureGoogle.fetch({
            workspaceId: currentWorkspaceId,
            options: {
                authType: 'refresh_token',
            },
        });

        await dataSources.fetch({ workspaceId: currentWorkspaceId });
    };

    const onDisconnect = async () => {
        try {
            await removeGAConnection.fetch({ workspaceId: currentWorkspaceId });
            await dataSources.fetch({ workspaceId: currentWorkspaceId });
            await workspace.fetch({ workspaceId: currentWorkspaceId });
        } catch (err) {
            enqueueSnackbar('Removing Google Analytics connection failed.', {
                variant: 'error',
            });
            captureException(err);
        } finally {
            setConfirmDeleteDialogOpen(false);
        }
    };

    const isLoading =
        dataSources.status === LoadingStatus.LOADING ||
        configureGoogle.status === LoadingStatus.LOADING ||
        removeGAConnection.status === LoadingStatus.LOADING;

    return (
        <>
            <GoogleAnalyticsConnection
                isConnected={
                    dataSources.result?.hasAnalyticsProviderConnection || false
                }
                onConnect={() => onConnect()}
                onDisconnect={() => setConfirmDeleteDialogOpen(true)}
                loading={isLoading}
            />
            <Dialog
                open={confirmDeleteDialogOpen}
                onClose={() => setConfirmDeleteDialogOpen(false)}
            >
                <DialogContent>
                    Are you sure you want to remove this Google Analytics
                    connection?
                </DialogContent>

                <DialogActions>
                    <Button
                        disabled={isLoading}
                        onClick={() => setConfirmDeleteDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isLoading}
                        color="error"
                        onClick={() => onDisconnect()}
                    >
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
