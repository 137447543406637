import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../../components/Form/Button';
import { useSeinoAuth } from '../../../../hooks/use-seino-auth';
import { useSeinoStore } from '../../../../store/seino-store';

export const InfoBox = styled(Box)`
    margin-top: 3rem;
`;
export const SelectWrapper = styled.div`
    overflow-wrap: break-word;
`;

type AUTH_TYPE = 'refresh_token' | 'service_account';

export const Setup = () => {
    const [authType, setAuthType] = useState<AUTH_TYPE>('refresh_token');

    const [serviceAccount, setServiceAccount] = useState('');
    const [serviceAccountHasError, setServiceAccountHasError] = useState(false);
    const {
        dataSource: {
            connectGoogleAnalytics,
            errors: { connectGoogleAnalytics: connectGAError },
        },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    const { fetchToken } = useSeinoAuth();

    const navigate = useNavigate();

    const onSubmit = async () => {
        if (authType === 'service_account' && serviceAccount.length < 1) {
            setServiceAccountHasError(true);
            return;
        }

        setServiceAccountHasError(false);

        connectGoogleAnalytics(await fetchToken(), currentWorkspaceId, {
            authType,
            serviceAccount,
        });
    };

    const continueText =
        authType === 'refresh_token' ? 'Continue to consent' : 'Continue';

    return (
        <Box>
            <Box my={4}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                        Please select the type of authentication you would like
                        to use.
                    </FormLabel>

                    <Box my={1}>
                        <RadioGroup
                            aria-label="authentication"
                            name="type_of_authentication"
                            value={authType}
                            onChange={evt =>
                                setAuthType(
                                    (evt?.target.value as AUTH_TYPE) ||
                                        'refresh_token'
                                )
                            }
                        >
                            <FormControlLabel
                                value="refresh_token"
                                control={<Radio />}
                                label="Refresh token"
                            />
                            <FormControlLabel
                                value="service_account"
                                control={<Radio />}
                                label="Service account (Recommended: Requires GA service account json file)"
                            />
                        </RadioGroup>
                    </Box>
                </FormControl>

                {authType === 'service_account' && (
                    <TextField
                        error={!!connectGAError || serviceAccountHasError}
                        fullWidth
                        rows={8}
                        required={authType === 'service_account'}
                        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                            setServiceAccount(evt.target.value)
                        }
                        multiline={true}
                        helperText="Invalid service account supplied"
                        placeholder="Paste the content of the service account json file"
                    />
                )}
            </Box>

            <Box my={4}>
                <Divider />
            </Box>

            <Grid my={4} container justifyContent="space-between" spacing={2}>
                <Grid item>
                    <Button
                        startIcon={<ArrowBack />}
                        onClick={() => {
                            navigate('/config/data-sources');
                        }}
                        variant="text"
                        color="primary"
                        title="Go back"
                    />
                </Grid>

                <Grid item>
                    <Button
                        variant="contained"
                        title={continueText}
                        onClick={onSubmit}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
