import axios from 'axios';
import { StoredView } from '../../components/ExplorerGrid/grid/utils/gridView';
import { getAPIUrl } from '../../utils/api';
import { FetchWorkspaceProps } from '../workspace';

export const fetchViews = async ({ workspaceId }: FetchWorkspaceProps) => {
    return axios.get(`${getAPIUrl()}/workspaces/${workspaceId}/grid-views`);
};

export interface SaveViewProps extends FetchWorkspaceProps {
    view: StoredView;
}

export const saveView = async ({ workspaceId, view }: SaveViewProps) => {
    return axios.post(
        `${getAPIUrl()}/workspaces/${workspaceId}/grid-views/${
            view.viewId
        }/save`,
        { viewId: view.viewId, config: JSON.stringify(view) }
    );
};

export interface DeleteViewProps extends FetchWorkspaceProps {
    viewId: string;
}

export const deleteView = async ({ workspaceId, viewId }: DeleteViewProps) => {
    return axios.delete(
        `${getAPIUrl()}/workspaces/${workspaceId}/grid-views/${viewId}`
    );
};
