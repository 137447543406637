import { Skeleton } from '@material-ui/core';
import { MenuItem, Select } from '@mui/material';
import { useState } from 'react';

interface Account {
    id: string;
    value: string;
}

export interface AccountSelectorProps {
    onChange: (id: string) => void;
    accounts: Account[];
    selectedAccountId: string | undefined;
}

export const AccountSelector = ({
    onChange,
    accounts,
    selectedAccountId,
}: AccountSelectorProps) => {
    const [value, setValue] = useState<string>(selectedAccountId || '');

    const onSelectChange = (newValue: string) => {
        setValue(newValue);
        onChange(newValue);
    };

    if (accounts.length === 0) {
        return <Skeleton height="40px" width="150px" />;
    }

    return (
        <Select
            displayEmpty
            variant="standard"
            id="switch-account"
            value={value}
            onChange={event => onSelectChange(event.target.value)}
        >
            <MenuItem key={0} disabled value="">
                <em>Switch account...</em>
            </MenuItem>

            {accounts.map(account => (
                <MenuItem key={account.id} value={account.id}>
                    {account.value}
                </MenuItem>
            ))}
        </Select>
    );
};
