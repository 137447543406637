import {
    Box,
    CircularProgress,
    Collapse,
    IconButton,
    Paper,
    Typography,
} from '@material-ui/core';
import { Close, Settings } from '@material-ui/icons';
import { differenceInCalendarDays, format, toDate } from 'date-fns';
import locale from 'date-fns/locale/en-US';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DateRangePicker } from '../../components/DateRangePicker/DateRangePicker';
import { useSeinoStore } from '../../store/seino-store';
import { MetricChartDataWrapper } from './MetricChartDataWrapper';
import { MetricChartGroup } from './MetricChartGroup';
import {
    CONVERTED,
    DELIVERY,
    ENGAGED,
    MetricsSettings,
    OPEN,
    REACHED,
} from './MetricsSettings';

const PageWrapper = styled.div`
    max-width: 90%;
    margin: auto;
`;

const StyledPaper = styled(Paper)`
    margin-bottom: 40px;
    padding: 18px;
`;

const Wrapper = styled.div`
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const WithDivider = styled(Box)`
    display: flex;
    align-items: center;
`;

export const Metrics = () => {
    const {
        views: { firstDayOfWeek },
        user: { currentWorkspaceId },
        dashboard: {
            dashboardData,
            dateRange: { startDate, endDate },
            setDateRange,
        },
    } = useSeinoStore();

    const [showSettings, setShowSettings] = useState(false);
    const [enabledMetrics, setEnabledMetrics] = useState<string[]>([]);
    const [selectedMetric, setSelectedMetric] = useState('');

    useEffect(() => {
        if (!currentWorkspaceId || !dashboardData.fetch) return;
        dashboardData.fetch({
            dateRange: { startDate, endDate },
            workspaceId: currentWorkspaceId,
        });
    }, [currentWorkspaceId, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!dashboardData.result) {
        return (
            <PageWrapper>
                <CircularProgress />
            </PageWrapper>
        );
    }

    const dashboardDateFormat = 'iii do, LLLL';

    return (
        <>
            <Box margin={1} display="flex" justifyContent="right">
                <Paper>
                    <DateRangePicker
                        locale={locale}
                        weekStartsOn={firstDayOfWeek}
                        dateRange={{ start: startDate, end: endDate }}
                        onChange={interval => {
                            setDateRange(
                                toDate(interval.start),
                                toDate(interval.end)
                            );
                        }}
                    />
                </Paper>
            </Box>
            <PageWrapper>
                <Box>
                    <StyledPaper elevation={5}>
                        <Box
                            display="flex"
                            alignItems="start"
                            justifyContent="space-between"
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#60C2D5',
                                    }}
                                    variant="h6"
                                    component={'h2'}
                                >
                                    {`Performance Overview for ${
                                        differenceInCalendarDays(
                                            endDate,
                                            startDate
                                        ) + 1
                                    } days`}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        color: '#60C2D5',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {`(${format(
                                        startDate,
                                        dashboardDateFormat
                                    )} - ${format(
                                        endDate,
                                        dashboardDateFormat
                                    )})`}
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    onClick={() => {
                                        setShowSettings(!showSettings);
                                    }}
                                >
                                    {showSettings ? <Close /> : <Settings />}
                                </IconButton>
                            </Box>
                        </Box>
                        <Collapse in={showSettings} collapsedSize={0}>
                            <MetricsSettings
                                initialSelection={[
                                    'metric_email_sent_unique_sum',
                                    'metric_email_deliverability_rate',
                                    'metric_email_open_unique_rate',
                                    'metric_email_clicks_unique_rate',
                                    'metric_email_clicks_to_open_unique_rate',
                                    'metric_website_revenue_sum',
                                ]}
                                onChanged={setEnabledMetrics}
                            />
                        </Collapse>
                        <Wrapper>
                            <WithDivider>
                                <MetricChartGroup
                                    name="Reached"
                                    data={dashboardData.result}
                                    group={REACHED}
                                    selectedMetric={selectedMetric}
                                    setSelected={setSelectedMetric}
                                    enabledMetrics={enabledMetrics}
                                />
                                <MetricChartGroup
                                    name="Delivered"
                                    data={dashboardData.result}
                                    group={DELIVERY}
                                    selectedMetric={selectedMetric}
                                    setSelected={setSelectedMetric}
                                    enabledMetrics={enabledMetrics}
                                />
                                <MetricChartGroup
                                    name="Opened"
                                    data={dashboardData.result}
                                    group={OPEN}
                                    selectedMetric={selectedMetric}
                                    setSelected={setSelectedMetric}
                                    enabledMetrics={enabledMetrics}
                                />
                                <MetricChartGroup
                                    name="Engaged"
                                    data={dashboardData.result}
                                    group={ENGAGED}
                                    selectedMetric={selectedMetric}
                                    setSelected={setSelectedMetric}
                                    enabledMetrics={enabledMetrics}
                                />
                                <MetricChartGroup
                                    name="Converted"
                                    data={dashboardData.result}
                                    group={CONVERTED}
                                    selectedMetric={selectedMetric}
                                    setSelected={setSelectedMetric}
                                    enabledMetrics={enabledMetrics}
                                />
                            </WithDivider>
                        </Wrapper>
                    </StyledPaper>
                </Box>
                <Box>
                    {selectedMetric && (
                        <StyledPaper elevation={5}>
                            <Collapse
                                in={selectedMetric !== ''}
                                collapsedSize={0}
                            >
                                <Box
                                    display="flex"
                                    alignItems="start"
                                    justifyContent="space-between"
                                    minHeight="500px"
                                >
                                    <MetricChartDataWrapper
                                        detailView={true}
                                        dateRange={{ startDate, endDate }}
                                        metricName={selectedMetric}
                                        onClick={setSelectedMetric}
                                    />
                                    <IconButton
                                        onClick={() => setSelectedMetric('')}
                                    >
                                        <Close />
                                    </IconButton>
                                </Box>
                            </Collapse>
                        </StyledPaper>
                    )}
                </Box>
            </PageWrapper>
        </>
    );
};
