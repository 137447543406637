import { TextField } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Rule } from '../../../store/data-source';
import { countries } from '../../../utils/countries';
import { Button } from '../Button';
import { Select } from '../Select';
import { ConditionOptions, FieldOptions, MarketOption } from './helpers';
import { RuleRow } from './RuleBuilder.styles';
import { createGroupedGAViewOptions } from './utils';

export interface GAView {
    viewId: string;
    propertyId: string;
    propertyName: string;
    viewName: string;
}

export interface CreateRuleRowProps {
    customerGAViews: GAView[];
    onAddRule: (rule: Rule) => void;
}

export const CreateRuleRow = ({
    customerGAViews,
    onAddRule,
}: CreateRuleRowProps) => {
    const [state, setState] = useState<Rule>({});

    const updateRule = useCallback(
        (ruleName: string, value: string) => {
            setState({
                ...state,
                [ruleName]: value,
            });
        },
        [state]
    );

    const updateValue = (evt: any) => {
        updateRule('value', evt.target.value);
    };

    return (
        <RuleRow>
            <Select
                testId="select-field"
                size="small"
                minWidth={undefined}
                onChange={(evt: any) => {
                    updateRule('field', evt.value);
                }}
                options={FieldOptions}
            />
            <Select
                testId="select-condition"
                size="small"
                onChange={(evt: any) => {
                    updateRule('condition', evt.value);
                }}
                options={ConditionOptions}
            />
            <TextField
                inputProps={{ 'data-testid': 'input-value' }}
                size="small"
                variant="standard"
                onInput={updateValue}
            />
            <Select
                testId="select-market"
                size="small"
                onChange={(evt: any) => {
                    updateRule('market', evt.value);
                }}
                components={{
                    Option: MarketOption,
                }}
                options={countries}
            />
            <Select
                testId="select-ga-view"
                size="small"
                onChange={(evt: any) => {
                    const [, viewId] = evt.value.split('---');
                    updateRule('view_id', viewId);
                }}
                options={createGroupedGAViewOptions(customerGAViews, true)}
            />
            <Button
                color="primary"
                size="medium"
                disabled={Object.keys(state || {}).length < 5}
                title="Add Rule"
                onClick={() => {
                    onAddRule(state as Rule);
                    setState({});
                }}
            />
        </RuleRow>
    );
};
