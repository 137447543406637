import { Box, Button, Typography } from '@mui/material';
import styled from 'styled-components';
import { ColorNames } from '../../utils/styles';

export const CALL_TO_ACTION_HEIGHT = 48;

export const MessageWrapper = styled(Box)`
    min-height: ${CALL_TO_ACTION_HEIGHT}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    background-color: ${ColorNames.BACKGROUND_GREY};
`;

export const TextWrapper = styled(Typography)`
    text-align: left;
    color: black !important;
`;
export const CallButton = styled(Button)`
    white-space: nowrap;
    min-width: max-content;
`;
