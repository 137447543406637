import { Column, ColumnState } from 'ag-grid-community';

export const addMissingColumns = (
    columnStates: ColumnState[],
    columnDefs?: Column[] | null
) => {
    if (!columnDefs) {
        return columnStates;
    }

    const columnIds = columnStates.map(c => c.colId);

    columnDefs.forEach((col, i) => {
        if (columnIds.includes(col.getColId())) {
            return;
        }

        // Insert column after preceding column from definition, as long as it is not the first
        if (i > 0) {
            const index = columnStates.findIndex(
                c => c.colId === columnDefs[i - 1].getColId()
            );

            columnStates.splice(index + 1, 0, {
                colId: col.getColId(),
                width: col.getActualWidth(),
            });
        }
        // Insert column before succeeding column from definition
        else {
            const index = columnStates.findIndex(
                c => c.colId === columnDefs[i + 1].getColId()
            );

            columnStates.splice(index, 0, {
                colId: col.getColId(),
                width: col.getActualWidth(),
            });
        }
    });

    return columnStates;
};
