import { ValueFormatterParams } from 'ag-grid-community';
import { format } from 'date-fns';

export const dateFormatter = (dateFormat: string) => (
    params: ValueFormatterParams
) => {
    if (!params.value) return params.value;
    const dateToFormat = new Date(params.value.split(' ')[0]);

    if (!dateToFormat) return params;

    return format(dateToFormat, dateFormat);
};
