import { Box } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Close, Save } from '@mui/icons-material';
import { TextField, Typography } from '@mui/material';
import { DetailGridInfo } from 'ag-grid-community';
import React, {
    memo,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { StoredView } from '../../../components/ExplorerGrid/grid/utils/gridView';
import { GRID_VIEW_VERSION, LoadingStatus } from '../../../constants';
import { GridContext as ExplorerGridContext } from '../../../pages/Explorer/GridContext';
import { useSeinoStore } from '../../../store/seino-store';

const Wrapper = styled.div`
    padding: 0 16px;
    min-width: 566px;
    margin-top: 39px;
`;

export interface SaveViewPanelProps {
    mode: 'duplicate' | 'rename';
    onClose: () => void;
}

const SaveViewPanel = ({ mode, onClose }: SaveViewPanelProps) => {
    const [viewName, setViewName] = useState('');

    const {
        views: {
            saveView,
            selectedViewKey,
            setSelectedViewKey,
            deleteView,
            views,
            currentView,
        },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    const { grid: explorerGrid } = useContext(ExplorerGridContext);
    const [grid, setGrid] = useState<DetailGridInfo | null>(null);

    useEffect(() => {
        setGrid(explorerGrid);
    }, [explorerGrid]);

    useEffect(() => {
        setViewName(currentView.title);
    }, [currentView]);

    const save = useCallback(async () => {
        const configObj: StoredView = {
            ...currentView,
            viewId: mode === 'rename' ? selectedViewKey : uuidv4(),
            title: viewName,
            version: GRID_VIEW_VERSION,
            modified: Date.now(),
            view: grid?.columnApi?.getColumnState()!,
            pivotMode: grid?.columnApi?.isPivotMode() || false,
            filterModel: grid?.api?.getFilterModel() || {},
            created: currentView.created,
        };

        await saveView.fetch({
            view: configObj,
            workspaceId: currentWorkspaceId,
        });

        await views.fetch({ workspaceId: currentWorkspaceId });

        setSelectedViewKey(configObj.viewId);

        setTimeout(onClose, 700);
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [
        saveView,
        onClose,
        currentView,
        selectedViewKey,
        currentWorkspaceId,
        grid,
        mode,
        viewName,
    ]);
    /* eslint-enable react-hooks/exhaustive-deps */

    const title = mode === 'rename' ? 'Rename View' : 'Save as new view';
    const buttonTitle = mode === 'rename' ? 'Rename View' : 'Save new view';

    return (
        <Wrapper>
            <Typography variant="h5">{title}</Typography>
            <Box mt={0.2} my={2} display="flex">
                <TextField
                    style={{ width: '100%' }}
                    size="medium"
                    variant="standard"
                    inputProps={{ 'data-testid': 'view-name-input' }}
                    onInput={(evt: any) => setViewName(evt.target.value)}
                    value={viewName}
                />
            </Box>
            <Box my={4} display="flex" justifyContent="space-between">
                <LoadingButton
                    disableElevation={true}
                    loading={deleteView.status === LoadingStatus.LOADING}
                    startIcon={<Close />}
                    size="small"
                    onClick={onClose}
                    variant="outlined"
                    color="primary"
                    title="Cancel"
                >
                    Cancel
                </LoadingButton>
                <LoadingButton
                    disableElevation={true}
                    loading={
                        saveView.status === LoadingStatus.LOADING ||
                        views.status === LoadingStatus.LOADING
                    }
                    size="small"
                    color="info"
                    endIcon={<Save />}
                    data-testid="save-view-button"
                    onClick={save}
                    variant="contained"
                    title="Save"
                >
                    {buttonTitle}
                </LoadingButton>
            </Box>
        </Wrapper>
    );
};

const Component = memo(SaveViewPanel);
export { Component as SaveViewPanel };
