import { debounce, find } from 'lodash';
import { GetState, SetState, StateCreator } from 'zustand';
import {
    deleteView as deleteViewApiCall,
    DeleteViewProps,
    fetchViews,
    FetchWorkspaceProps,
    saveView as saveViewApiCall,
    SaveViewProps,
} from '../../api-client';
import { StoredView } from '../../components/ExplorerGrid/grid/utils/gridView';
import { broadcastStandard } from '../../utils/helpers';
import { createFetchState, FetchState } from '../helpers';
import { mapResponseToAllViews } from './utils';

export interface ViewStore {
    selectedViewKey: string;
    currentView: StoredView;
    setSelectedViewKey: (id: string) => void;
    views: FetchState<FetchWorkspaceProps, StoredView[]>;
    deleteView: FetchState<DeleteViewProps, null>;
    saveView: FetchState<SaveViewProps, null>;
    autosave: (view: StoredView, workspaceId: string) => void;
    dashboardMessage: string;
    firstDayOfWeek: Day;
    setFirstDayOfWeek: (dayIndex: Day) => void;
}

const initialCurrentView: StoredView = {
    title: 'Broadcast',
    version: '1.0',
    created: Date.now(),
    viewId: 'initialView',
    kpiData: {},
    modified: Date.now(),
    view: broadcastStandard,
    pivotMode: false,
    filterModel: {},
};

const initialFirstDayOfTheWeek = parseInt(
    window.localStorage.getItem('first_day_of_week') || '0'
);

const initialViewKey =
    window.localStorage.getItem('selectedViewKey') || 'initialView';

export const viewStore: StateCreator<ViewStore> = (
    set: SetState<ViewStore>,
    get: GetState<ViewStore>
) => ({
    dashboardMessage: '',
    firstDayOfWeek:
        initialFirstDayOfTheWeek >= 0 && initialFirstDayOfTheWeek <= 6
            ? (initialFirstDayOfTheWeek as Day)
            : 0,
    setFirstDayOfWeek: (dayIndex: Day) => {
        set({ firstDayOfWeek: dayIndex });
    },
    selectedViewKey: initialViewKey,
    currentView: initialCurrentView,
    setSelectedViewKey: (selectedViewKey: string) => {
        const loadedViews = get().views.result;
        if (!loadedViews || !loadedViews.length) return;
        const defaultView = loadedViews[0];
        const selected = find(get().views.result, ['viewId', selectedViewKey]);
        const currentView = selected || defaultView;
        if (!currentView) return;

        set({
            selectedViewKey,
            currentView,
        });
        window.localStorage.setItem('selectedViewKey', selectedViewKey);
    },
    deleteView: createFetchState<DeleteViewProps, null>(
        'deleteView',
        deleteViewApiCall
    )(set, get),
    views: createFetchState<FetchWorkspaceProps, StoredView[]>(
        'views',
        fetchViews,
        { processData: mapResponseToAllViews }
    )(set, get),
    saveView: createFetchState<SaveViewProps, null>(
        'saveView',
        saveViewApiCall
    )(set, get),
    autosave: debounce((view: StoredView, workspaceId: string) => {
        get().saveView.fetch({ view, workspaceId });
        get().currentView.modified = Date.now();
    }, 1000),
});
