import axios from 'axios';
import { format } from 'date-fns';
import { BACKEND_DATE_FORMAT } from '../../constants';
import { getAPIUrl } from '../../utils/api';
import { DateRange } from '../dashboard';

export interface FetchGridDataProps {
    workspaceId: string;
    dateRange: DateRange;
}

export const fetchGridData = ({
    workspaceId,
    dateRange,
}: FetchGridDataProps) => {
    const { startDate, endDate } = dateRange;

    return axios.get(`${getAPIUrl()}/workspaces/${workspaceId}/data-explorer`, {
        params: {
            startDate: format(startDate, BACKEND_DATE_FORMAT),
            endDate: format(endDate, BACKEND_DATE_FORMAT),
        },
    });
};
