import { Skeleton, Typography } from '@material-ui/core';
import { CheckOutlined, Error } from '@mui/icons-material';
import {
    Chip,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import styled from 'styled-components';
import { Workspace } from '../../store/account-management';

const StyledTableRow = styled(TableRow)({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&.MuiTableRow-hover:hover': {
        cursor: 'pointer',
    },
});

export interface WorkspaceListProps {
    workspaces: Workspace[];
    onSelect: (id: string) => void;
    loading: boolean;
}

const ListItemStatus = ({
    label,
    status = undefined,
}: {
    label: string;
    status?: boolean | undefined;
}) => {
    if (status === undefined) {
        return (
            <Chip
                variant="outlined"
                color="default"
                icon={<CheckOutlined />}
                label={label}
            />
        );
    }

    return status ? (
        <Chip variant="outlined" icon={<CheckOutlined />} label={label} />
    ) : (
        <Chip variant="outlined" color="error" icon={<Error />} label={label} />
    );
};

const LoadingListItem = () => (
    <TableBody>
        <StyledTableRow hover>
            <TableCell width="400px" component="th" scope="row">
                <Typography variant="body1">
                    <Skeleton variant="text" width={210} />
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Chip label="-" color="default" />
            </TableCell>
            <TableCell align="right">
                <Stack direction="row" justifyContent="center" gap={2}>
                    <Stack direction="row" gap={1}>
                        <ListItemStatus label="ESP" />
                    </Stack>
                    <Stack direction="row" gap={1}>
                        <ListItemStatus label="GA" />
                    </Stack>

                    <Stack direction="row" gap={1}>
                        <ListItemStatus label="Mapping" />
                    </Stack>
                </Stack>
            </TableCell>
        </StyledTableRow>
    </TableBody>
);

const ListItem = ({
    workspace,
    onSelect,
}: {
    workspace: Workspace;
    onSelect: (id: string) => void;
}) => (
    <StyledTableRow
        key={workspace.id}
        hover
        onClick={() => onSelect(workspace.id)}
    >
        <TableCell width="400px" component="th" scope="row">
            <Typography variant="body1">{workspace.name}</Typography>
        </TableCell>
        <TableCell align="left">
            <Chip label="-" color="success" />
        </TableCell>
        <TableCell align="right">
            <Stack direction="row" justifyContent="center" gap={2}>
                <Stack direction="row" gap={1}>
                    <ListItemStatus
                        label="ESP"
                        status={
                            workspace.dataSources.hasEmailProviderConnection
                        }
                    />
                </Stack>
                <Stack direction="row" gap={1}>
                    <ListItemStatus
                        label="GA"
                        status={
                            workspace.dataSources.hasAnalyticsProviderConnection
                        }
                    />
                </Stack>

                <Stack direction="row" gap={1}>
                    <ListItemStatus
                        label="Mapping"
                        status={
                            workspace.dataSources.hasConfiguredAggregationRules
                        }
                    />
                </Stack>
            </Stack>
        </TableCell>
    </StyledTableRow>
);

const List = ({
    workspaces,
    onWorkspaceSelect,
}: {
    workspaces: Workspace[];
    onWorkspaceSelect: (workspaceId: string) => void;
}) => (
    <TableBody>
        {workspaces?.map(workspace => (
            <ListItem
                key={workspace.id}
                workspace={workspace}
                onSelect={onWorkspaceSelect}
            />
        ))}
    </TableBody>
);

export const WorkspaceList = ({
    loading,
    workspaces,
    onSelect,
}: WorkspaceListProps) => (
    <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="Table of workspaces">
            <TableHead>
                <TableRow>
                    <TableCell>Workspace</TableCell>
                    <TableCell>Plan</TableCell>
                    <TableCell colSpan={3} align="center">
                        Configuration
                    </TableCell>
                </TableRow>
            </TableHead>

            {loading ? (
                <LoadingListItem />
            ) : (
                <List
                    workspaces={workspaces}
                    onWorkspaceSelect={id => onSelect(id)}
                />
            )}
        </Table>
    </TableContainer>
);
