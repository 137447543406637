import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import {
    Item,
    MemberList,
    Role,
} from '../../../../components/MemberList/MemberList';
import { Invitation, Member } from '../../../../store/account-management';

export interface MemberManagementProps {
    loading: boolean;
    members: Member[];
    invitations: Invitation[];
    deleteMember: (id: string) => void;
    deleteInvitation: (token: string) => void;
    onChangeMemberRole: (id: string, role: Role) => void;
}

export const MemberManagement = ({
    loading,
    members,
    invitations,
    deleteMember,
    deleteInvitation,
    onChangeMemberRole,
}: MemberManagementProps) => {
    const [subjectToRemove, setSubjectToRemove] = useState<Item | undefined>(
        undefined
    );

    const handleClose = () => setSubjectToRemove(undefined);

    const handleConfirm = () => {
        if (subjectToRemove !== undefined) {
            if (subjectToRemove.type === 'member') {
                deleteMember(subjectToRemove.id);
            } else if (subjectToRemove.type === 'invitation') {
                deleteInvitation(subjectToRemove.id);
            }
        }
        setSubjectToRemove(undefined);
        handleClose();
    };

    return (
        <>
            <Dialog open={subjectToRemove !== undefined} onClose={handleClose}>
                <Box style={{ minWidth: '600px' }}>
                    <DialogTitle>Remove permission</DialogTitle>
                    <DialogContent>
                        Are you sure you want to withdraw the permission of{' '}
                        {subjectToRemove?.email}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>Cancel</Button>
                        <Button
                            onClick={handleConfirm}
                            variant="contained"
                            color="info"
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <Box>
                <MemberList
                    loading={loading}
                    onRemove={(item: Item) => setSubjectToRemove(item)}
                    onChangeMemberRole={(item: Item, role: Role) =>
                        onChangeMemberRole(item.id, role)
                    }
                    items={[
                        ...members.map(
                            member =>
                                ({
                                    id: member.id,
                                    name: member.name,
                                    email: member.email,
                                    role: member.role,
                                    type: 'member',
                                    isCurrentUser: member.isCurrentUser,
                                } as Item)
                        ),
                        ...invitations.map(
                            invitation =>
                                ({
                                    id: invitation.token,
                                    name: invitation.name,
                                    email: invitation.email,
                                    role: invitation.role,
                                    type: 'invitation',
                                    isCurrentUser: false,
                                } as Item)
                        ),
                    ].sort((a, b) => (a.name > b.name ? 1 : -1))}
                />
            </Box>
        </>
    );
};
