import React from 'react';
import { useCallback, useState } from 'react';
import { IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export interface ShareProps {
    message: string;
}

export const SnackbarMessage = ({ message }: ShareProps) => {
    const [open, setOpen] = useState(true);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={message}
            action={
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                >
                    <Close fontSize="small" />
                </IconButton>
            }
        />
    );
};
