export enum ColorNames {
    LIGHT_BLUE = '#CAE0EB',
    SECONDARY_STROKE = '#CFE3ED',
    BLUE_PRIMARY = '#3699FF',
    BACKGROUND_GREY = '#f9f9f9',
    BACKGROUND_GREY_DARK = '#f4f4f4',
    GREY_DIVIDER = '#e0e0e0',
    GREY_DISABLED = '#BCCBD2',
    TEXT_COLOR = '#455A64',
    WHITE = '#ffffff',
    BLACK = '#000000',
    GRAY_HOVER = '#e7e7e7',
    TEXT_GRAY = '#9e9e9e',

    PRIMARY_COLOR = '#009DBB',
    PRIMARY_LIGHT_COLOR = '#5aceed',
    PRIMARY_DARK_COLOR = '#006e8b',

    SECONDARY_COLOR = '#002f39',
    SECONDARY_LIGHT_COLOR = '#315863',
    SECONDARY_DARK_COLOR = '#000613',
    PRIMARY_TEXT_COLOR = '#000000',
    SECONDARY_TEXT_COLOR = '#000000',

    SUCCESS_COLOR = '#00BB77',
    SUCCESS_LIGHT_COLOR = '#53D3A7',
    SUCCESS_DARK_COLOR = '#007A4E',

    DANGER_COLOR = '#DA5563',
    DANGER_LIGHT_COLOR = '#E88F98',
    DANGER_DARK_COLOR = '#8E363F',

    WARNING_COLOR = '#D59B44',
    WARNING_LIGHT_COLOR = '#E4BE87',
    WARNING_DARK_COLOR = '#8B642C',

    INFO_COLOR = '#2196F3',
}
