import { subDays } from 'date-fns';
import { GetState, SetState, StateCreator } from 'zustand';
import {
    DateRange,
    fetchDashboardData,
    FetchDashboardDataProps,
} from '../../api-client';
import { createFetchState, FetchState } from '../helpers';

export interface DashboardStore {
    dateRange: DateRange;
    dashboardData: FetchState<FetchDashboardDataProps, any>;
    setDateRange: (startDate?: Date | null, endDate?: Date | null) => void;
}

export interface DashboardDayInfo {
    date: string;
    metric_email_campaigns_unique: number;
    metric_email_sent_unique_sum: number;
    metric_email_deliverability_rate: number;
    metric_email_open_unique_rate: number;
    metric_email_clicks_to_open_unique_rate: number;
    metric_email_unsubscribe_rate: number;
    metric_website_transactions_sum: number;
    metric_website_average_order_value: number;
    metric_website_revenue_sum: number;
}

export type DashboardDataResult = DashboardDayInfo[];

export const dashboardStore: StateCreator<DashboardStore> = (
    set: SetState<DashboardStore>,
    get: GetState<DashboardStore>
) => ({
    dashboardData: createFetchState<
        FetchDashboardDataProps,
        DashboardDataResult
    >('dashboardData', fetchDashboardData)(set, get),
    dateRange: { startDate: subDays(new Date(), 6), endDate: new Date() },
    setDateRange: (startDate, endDate) => {
        const newStartDate = startDate || get().dateRange.startDate;
        const newEndDate = endDate || get().dateRange.endDate;

        set({
            dateRange: { startDate: newStartDate, endDate: newEndDate },
        });
    },
});
