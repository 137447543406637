import { Box } from '@material-ui/core';
import { Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GrantWorkspacePermission } from '../../../components/GrantWorkspacePermission/GrantWorkspacePermission';
import { PermissionManagement } from '../../../components/PermissionManagement/PermissionManagement';
import { LoadingStatus } from '../../../constants';
import { useSeinoStore } from '../../../store/seino-store';
import { Permission } from '../../../store/workspace-management';

interface TabPermissionProps {
    workspaceId: string;
    linkToAccountMembersPage?: string;
}

export const TabPermission = ({
    workspaceId,
    linkToAccountMembersPage,
}: TabPermissionProps) => {
    const {
        workspaceManagement: {
            availableMembers,
            grantMember,
            withdrawPermission,
            withdrawInvitation,
            aggregationRules,
            dataSources,
            permissions,
        },
    } = useSeinoStore();

    useEffect(() => {
        availableMembers.fetch({ workspaceId });
        aggregationRules.fetch({ workspaceId });
        permissions.fetch({ workspaceId });
        dataSources.fetch({ workspaceId });
    }, [workspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

    const deleteMemberOnClick = async (
        memberId: string,
        workspaceId: string
    ) => {
        await withdrawPermission.fetch({ workspaceId, memberId });
        availableMembers.fetch({ workspaceId });
        permissions.fetch({ workspaceId });
    };

    const onWithdrawPermission = (permission: Permission) => {
        if (permission.type === 'member') {
            deleteMemberOnClick(permission.id, workspaceId);
        } else if (permission.type === 'invitation') {
            deleteInvitationOnClick(permission.id, workspaceId);
        }
    };

    const deleteInvitationOnClick = async (
        token: string,
        workspaceId: string
    ) => {
        await withdrawInvitation.fetch({ workspaceId, token });
        availableMembers.fetch({ workspaceId });
        permissions.fetch({ workspaceId });
    };

    const onPermissionGranted = async (memberId: string) => {
        await grantMember.fetch({ workspaceId: workspaceId, memberId });
        availableMembers.fetch({ workspaceId });
        permissions.fetch({ workspaceId });
    };

    return (
        <Stack maxWidth={'xl'} spacing={4}>
            <Box>
                <Typography gutterBottom={true} variant="h6" component={'h2'}>
                    Add member
                </Typography>

                <Typography variant="body2">
                    Select a member to give access to this workspace.
                </Typography>
                <Typography
                    paragraph={true}
                    gutterBottom={true}
                    variant="body2"
                >
                    Want to add someone who isn't an account member yet? Go to
                    {linkToAccountMembersPage ? (
                        <Link
                            style={{ marginInline: '.3rem' }}
                            to={linkToAccountMembersPage}
                        >
                            manage account members
                        </Link>
                    ) : (
                        'manage account members'
                    )}
                    to invite them.
                </Typography>

                <GrantWorkspacePermission
                    members={availableMembers.result?.members || []}
                    grantPermission={onPermissionGranted}
                />
            </Box>

            <Box>
                <Typography gutterBottom={true} variant="h6" component={'h2'}>
                    Current permissions
                </Typography>
                <Typography variant="body2">
                    The following account members have permission to access this
                    workspace.
                </Typography>

                <PermissionManagement
                    loading={
                        permissions.status === LoadingStatus.LOADING ||
                        availableMembers.status === LoadingStatus.LOADING ||
                        withdrawPermission.status === LoadingStatus.LOADING ||
                        grantMember.status === LoadingStatus.LOADING
                    }
                    permissions={permissions.result?.permissions || []}
                    withdrawPermission={onWithdrawPermission}
                />
            </Box>
        </Stack>
    );
};
