import { IconButton, Popover, Tooltip } from '@mui/material';
import React, { useRef } from 'react';

export interface ViewActionProps {
    Icon: React.ReactNode;
    id: string;
    tooltip: string;
    open: boolean;
    panel: any;
    onClick: (id: string | null) => void;
}

export const ViewAction = ({
    Icon,
    open,
    id,
    panel,
    tooltip,
    onClick,
}: ViewActionProps) => {
    const popOverRef = useRef();

    return (
        <React.Fragment>
            <Tooltip title={tooltip} ref={popOverRef}>
                <IconButton
                    data-testid={`view-icon-button-${id}`}
                    onClick={() => onClick(id)}
                >
                    {Icon}
                </IconButton>
            </Tooltip>
            <Popover
                style={{ overflow: 'visible', zIndex: 10, marginTop: '14px' }}
                id={id}
                open={open}
                anchorEl={popOverRef.current}
                onClose={() => onClick(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {panel}
            </Popover>
        </React.Fragment>
    );
};
