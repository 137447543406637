import {
    Box,
    CircularProgress,
    Container,
    Divider,
    IconButton,
    Snackbar,
    Typography,
} from '@material-ui/core';
import { Close, FileCopy } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSeinoStore } from '../../../../store/seino-store';
import { ColorNames } from '../../../../utils/styles';

export const ConfigureWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem auto;
`;

export const InfoBox = styled(Box)`
    margin-top: 1rem;
`;

export const ConfigureCanopyDeploy = () => {
    const {
        workspaceManagement: { canopyDeployCredentials, workspace },
        user: { currentWorkspaceId },
    } = useSeinoStore();

    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        if (!currentWorkspaceId) return;
        (async () => {
            await canopyDeployCredentials.fetch({
                workspaceId: currentWorkspaceId,
            });
            workspace.fetch({ workspaceId: currentWorkspaceId });
        })();
    }, [currentWorkspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!canopyDeployCredentials.result) {
        return (
            <ConfigureWrapper>
                <CircularProgress />
            </ConfigureWrapper>
        );
    }

    return (
        <ConfigureWrapper>
            <Container>
                <InfoBox my={2}>
                    <Box my={1}>
                        <Typography my={2}>
                            To setup your CanopyDeploy connection you need to
                            install the PEGA App in the CanopyDeploy Store.
                        </Typography>
                        <Typography my={2}>
                            When you install this app you can use your
                            credentials below to send data from CanopyDeploy to
                            your SEINō account.
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            paragraph={true}
                            variant="overline"
                            mt={4}
                            mb={1}
                        >
                            Canopy Deploy settings
                        </Typography>
                        <Box
                            style={{
                                display: 'grid',
                                alignItems: 'center',
                                gridTemplateColumns: '1fr 3fr 1fr',
                                boxSizing: 'border-box',
                                padding: '1rem',
                                borderRadius: '1rem',
                                rowGap: '1rem',
                                columnGap: '1rem',
                                backgroundColor:
                                    ColorNames.BACKGROUND_GREY_DARK,
                            }}
                        >
                            <Typography variant="body2" fontWeight="bold">
                                URL
                            </Typography>
                            <Typography variant="body2">
                                {canopyDeployCredentials.result?.url}
                            </Typography>
                            <Box display="inline-block">
                                <IconButton
                                    onClick={() => {
                                        if (
                                            canopyDeployCredentials.result?.url
                                        ) {
                                            navigator.clipboard.writeText(
                                                canopyDeployCredentials.result
                                                    ?.url
                                            );
                                            setOpenSnackbar(true);
                                        }
                                    }}
                                >
                                    <FileCopy />
                                </IconButton>
                            </Box>
                            <Typography variant="body2" fontWeight="bold">
                                Username
                            </Typography>

                            <Typography variant="body2">
                                {canopyDeployCredentials.result?.username}
                            </Typography>
                            <Box />
                            <Typography variant="body2" fontWeight="bold">
                                Password
                            </Typography>
                            <Typography variant="body2">
                                <code>
                                    {canopyDeployCredentials.result?.password}
                                </code>
                            </Typography>
                            <Box display="inline-block">
                                <IconButton
                                    onClick={() => {
                                        if (
                                            canopyDeployCredentials.result
                                                ?.password
                                        ) {
                                            navigator.clipboard.writeText(
                                                canopyDeployCredentials.result
                                                    ?.password
                                            );
                                            setOpenSnackbar(true);
                                        }
                                    }}
                                >
                                    <FileCopy />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box my={4}>
                        <Divider />
                    </Box>

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={() => setOpenSnackbar(false)}
                        message="Copied to clipboard!"
                        action={
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => setOpenSnackbar(false)}
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        }
                    />
                </InfoBox>
            </Container>
        </ConfigureWrapper>
    );
};
