// TODO make env variable
import { generateAbsoluteUrl } from './url-generator';

export const getAPIUrl = (): string => {
    const apiUrlFromEnv = process.env.REACT_APP_API_URL || '';

    if (apiUrlFromEnv) {
        return apiUrlFromEnv;
    }

    const API_BASE = 'api.seino';
    const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https';
    const extension =
        process.env.NODE_ENV === 'development' ? 'localhost' : 'ai';

    return `${protocol}://${API_BASE}.${extension}`;
};

export const getRedirectUri = (): string =>
    generateAbsoluteUrl('/config/data-sources/');
