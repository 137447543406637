import { ICellRenderer, ICellRendererParams } from 'ag-grid-community';
import { KPIData } from './gridView';

export class KPINumberRenderer implements ICellRenderer {
    private eGui: HTMLElement;
    constructor() {
        this.eGui = document.createElement('div');
    }

    init(params: ICellRendererParams & { kpiData: KPIData }) {
        this.eGui.innerHTML = this.render(params);
    }

    getGui() {
        return this.eGui;
    }
    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams & { kpiData: KPIData }) {
        this.eGui.innerHTML = this.render(params);
        return true;
    }
    // gets called when the cell is removed from the grid
    destroy() {}

    getPercentageDifference = (kpi: number, value: number) => {
        const diff = Math.abs(((value - kpi) / kpi) * 100).toFixed(2);
        if (!diff) return undefined;
        const percentageDiff = `${diff}%`;
        return percentageDiff;
    };

    render(params: ICellRendererParams & { kpiData: KPIData }): string {
        const { valueFormatted, kpiData, value } = params;

        if (!kpiData || !params.column) return valueFormatted;
        const kpi = kpiData[params.column.getId()];
        if (!kpi) return valueFormatted;

        const valueNum = parseFloat(
            valueFormatted.indexOf('%') > -1 ? valueFormatted : value
        );

        const meetsKPI = valueNum < kpi;
        const diff = this.getPercentageDifference(kpi, valueNum);

        if (!diff) return valueFormatted;

        const color = meetsKPI ? 'red' : 'green';
        const arrow = meetsKPI ? '↓' : '↑';
        const returnValue = `<span style="display: flex;"><span style="width: 50%">${valueFormatted}</span><span style="width: 50%; color: ${color}">${arrow}${diff}</span></span>`;

        return returnValue;
    }
}
