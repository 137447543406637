import {
    Box,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../components/Form/Button';
import { LoadingStatus } from '../../../../constants';
import { useSeinoStore } from '../../../../store/seino-store';

export const ConfigureWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 1rem auto;
`;

export const ConfigureCopernica = () => {
    const [accessToken, setAccessToken] = useState('');
    const [editingEnabled, setEditingEnabled] = useState(false);

    const {
        user: { currentWorkspaceId },
        workspaceManagement: { configureCopernica, workspace },
        dataSource: { hasCopernicaConnection },
    } = useSeinoStore();

    const handleSubmit = useCallback(() => {
        if (!currentWorkspaceId) return;

        const configure = async () => {
            await configureCopernica.fetch({
                token: accessToken,
                workspaceId: currentWorkspaceId,
            });
            workspace.fetch({ workspaceId: currentWorkspaceId });
        };
        configure();
    }, [accessToken]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ConfigureWrapper>
            <Container>
                <Typography style={{ maxWidth: 600 }}>
                    SEINō uses the Copernica REST API to retrieve data about
                    your mailings. In order to access the API, we need an access
                    token. Please create an access token through the{' '}
                    <Link
                        href="https://ms.copernica.com/en#/admin/account/access-tokens"
                        target="_blank"
                    >
                        API access token page
                    </Link>
                    in Copernica and copy it in the field below.
                </Typography>

                <Box mt={3}>
                    <TextField
                        label="Access token"
                        error={configureCopernica.error && !!accessToken}
                        helperText={
                            accessToken &&
                            configureCopernica.error &&
                            'The access token you provided was incorrect.'
                        }
                        disabled={
                            configureCopernica.status === LoadingStatus.LOADING
                        }
                        fullWidth={true}
                        value={accessToken}
                        onFocus={e => e.target.select()}
                        onChange={e => setAccessToken(e.target.value)}
                        {...(hasCopernicaConnection && !editingEnabled
                            ? {
                                  disabled: true,
                                  value: '•'.repeat(64),
                                  InputProps: {
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <IconButton
                                                  onClick={() =>
                                                      setEditingEnabled(true)
                                                  }
                                                  edge="end"
                                                  sx={{ margin: 0 }}
                                              >
                                                  <Edit />
                                              </IconButton>
                                          </InputAdornment>
                                      ),
                                  },
                              }
                            : {})}
                    />
                </Box>
                <Grid
                    my={4}
                    container
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Grid item />

                    <Grid item>
                        <Button
                            disabled={
                                configureCopernica.status ===
                                    LoadingStatus.LOADING || !accessToken
                            }
                            onClick={() => {
                                handleSubmit();
                            }}
                            fullWidth={false}
                            variant="contained"
                            title="Connect"
                            startIcon={
                                configureCopernica.status ===
                                    LoadingStatus.LOADING && (
                                    <CircularProgress size={12} />
                                )
                            }
                        />
                    </Grid>
                </Grid>
            </Container>
        </ConfigureWrapper>
    );
};
