import {
    Box,
    Chip,
    IconButton,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import React, { Fragment, useState } from 'react';
import { Permission } from '../../store/workspace-management';

export interface PermissionListProps {
    permissions: Permission[];
    loading: boolean;
    onRemove: (item: Permission) => void;
}

const CELL_HEIGHT = 57;

const AccountAdminChip = () => (
    <Chip
        style={{
            marginLeft: '1rem',
            height: '1.5rem',
        }}
        label="Account admin"
    />
);

const InvitationChip = () => (
    <Chip
        style={{
            marginLeft: '1rem',
            height: '1.5rem',
        }}
        label="INVITED"
    />
);

const Row = ({
    id,
    name,
    email,
    type,
    isAdmin,
    onRemove,
}: {
    id: string;
    name: string;
    email: string;
    isAdmin: boolean;
    type: 'member' | 'invitation';
    onRemove: () => void;
}) => {
    return (
        <TableRow key={id}>
            <TableCell width="80%">
                <Stack direction="row">
                    <Typography variant="body1">
                        {name} ({email})
                    </Typography>

                    {type === 'invitation' && <InvitationChip />}
                    {isAdmin && <AccountAdminChip />}
                </Stack>
            </TableCell>
            <TableCell align="right">
                {!isAdmin && (
                    <IconButton
                        sx={{ padding: 0 }}
                        onClick={() => onRemove()}
                        size="small"
                    >
                        <Cancel color="info" fontSize="small" />
                    </IconButton>
                )}
            </TableCell>
        </TableRow>
    );
};

export const SkeletonRow = () => (
    <TableRow>
        <TableCell width="80%">
            <Stack direction="row" gap={2}>
                <Typography variant="body1">
                    <Skeleton width="160px" />
                </Typography>

                <Typography variant="body1">
                    <Skeleton width="200px" />
                </Typography>

                <Skeleton>
                    <Chip
                        style={{
                            marginLeft: '1rem',
                            height: '1rem',
                        }}
                        label="Account admin"
                    />
                </Skeleton>
            </Stack>
        </TableCell>
        <TableCell>
            <Box sx={{ display: 'grid', justifyContent: 'end' }}>
                <Skeleton variant="circular" width="20px" height="20px" />
            </Box>
        </TableCell>
    </TableRow>
);

export const PermissionList = ({
    loading,
    permissions,
    onRemove,
}: PermissionListProps) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = Math.max(
        0,
        (1 + page) * rowsPerPage - permissions.length
    );

    const emptyRowsBody = (
        <TableRow style={{ height: CELL_HEIGHT * emptyRows }}>
            <TableCell colSpan={2} />
        </TableRow>
    );

    const emptyBody = (
        <>
            <TableRow style={{ height: CELL_HEIGHT * rowsPerPage - 1 }}>
                <TableCell colSpan={2}>No permissions found</TableCell>
            </TableRow>
        </>
    );

    const mapPermission = (permission: Permission) => (
        <Row
            key={permission.id}
            id={permission.id}
            name={permission.name}
            email={permission.email}
            isAdmin={permission.isAccountAdmin}
            type={permission.type}
            onRemove={() => onRemove(permission)}
        />
    );

    const Body = ({
        permissions,
        currentPage,
        rowsPerPage,
    }: {
        permissions: Permission[];
        currentPage: number;
        rowsPerPage: number;
    }) => {
        const rows =
            rowsPerPage > 0
                ? permissions.slice(
                      currentPage * rowsPerPage,
                      currentPage * rowsPerPage + rowsPerPage
                  )
                : permissions;

        return (
            <>
                {rows.map(mapPermission)}
                {/* <SkeletonRow /> */}
                {emptyRows > 0 && emptyRowsBody}
            </>
        );
    };

    return (
        <Fragment>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant="head">Member</TableCell>
                            <TableCell align="right" variant="head">
                                Remove
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && (
                            <>
                                <SkeletonRow />
                                <SkeletonRow />
                                <SkeletonRow />
                                <SkeletonRow />
                                <SkeletonRow />
                            </>
                        )}

                        {!loading && permissions.length === 0 && emptyBody}
                        {!loading && permissions.length > 0 && (
                            <Body
                                permissions={permissions}
                                currentPage={page}
                                rowsPerPage={rowsPerPage}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {!loading ? (
                <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={permissions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            ) : (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    minHeight="52px"
                >
                    <Skeleton variant="rectangular" width={300} height={30} />
                </Box>
            )}
        </Fragment>
    );
};
