import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, Paper, Snackbar, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
    FormControl,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSeinoStore } from '../../store/seino-store';

export const DARK_MODE = 'darkMode';

const ProfilePageWrapper = styled.div`
    height: calc(100vh - 70px);
    margin: 50px 260px;
`;

const SettingsContainer = styled(Paper)`
    margin: 1rem 0;
    padding: 0.2rem 1rem;
`;

const SettingsList = styled(List)`
    max-width: 550px;
`;

export const Profile = () => {
    const { user } = useAuth0();

    const [daysOptions] = useState([
        { label: 'Sunday', value: '0' },
        { label: 'Monday', value: '1' },
        { label: 'Tuesday', value: '2' },
        { label: 'Wednesday', value: '3' },
        { label: 'Thursday', value: '4' },
        { label: 'Friday', value: '5' },
        { label: 'Saturday', value: '6' },
    ]);

    const [firstDayOfWeek, setLocalFirstDayOfWeek] = useState(daysOptions[0]);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const {
        views: { setFirstDayOfWeek },
        user: { currentWorkspaceId, workspaces },
    } = useSeinoStore();

    useEffect(() => {
        const selected = parseInt(
            window.localStorage?.getItem('first_day_of_week') || '0'
        );

        setLocalFirstDayOfWeek(daysOptions[selected]);
    }, [daysOptions]);

    useEffect(() => {
        window.localStorage?.setItem('first_day_of_week', firstDayOfWeek.value);
        setFirstDayOfWeek(parseInt(firstDayOfWeek.value) as Day);
    }, [firstDayOfWeek, setFirstDayOfWeek]);

    if (user) {
        return (
            <ProfilePageWrapper>
                <Typography variant="h1">Profile</Typography>

                <SettingsContainer>
                    <SettingsList
                        dense={true}
                        subheader={
                            <ListSubheader>Personal settings</ListSubheader>
                        }
                    >
                        <ListItem
                            secondaryAction={
                                <Typography variant="caption">
                                    {user.name}
                                </Typography>
                            }
                        >
                            <ListItemText primary="Name" />
                        </ListItem>
                        <ListItem
                            secondaryAction={
                                <Typography variant="caption">
                                    {user.email}
                                </Typography>
                            }
                        >
                            <ListItemText primary="E-mail address" />
                        </ListItem>
                        <ListItem
                            secondaryAction={
                                <FormControl variant="standard">
                                    <Select
                                        labelId="first-day-of-the-week-label"
                                        id="first-day-of-the-week"
                                        autoWidth
                                        value={firstDayOfWeek.value}
                                        size="small"
                                        onChange={e => {
                                            setLocalFirstDayOfWeek(
                                                daysOptions[
                                                    parseInt(
                                                        (e.target
                                                            .value as string) ||
                                                            '0'
                                                    )
                                                ]
                                            );
                                            setOpenSnackbar(true);
                                        }}
                                    >
                                        {daysOptions.map(day => (
                                            <MenuItem
                                                key={day.value}
                                                value={day.value as string}
                                            >
                                                {day.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                        >
                            <ListItemText
                                id="switch-list-label-wifi"
                                primary="First day of the week"
                            />
                        </ListItem>
                    </SettingsList>
                    {workspaces.length > 0 && (
                        <SettingsList
                            dense={true}
                            subheader={
                                <ListSubheader>
                                    Workspace settings
                                </ListSubheader>
                            }
                        >
                            <ListItem
                                secondaryAction={
                                    <Typography variant="caption">
                                        {
                                            workspaces.filter(
                                                ws =>
                                                    ws.id === currentWorkspaceId
                                            )[0]?.displayName
                                        }
                                    </Typography>
                                }
                            >
                                <ListItemText primary="Current workspace" />
                            </ListItem>
                        </SettingsList>
                    )}
                </SettingsContainer>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                    message="First day of week updated"
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setOpenSnackbar(false)}
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    }
                />
            </ProfilePageWrapper>
        );
    } else {
        return <div />;
    }
};
