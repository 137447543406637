import { Box, Divider, IconButton, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { memo, useCallback, useState } from 'react';
import { Rule } from '../../../store/data-source';
import { CreateRuleRow, GAView } from './CreateRuleRow';
import { RuleItem, RuleRow, RuleWrapper, Wrapper } from './RuleBuilder.styles';
import { getImageForCountryCode, getTitleForRule } from './utils';

export interface RuleBuilderProps {
    customerGAViews: GAView[];
    onRulesUpdated: (rules: Rule[]) => void;
    initialRules: Rule[];
}

const RuleBuilder = ({
    onRulesUpdated,
    customerGAViews,
    initialRules,
}: RuleBuilderProps) => {
    const RuleColumns: Rule = {
        field: 'Canopy Deploy Field',
        condition: 'Condition',
        value: 'Value',
        market: 'Set Market to',
        view_id: 'Link to GA View',
    };

    const [rules, setRules] = useState<Rule[]>(initialRules || []);

    const removeRule = useCallback(
        (index: number) => {
            const rulesCopy = rules.concat();
            rulesCopy.splice(index, 1);
            setRules(rulesCopy);
            onRulesUpdated(rulesCopy);
        },
        [onRulesUpdated, rules]
    );

    const onAddRule = useCallback(
        rule => {
            const updatedRules = [...rules, rule];
            setRules(updatedRules);
            onRulesUpdated(updatedRules);
        },
        [rules, onRulesUpdated]
    );

    return (
        <Wrapper>
            <RuleRow>
                {Object.values(RuleColumns).map((columnTitle, index) => (
                    <Typography
                        variant="subtitle1"
                        key={`${columnTitle}-${index}`}
                    >
                        {columnTitle}
                    </Typography>
                ))}
            </RuleRow>
            <Box my={2}>
                <CreateRuleRow
                    key={`create-rule-${rules.length}`}
                    customerGAViews={customerGAViews}
                    onAddRule={onAddRule}
                />
            </Box>
            <Divider />
            {rules.map((rule: Rule, index: number) => {
                return (
                    <RuleWrapper
                        data-testid={`created-rule-${index}`}
                        key={`created-rule-${index}`}
                    >
                        <RuleRow>
                            <RuleItem
                                data-testid={`created-rule-field-${index}`}
                            >
                                <Typography variant="body2">
                                    {getTitleForRule(rule.field!)}
                                </Typography>
                            </RuleItem>
                            <RuleItem
                                data-testid={`created-rule-condition-${index}`}
                            >
                                <Typography variant="body2">
                                    {getTitleForRule(rule.condition!)}
                                </Typography>
                            </RuleItem>
                            <RuleItem
                                data-testid={`created-rule-value-${index}`}
                            >
                                <Typography variant="body2">
                                    {rule.value!}
                                </Typography>
                            </RuleItem>
                            <RuleItem
                                data-testid={`created-rule-market-${index}`}
                            >
                                <Typography variant="body2">
                                    {getImageForCountryCode(rule.market!)}
                                    {getTitleForRule(
                                        rule.market!,
                                        customerGAViews
                                    )}
                                </Typography>
                            </RuleItem>
                            <RuleItem
                                data-testid={`created-rule-ga_view-${index}`}
                            >
                                <Typography variant="body2">
                                    {getTitleForRule(
                                        rule.view_id!,
                                        customerGAViews
                                    )}
                                </Typography>
                            </RuleItem>
                            <RuleItem justifyContent="center">
                                <IconButton onClick={() => removeRule(index)}>
                                    <Delete />
                                </IconButton>
                            </RuleItem>
                        </RuleRow>
                        <Divider />
                    </RuleWrapper>
                );
            })}
        </Wrapper>
    );
};

const Component = memo(RuleBuilder);
export { Component as RuleBuilder };
