import { AxiosResponse } from 'axios';
import create, {
    GetState,
    SetState,
    StateCreator,
    StoreApi,
    Subscribe,
} from 'zustand';
import { devtools } from 'zustand/middleware';
import { LoadingStatus } from '../constants';
type NestedObject = {
    [T: string]: Object;
};
export function compose<
    StoreState extends NestedObject,
    ComposeKey extends keyof StoreState,
    ComposeState extends object = StoreState[ComposeKey]
>(
    key: ComposeKey,
    store: StateCreator<ComposeState>
): (
    storeSet: SetState<StoreState>,
    storeGet: GetState<StoreState>,
    storeApi: StoreApi<StoreState>
) => ComposeState {
    return (rootStoreSet: any, rootStoreGet: any, rootStoreApi: any) => {
        const setState: SetState<ComposeState> = (
            updates: any,
            replace?: boolean
        ) => {
            rootStoreSet((liftedState: StoreState) => {
                if (typeof updates === 'function') {
                    updates = updates(liftedState[key]);
                }
                if (!replace) {
                    updates = { ...liftedState[key], ...updates };
                }
                return {
                    ...liftedState,
                    [key]: updates,
                };
            }, replace);
        };
        const getState: GetState<ComposeState> = () => {
            return rootStoreGet()[key];
        };
        const subscribe = ((listener: any, selector: any, equalityFn?: any) => {
            if (selector) {
                return rootStoreApi.subscribe(
                    listener,
                    (state: any) => selector(state[key]),
                    equalityFn
                );
            } else {
                return rootStoreApi.subscribe(
                    listener,
                    (state: any) => state[key]
                );
            }
        }) as Subscribe<ComposeState>;
        const destroy = () => {};
        const api = { getState, setState, subscribe, destroy };
        return store(setState, getState, api);
    };
}

export const createStore: typeof create = store => {
    return create(devtools(store as any));
};

export interface FetchState<I, T> {
    result: T | null;
    status: LoadingStatus;
    error: any | null;
    fetch: (options: I) => Promise<void>;
}

export interface CreateFetchStateOptions {
    flushResultOnFetch?: boolean;
    processData?: (data: any) => any;
}

export const createFetchState = <I, T>(
    key: string,
    apiCall: (options: I) => Promise<AxiosResponse<T>> | Promise<void>,
    options: CreateFetchStateOptions = {}
) => (set: SetState<any>, get: GetState<any>): FetchState<I, T> => {
    const { flushResultOnFetch = true, processData } = options;

    return {
        result: null,
        status: LoadingStatus.IDLE,
        error: null,
        fetch: async (options: I) => {
            try {
                set({
                    [key]: {
                        status: LoadingStatus.LOADING,
                        result: flushResultOnFetch ? null : get()[key].result,
                        error: null,
                        fetch: get()[key].fetch,
                    },
                });

                const rawResult = await apiCall(options);

                let result;
                if (processData && rawResult && rawResult.data) {
                    result = processData(rawResult.data);
                } else {
                    result = rawResult && rawResult.data;
                }

                set({
                    [key]: {
                        status: LoadingStatus.SUCCESS,
                        result: result,
                        error: null,
                        fetch: get()[key].fetch,
                    },
                });
            } catch (error) {
                set({
                    [key]: {
                        status: LoadingStatus.FAILED,
                        error,
                        result: null,
                        fetch: get()[key].fetch,
                    },
                });
            }
            return Promise.resolve();
        },
    };
};
