import { GridOptions, ValueGetterParams } from 'ag-grid-community';
import { format } from 'date-fns';
import locale from 'date-fns/locale/en-US';
import { getWeekdays, getWeekNumber } from '../../utils/weeks';
import {
    currencyFormatter,
    dateFormatter,
    KPINumberRenderer,
    marketRenderer,
    numberFormatter,
    percentageFormatter,
    utmParam,
} from './grid/utils';
import { CustomDimension } from './grid/utils/customDimension';
import { GoalMetric } from './grid/utils/goalMetric';
import { split } from './grid/utils/split';
import { timeFormatter } from './grid/utils/timeFormatter';
import {
    weightedAverage,
    WeightedValue,
    weightedValueGetter,
} from './grid/utils/weightedAverage';

interface GridOptionsConfig {
    firstDayOfWeek: Day;
    customDimensions: CustomDimension[];
    goalMetrics: GoalMetric[];
}

export const createGridOptions = ({
    firstDayOfWeek,
    customDimensions,
    goalMetrics,
}: GridOptionsConfig): GridOptions => {
    const weekdays = getWeekdays(locale, 'abbreviated', firstDayOfWeek);
    const weekdayMap = Object.fromEntries(weekdays.map((d, i) => [d, i]));
    const weekdayComparator = (a: any, b: any) =>
        a in weekdayMap && b in weekdayMap
            ? weekdayMap[a] - weekdayMap[b]
            : a - b;

    return {
        enableCharts: true,
        enableRangeSelection: true,
        animateRows: true,
        rowGroupPanelShow: 'always',
        groupIncludeTotalFooter: true,
        suppressAggFuncInHeader: true,
        overlayNoRowsTemplate:
            '<span class="ag-overlay-loading-center">Data request failed. Refresh the page to try again.',
        overlayLoadingTemplate:
            '<span class="ag-overlay-loading-center">Meditating on your request...</span>',
        defaultColDef: {
            hide: true,
            floatingFilter: true,
            resizable: true,
            sortable: true,
            filterParams: { newRowsAction: 'keep' },
        },
        aggFuncs: {
            weightedAvg: weightedAverage,
        },
        statusBar: {
            statusPanels: [
                {
                    statusPanel: 'agTotalAndFilteredRowCountComponent',
                    align: 'left',
                },
                { statusPanel: 'agTotalRowCountComponent' },
                { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
                { statusPanel: 'agAggregationComponent' },
            ],
        },
        sideBar: true,
        detailRowAutoHeight: true,
        isRowMaster: dataItem => dataItem.utm_metrics?.length > 1,
        detailCellRendererParams: {
            detailGridOptions: {
                defaultColDef: {
                    resizable: true,
                    sortable: true,
                    flex: 1,
                },
                columnDefs: [
                    { field: 'utm_campaign', headerName: 'UTM campaign' },
                    { field: 'utm_content', headerName: 'UTM content' },
                    { field: 'utm_source', headerName: 'UTM source' },
                    { field: 'utm_term', headerName: 'UTM term' },
                    { field: 'utm_medium', headerName: 'UTM medium' },
                    {
                        field: 'sessions',
                        type: 'numericColumn',
                        valueFormatter: numberFormatter,
                        resizable: true,
                        width: 120,
                    },
                    {
                        field: 'bounces',
                        type: 'numericColumn',
                        valueFormatter: numberFormatter,
                        resizable: true,
                        width: 120,
                    },
                    {
                        field: 'transactions',
                        type: 'numericColumn',
                        valueFormatter: numberFormatter,
                        resizable: true,
                        width: 120,
                    },
                    {
                        field: 'revenue',
                        type: 'numericColumn',
                        valueFormatter: currencyFormatter,
                        resizable: true,
                        width: 120,
                    },
                ],
            },
            getDetailRowData: (params: any) => {
                params.successCallback(params.data.utm_metrics);
            },
        },
        columnDefs: [
            {
                headerName: 'Campaign',
                children: [
                    {
                        headerName: 'Broadcast type',
                        colId: 'broadcast_campaign_type',
                        field: 'broadcast_campaign_type',
                        filter: 'agSetColumnFilter',
                        width: 132,
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                        enableRowGroup: true,
                    },
                    {
                        headerName: 'Campaign IDs',
                        colId: 'broadcast_campaign_id',
                        field: 'broadcast_campaign_id',
                        filter: 'agSetColumnFilter',
                        width: 124,
                        enableRowGroup: true,
                    },
                    {
                        headerName: 'Campaign Name',
                        colId: 'broadcast_campaign_name',
                        field: 'broadcast_campaign_name',
                        filter: 'agSetColumnFilter',
                        width: 170,
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                        enableRowGroup: true,
                    },
                    {
                        headerName: 'Content Name',
                        colId: 'broadcast_mailing_name',
                        field: 'broadcast_mailing_name',
                        filter: 'agSetColumnFilter',
                        width: 170,
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                        enableRowGroup: true,
                        hide: false,
                    },
                    {
                        headerName: 'Subject',
                        colId: 'broadcast_mailing_subject',
                        field: 'broadcast_mailing_subject',
                        filter: 'agSetColumnFilter',
                        width: 170,
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                        enableRowGroup: true,
                        hide: false,
                    },
                    {
                        headerName: 'Mailing type',
                        colId: 'broadcast_mailing_type',
                        field: 'broadcast_mailing_type',
                        filter: 'agSetColumnFilter',
                        width: 121,
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                        enableRowGroup: true,
                    },
                    {
                        headerName: 'Audience',
                        colId: 'broadcast_mailing_audience',
                        field: 'broadcast_mailing_audience',
                        width: 98,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                        enableRowGroup: true,
                    },
                    {
                        headerName: 'Market',
                        colId: 'broadcast_mailing_market',
                        field: 'broadcast_mailing_market',
                        filter: 'agSetColumnFilter',
                        width: 84,
                        enablePivot: true,
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                        enableRowGroup: true,
                        rowGroup: true,
                        showRowGroup: false,
                        cellRenderer: marketRenderer,
                    },
                    {
                        headerName: 'Send date',
                        colId: 'broadcast_mailing_send_date',
                        field: 'broadcast_mailing_send_date',
                        valueFormatter: dateFormatter('d/MM/yyyy'),
                        sortable: true,
                        width: 191,
                        floatingFilter: true,
                        enableRowGroup: true,
                    },
                    {
                        headerName: 'Day',
                        colId: 'broadcast_mailing_send_date_day',
                        enablePivot: true,
                        width: 90,
                        enableRowGroup: true,
                        filter: 'agSetColumnFilter',

                        comparator: weekdayComparator,
                        pivotComparator: weekdayComparator,
                        valueGetter: ({ getValue }: ValueGetterParams) => {
                            const value = getValue(
                                'broadcast_mailing_send_date'
                            );

                            return value
                                ? format(new Date(value), 'E', { locale })
                                : '';
                        },
                    },
                    {
                        headerName: 'Week',
                        colId: 'broadcast_mailing_send_date_week',
                        type: 'numericColumn',
                        valueGetter: ({ getValue }: ValueGetterParams) => {
                            const value = getValue(
                                'broadcast_mailing_send_date'
                            );

                            return value
                                ? getWeekNumber(new Date(value), firstDayOfWeek)
                                : '';
                        },
                        enablePivot: true,
                        width: 75,
                        enableRowGroup: true,
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                    },
                    {
                        headerName: 'Month',
                        colId: 'broadcast_mailing_send_date_month',
                        field: 'broadcast_mailing_send_date_month',
                        width: 81,
                        sortable: true,
                        enablePivot: true,
                        floatingFilter: true,
                        enableRowGroup: true,
                        type: 'numericColumn',
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                    },
                    {
                        headerName: 'Year',
                        colId: 'broadcast_mailing_send_date_year',
                        field: 'broadcast_mailing_send_date_year',
                        enableRowGroup: true,
                        width: 71,
                        enablePivot: true,
                        type: 'numericColumn',
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                    },
                ],
            },
            {
                headerName: 'UTM',
                children: [
                    {
                        headerName: 'UTM source',
                        cellRenderer: 'agGroupCellRenderer',
                        valueGetter: utmParam('utm_source'),
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                    },
                    {
                        headerName: 'UTM medium',
                        cellRenderer: 'agGroupCellRenderer',
                        valueGetter: utmParam('utm_medium'),
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                    },
                    {
                        headerName: 'UTM campaign',
                        cellRenderer: 'agGroupCellRenderer',
                        valueGetter: utmParam('utm_campaign'),
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                    },
                    {
                        headerName: 'UTM term',
                        cellRenderer: 'agGroupCellRenderer',
                        valueGetter: utmParam('utm_term'),
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                    },
                    {
                        headerName: 'UTM content',
                        cellRenderer: 'agGroupCellRenderer',
                        valueGetter: utmParam('utm_content'),
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                    },
                ],
            },
            {
                headerName: 'Custom dimensions',
                children: customDimensions.map(
                    ({ name, delimiter, max, index, sourceField }) => ({
                        headerName: name,
                        valueGetter: ({ data }) => {
                            return split(
                                data?.[sourceField] || '',
                                delimiter,
                                max
                            )[index];
                        },
                        sortable: true,
                        enablePivot: true,
                        floatingFilter: true,
                        enableRowGroup: true,
                        type: 'numericColumn',
                        filter: 'agSetColumnFilter',
                        filterParams: {
                            applyMiniFilterWhileTyping: true,
                        },
                    })
                ),
            },
            {
                headerName: 'Email',
                children: [
                    {
                        headerName: 'Sent all',
                        colId: 'metric_email_sent_all_sum',
                        field: 'metric_email_sent_all_sum',
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        width: 87,
                        enableValue: true,
                        hide: false,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Sent unique',
                        colId: 'metric_email_sent_unique_sum',
                        field: 'metric_email_sent_unique_sum',
                        enableValue: true,
                        width: 113,
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        hide: false,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Open',
                        colId: 'metric_email_open_unique_sum',
                        field: 'metric_email_open_unique_sum',
                        enableValue: true,
                        width: 73,
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        hide: false,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Open (%)',
                        colId: 'metric_email_open_unique_rate',
                        field: 'metric_email_open_unique_rate',
                        enableValue: true,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_email_open_unique_sum',
                            'metric_email_sent_unique_sum'
                        ),
                        chartDataType: 'series',
                        width: 97,
                        hide: false,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: percentageFormatter,
                    },
                    {
                        headerName: 'Clicks unique (sum)',
                        colId: 'metric_email_clicks_unique_sum',
                        field: 'metric_email_clicks_unique_sum',
                        enableValue: true,
                        width: 160,
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Clicks unique (%)',
                        colId: 'metric_email_clicks_unique_rate',
                        field: 'metric_email_clicks_unique_rate',
                        enableValue: true,
                        initialAggFunc: 'weightedAvg',
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_email_clicks_unique_sum',
                            'metric_email_sent_unique_sum'
                        ),
                        chartDataType: 'series',
                        width: 146,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: percentageFormatter,
                    },
                    {
                        headerName: 'Clicks all',
                        colId: 'metric_email_clicks_all_sum',
                        field: 'metric_email_clicks_all_sum',
                        enableValue: true,
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        width: 95,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Clicks all (%)',
                        colId: 'metric_email_clicks_all_rate',
                        field: 'metric_email_clicks_all_rate',
                        enableValue: true,
                        width: 119,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_email_clicks_all_sum',
                            'metric_email_sent_all_sum'
                        ),
                        chartDataType: 'series',
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: percentageFormatter,
                    },
                    {
                        headerName: 'Clicks to open all (%)',
                        colId: 'metric_email_clicks_to_open_all_rate',
                        field: 'metric_email_clicks_to_open_all_rate',
                        enableValue: true,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_email_clicks_all_sum',
                            'metric_email_open_unique_sum'
                        ),
                        chartDataType: 'series',
                        width: 168,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: percentageFormatter,
                    },
                    {
                        headerName: 'Clicks to open unique (%)',
                        colId: 'metric_email_clicks_to_open_unique_rate',
                        field: 'metric_email_clicks_to_open_unique_rate',
                        enableValue: true,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_email_clicks_unique_sum',
                            'metric_email_open_unique_sum'
                        ),
                        chartDataType: 'series',
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        width: 194,
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: percentageFormatter,
                    },
                    {
                        headerName: 'Clicks per user',
                        colId: 'metric_email_clicks_per_user_avg',
                        field: 'metric_email_clicks_per_user_avg',
                        enableValue: true,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_email_clicks_all_sum',
                            'metric_email_clicks_unique_sum'
                        ),
                        chartDataType: 'series',
                        type: 'numericColumn',
                        width: 131,
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Soft bounces',
                        colId: 'metric_email_softbounce_sum',
                        field: 'metric_email_softbounce_sum',
                        enableValue: true,
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        type: 'numericColumn',
                        width: 122,
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Soft bounces (%)',
                        colId: 'metric_email_softbounce_rate',
                        field: 'metric_email_softbounce_rate',
                        enableValue: true,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_email_softbounce_sum',
                            'metric_email_sent_unique_sum'
                        ),
                        chartDataType: 'series',
                        type: 'numericColumn',
                        width: 145,
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: percentageFormatter,
                    },
                    {
                        headerName: 'Hard bounces',
                        colId: 'metric_email_hardbounce_sum',
                        field: 'metric_email_hardbounce_sum',
                        enableValue: true,
                        width: 125,
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Hard bounces (%)',
                        colId: 'metric_email_hardbounce_rate',
                        field: 'metric_email_hardbounce_rate',
                        enableValue: true,
                        width: 149,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_email_hardbounce_sum',
                            'metric_email_sent_unique_sum'
                        ),
                        chartDataType: 'series',
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: percentageFormatter,
                    },
                    {
                        headerName: 'Complaints',
                        colId: 'metric_email_complaints_sum',
                        field: 'metric_email_complaints_sum',
                        enableValue: true,
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        width: 110,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Complaints (%)',
                        colId: 'metric_email_complaints_rate',
                        field: 'metric_email_complaints_rate',
                        enableValue: true,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_email_complaints_sum',
                            'metric_email_sent_unique_sum'
                        ),
                        chartDataType: 'series',
                        width: 133,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: percentageFormatter,
                    },
                    {
                        headerName: 'Unsubscribes',
                        colId: 'metric_email_unsubscribe_sum',
                        field: 'metric_email_unsubscribe_sum',
                        enableValue: true,
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        width: 124,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Unsubscribes (%)',
                        colId: 'metric_email_unsubscribe_rate',
                        field: 'metric_email_unsubscribe_rate',
                        enableValue: true,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_email_unsubscribe_sum',
                            'metric_email_sent_unique_sum'
                        ),
                        chartDataType: 'series',
                        width: 144,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: percentageFormatter,
                    },
                    {
                        headerName: 'Deliverability (%)',
                        colId: 'metric_email_deliverability_rate',
                        field: 'metric_email_deliverability_rate',
                        enableValue: true,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: ({ data }: ValueGetterParams) =>
                            new WeightedValue(
                                (data?.metric_email_sent_unique_sum || 0) -
                                    (data?.metric_email_softbounce_sum || 0) -
                                    (data?.metric_email_hardbounce_sum || 0),
                                data?.metric_email_sent_unique_sum || 0
                            ),
                        chartDataType: 'series',
                        width: 144,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: percentageFormatter,
                    },
                ],
            },
            {
                headerName: 'Website',
                children: [
                    {
                        headerName: 'Sessions',
                        colId: 'metric_website_session_sum',
                        field: 'metric_website_session_sum',
                        enableValue: true,
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        width: 94,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Bounces',
                        colId: 'metric_website_bounce_sum',
                        field: 'metric_website_bounce_sum',
                        enableValue: true,
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        type: 'numericColumn',
                        width: 93,
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Avg. Session Duration',
                        colId: 'metric_website_session_time_avg',
                        field: 'metric_website_session_time_avg',
                        enableValue: true,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_website_session_time_sum',
                            'metric_website_session_sum'
                        ),
                        chartDataType: 'series',
                        width: 173,
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                        valueFormatter: timeFormatter,
                    },
                    {
                        headerName: 'Transactions',
                        colId: 'metric_website_transactions_sum',
                        field: 'metric_website_transactions_sum',
                        enableValue: true,
                        type: 'numericColumn',
                        width: 118,
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: numberFormatter,
                    },
                    {
                        headerName: 'Conversion Rate',
                        colId: 'metric_website_conversion_rate',
                        field: 'metric_website_conversion_rate',
                        enableValue: true,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_website_transactions_sum',
                            'metric_website_session_sum'
                        ),
                        chartDataType: 'series',
                        type: 'numericColumn',
                        width: 139,
                        filter: 'agNumberColumnFilter',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: percentageFormatter,
                    },
                    {
                        headerName: 'Revenue',
                        colId: 'metric_website_revenue_sum',
                        field: 'metric_website_revenue_sum',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: currencyFormatter,
                        enableValue: true,
                        width: 92,
                        aggFunc: 'sum',
                        allowedAggFuncs: ['sum'],
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                    },
                    {
                        headerName: 'Revenue per session',
                        colId: 'metric_website_revenue_per_session',
                        field: 'metric_website_revenue_per_session',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: currencyFormatter,
                        width: 165,
                        enableValue: true,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_website_revenue_sum',
                            'metric_website_session_sum'
                        ),
                        chartDataType: 'series',
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                    },
                    {
                        headerName: 'Revenue per email sent',
                        colId: 'metric_website_revenue_per_email_sent',
                        field: 'metric_website_revenue_per_email_sent',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: currencyFormatter,
                        enableValue: true,
                        width: 181,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_website_revenue_sum',
                            'metric_email_sent_unique_sum'
                        ),
                        chartDataType: 'series',
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                    },
                    {
                        headerName: 'Average order value',
                        colId: 'metric_website_average_order_value',
                        field: 'metric_website_average_order_value',
                        cellRenderer: KPINumberRenderer,
                        valueFormatter: currencyFormatter,
                        enableValue: true,
                        width: 161,
                        aggFunc: 'weightedAvg',
                        allowedAggFuncs: ['weightedAvg'],
                        valueGetter: weightedValueGetter(
                            'metric_website_revenue_sum',
                            'metric_website_transactions_sum'
                        ),
                        chartDataType: 'series',
                        type: 'numericColumn',
                        filter: 'agNumberColumnFilter',
                    },
                ],
            },
            {
                headerName: 'Goals',
                children: goalMetrics.map(({ name: headerName, sources }) => ({
                    headerName,
                    enableValue: true,
                    type: 'numericColumn',
                    aggFunc: 'sum',
                    allowedAggFuncs: ['sum'],
                    filter: 'agNumberColumnFilter',
                    valueFormatter: numberFormatter,
                    valueGetter: ({ data }) => {
                        return sources
                            .map(
                                ({ view_id, goal_id }) =>
                                    data?.goal_metrics?.[
                                        `${view_id}_${goal_id}`
                                    ]?.goal_completions
                            )
                            .reduce(
                                (prev, current) =>
                                    current ? (prev || 0) + current : prev,
                                undefined
                            );
                    },
                })),
            },
        ],
    };
};
